
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { differenceInDays, format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { CommunityService } from 'src/app/community.service';
import { ContactReferenceService } from 'src/app/contact-reference.service';
import { ContactsService } from 'src/app/contacts.service';
import { dateFormatYYYYMMDD } from 'src/app/core/utils/dateformat';
import { DocumentService } from 'src/app/document.service';
import { DocumentTypesService } from 'src/app/documentTypes.service';
import { LeasePGService } from 'src/app/lease-pg.service';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { SystemService } from 'src/app/system.service';
import { UserService } from 'src/app/user.service';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';
import { Contact } from '../../../../../models/contacts.models';
import { DocumentListerInputs, DocumentPG, DocumentTypeEnum, DocumentTypeValues, DocumentVM } from '../../../../../models/document.pg.model';
import { GeneralSearchModel } from '../../../../../models/generalSearch.model';
import { ContactType } from '../../../../../models/requestDetails.models';
import { isDateValid } from '../../../../../utils/datevalidator';
import { gettoday } from '../../../../../utils/gettoday';
import { maptoarray } from '../../../../../utils/mapandarray';
import { objectify } from '../../../../../utils/objectify';
import { sortBy, sortByN } from '../../../../../utils/sortby';



export interface ListerData {
	refType: string,
	refId: string,
	limitTo: string[],
	exclude?: string[],
	isUnique?: boolean,
	community?: string,
	lh?:any[],
	fp?:any[],
 }


/**
 * This component represents a list of documents uploaded within a specific section in the system
 *
 * and is represented by a table containing the following columns:
 *
 * 	- File Name.
 *
 * 	- Type.
 *
 * 	- Date Uploaded.
 *
 * 	- Uploaded By.
 *
 * 	- Service Provider.
 *
 * 	- Frequency of Service.
 *
 * 	- Dates(start & end dates).
 *
 * 	- Notification Range.
 *
 * 	- Status.(expired or not ).
 *
 * This component also provides the ability to upload a new file to the system, delete a pre-existing file,
 *
 * or renew an expired file if the administrator has power to do so.
 *
 * It also provides the possibility of filtering in relation to one of the previously mentioned columns.
 */
@Component({
	selector: 'app-document-lister',
	templateUrl: './document-lister.component.html',
	styleUrls: ['./document-lister.component.scss'],
	providers:[
		ConfirmationService 
	]
})
export class DocumentListerComponent implements OnInit, OnDestroy, OnChanges {

	@Input() purchaseUpdated: EventEmitter<void>;


	@Input('list') list: any;

	@Input() showAllTypes = false;
	@Input('header') header = 'Documents';
	@Input('limitTo') limitTo: string[] = [];
	@Input('exclude') exclude: string[] = [];
	@Input('refType') refType = '';
	@Input('refId') refId = '';
	@Input('addBtn') addBtn = '';
	@Input('onlyIcons') onlyIcons = true;
	@Input('isReadOnly') isReadOnly = false;
	@Input('extended') extended = false;
	@Input('disableUpload') disableUpload = false;
	@Input('enableUploadEvenReadOnly') enableUploadEvenReadOnly = false;
	@Input('isUnique') isUnique = false;
	@Input('community') community = '';
	@Input('mandatoryDocuments') mandatoryDocuments: any[] = [];
	@Input() visaPassportDocs = false;
	@Input() isShortTerm = false;
	@Input('pageTitle') pageTitle = '';
	@Input('needNotificationRange') needNotificationRange = false;
	@Input() maxFileSize = this.documentService.MAX_DOCUMENT_SIZE;
	@Output() listUpdate = new EventEmitter<any>();
	@Output() processingDocs = new EventEmitter(false);
	@Output() private add = new EventEmitter<any>(true);
	@Output() private delete = new EventEmitter<any>(true);
	@Output() allMandatoryDocsUploaded = new EventEmitter(false);
	@Input() allowDelete = true;
	@Input() vendorDocuments = false;
	@Input() approveByActorType = false;
	@Input() allowReject = true;
	@Input() leaseId = '';
	criteria: DocumentListerInputs = null;
	displayUploader = false;
	stillNeedVisaPassportDocs = false;
	public bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD' };
	public genTemplates = false;
	public imgPreviewUrl = '';
	public contacts: any;
	public contactsArray: Contact[] = [];
	private documents: DocumentVM[];
	private expiredDocuments: any[] = undefined;
	public filteredDocuments: any[] = [];
	public documentTypes: any;
	public filterValue = '';
	public showAll = true;
	public isLoading = true;
	public isSaving = false;
	public isImage: boolean[];
	private lh: any[] = [];
	private fp: any[] = [];

	private subscription: Subscription;
	private documentSubscription: Subscription;
	public searchObject: GeneralSearchModel;
	listerData: ListerData;
	public mandatoryDocumentList: any[] = [];
	public uploadedTypes: any[] = [];
	public haveMandatory: boolean;
	public isContact;
	public displayFileViewer = false;
	public openedDocument;
	public openedDocumentUrl;
	private imgTypes = ['apng', 'avif', 'gif', 'jpeg', 'jpg', 'png', 'svg+xml', 'webp'];
	public filterFrom: string;
	public filterTo: string;
	public serviceProvider: string;
	public showExpiry: boolean;
	public expiryDays = 'expired';
	public showReasonDialog = false;
	approveLeaseDocument = false;
	rejectLeaseDocument = false;
	selectedVendorDocument
	selectedLeaseDocument: DocumentPG;
	contactType: ContactType
	subscription$ = new Subscription();
	canDeleteDocs = false;
	userFunctions = [];
	project = '';
	projectId$ = this.communityService.projectID;
	user$ = this.userService.user;
	documentTypeEnum=DocumentTypeEnum
	userFunctions$ = this.user$.pipe(
		switchMap((user) => this.contactReferenceService.getContactReferenceByContact(user.contact)),
		withLatestFrom(this.projectId$),
		map(([refs, project]) => {
			this.project = project;
			return project ? refs.filter(ref => ref.refId === project) : refs;
		}),
		map(functions => functions?.map(f => f.function)),
	);
	@Input() vendorDocs = false;
	isPropertyManager = false;
	isPropertyAccountanat = false;
	showApprovalHistoryDialog = false;
	selectedDocumentHistory = [];
	sendToTenantLoadingMap: { [key: string]: boolean } = {};
	sendToLandlordLoadingMap: { [key: string]: boolean } = {};
	approvableLeaseDocument = [DocumentTypeEnum.TenancyContract, DocumentTypeEnum.ChequeCopy, DocumentTypeEnum.Notices, DocumentTypeEnum.SignedOfferTOLease,DocumentTypeEnum.Ejari,DocumentTypeEnum.Other];
	disabledLeaseApprovableDocs = [DocumentTypeEnum.SignedOfferTOLease]
	constructor(
		private confirmationService: ConfirmationService,
		private ms: BsModalService,
		public ss: SystemService,
		public cs: ContactsService,
		private toastr: CustomToastrService,
		private documentService: DocumentService,
		private changeDetection: ChangeDetectorRef,
		public documentTypesService: DocumentTypesService,
		private authService: AuthService,
		private sanitizer: DomSanitizer,
		private contactReferenceService: ContactReferenceService,
		private userService: UserService,
		private communityService: CommunityService,
		private leaseService: LeasePGService,
		private router: Router,
	) { }
	checkLeaseDisabledApproval(doc:DocumentPG){
		return this.disabledLeaseApprovableDocs.includes(doc.type as DocumentTypeEnum)
	}
	async ngOnInit() {
		this.purchaseUpdated?.subscribe(async () => {
			await this.initialize();
			});
		(this.authService.user.actorType === 'contact') ? this.isContact = true : this.isContact = false;
		this.contactsArray = (await getNonNullNonEmptyValue(this.cs.contactArray))?.filter(c => c.type === 'vc');
		this.subscription$.add(
			this.userFunctions$.subscribe(functions => {
				this.userFunctions = functions;
				this.canDeleteDocs = this.project ? !!this.allowDelete || this.userFunctions.includes('addoc') : !!this.allowDelete || this.userFunctions.includes('addocg');
				this.isPropertyManager = this.project && this.userFunctions.includes('cm');
				this.isPropertyAccountanat = this.project && this.userFunctions.includes('pa');
			})
		);
		await this.initialize();
	}
	async ngOnChanges(changes: SimpleChanges) {
		await this.initialize();
	}
	errorLoadingImage(i: number) {
		this.isImage[i] = false;
	}

	async initialize() {
		try {
			this.haveMandatory = this.mandatoryDocuments.length !== 0;
			this.mandatoryDocumentList = this.mandatoryDocuments;
			if (!this.refId || !this.refType) throw new Error('Please provide refId and refType to the document lister');
			if (!this.limitTo) this.limitTo = [];
			if (!this.exclude) this.exclude = [];
			if (!this.community) this.community = '';
			this.criteria = { refType: this.refType, refId: this.refId, limitTo: this.limitTo, exclude: this.exclude, isUnique: this.isUnique, community: this.community };
			this.isLoading = true;

			this.filteredDocuments = this.documents = [];
			if (this.criteria) {
				if (this.showAllTypes) {
					this.documents = await this.documentService.getDocumentsByRef({ refType: this.refType, refId: this.refId, community: this.community }) || []
				} else {
					this.documents = (this.vendorDocuments) ? await this.documentService.getvendorSearchDocumentsByRef({ community: this.criteria.refId, types: this.limitTo }) || [] : await this.documentService.getDocumentsByRef(this.criteria) || [];
				}
				this.documents = this.documents.map(doc => ({ ...doc, contactName: this.contactsArray?.find(c => c.id === doc.service)?.displayName || '' }))
				this.isImage = this.documents.map(_ => true);
				this.filteredDocuments = this.documents.sort(sortByN(['type', 'name']));
				if (this.expiredDocuments !== undefined) this.filteredDocuments = this.documents = this.expiredDocuments;
				for (const doc of this.filteredDocuments) {
					doc.startDate = (doc.startDate || doc.issueDate);
					doc.endDate = (doc.endDate || doc.expiryDate || doc.dueDate);
				}
				this.listUpdate.emit(this.filteredDocuments);
				this.isLoading = false;
				this.changeDetection.detectChanges();
			}
			const allDocumentTypes = await this.documentTypesService.documentTypesFetch();
			this.documentTypes = this.documentTypesService.documentTypes;
			if (this.uploadedTypes.length === 0) {
				for (const document of this.filteredDocuments) {
					const docType = allDocumentTypes.filter(documentType => documentType.id === document.type);
					this.uploadedTypes.push(docType[0]);
				}
				this.uploadedTypes = this.uploadedTypes.filter((value, index, self) =>
					index === self.findIndex((t) => (
						t?.id === value?.id
					)));
			}
			if (this.limitTo.length === 0) {
				const documentTypes: any[] = maptoarray(this.documentTypes.value);
				this.limitTo = documentTypes.filter(document => !document.id.includes('project-documents-section')).sort(sortBy('description')).map(document => document.id);
			}
		} catch (error) {
			console.error(error);
			this.toastr.error(error?.message || 'Error happened in document-lister');
		}
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		this.documentSubscription?.unsubscribe();
		this.subscription$.unsubscribe();
	}

	public updateList(docs) {
		this.expiredDocuments = docs;
		if (docs !== undefined) this.filteredDocuments = this.documents = docs;
	}

	public uploadDocument() {
		this.listerData = { refType: this.refType, refId: this.refId, limitTo: this.limitTo, exclude: this.exclude, isUnique: this.isUnique, community: this.community };
		if (this.limitTo.includes('lh')) {
			this.listerData.lh = this.lh;
		}
		if (this.limitTo.includes('fp')) {
			this.listerData.fp = this.fp;
		}
		this.displayUploader = true;
	}
	getApprovalDocumentStatus(status: string) {
		return (status) == 'approved' ? 'Approved' : (status) == 'pending' ? 'Pending' : 'Rejected'
	}

	close() {
		this.displayUploader = false;
		this.uploadedTypes = [];
		this.filterValue = '';
		this.initialize();
	}
	public async deleteDocumentDialog(document: string, type: string) {
		if (confirm('Are you sure?')) {
			this.isLoading = true;
			await this.deleteDocument(document);
			this.uploadedTypes = this.uploadedTypes?.filter(documentType => documentType.id !== type);
			this.allMandatoryDocsUploaded.emit(false);
			this.isLoading = false;
			this.delete.emit(true);
		}
	}

	private async deleteDocument(documentId: string) {
		try {
			this.processingDocs.emit(true);
			await this.documentService.delete(documentId /*, this.criteria */);
			await this.initialize();
			this.processingDocs.emit(false);
		} catch (error) {
			console.error(error);
			this.toastr.error(error?.message || 'An error happened while deleting the document');
			this.processingDocs.emit(false);
		}
	}

	public getDateFormat = (params) => {
		if (!isDateValid(params)) {
			return '-';
		}
		return dateFormatYYYYMMDD(params);
	}

	public getRenewal = (params) => {
		if (!isDateValid(params)) {
			return '-';
		}
		return dateFormatYYYYMMDD(params);
	}
	public saveDocuments = async () => {
		this.isSaving = true;
		try {
			for (const document of this.filteredDocuments) {
				const startDate = document.startDate;
				const endDate = document.endDate;

				if ((document.startDate || document.type === 'in') && document.endDate) {
					if (!isDateValid(document.startDate) && document.type !== 'in') {
						document.startDate = this.getDate(document.startDate.toLocaleString());
					}
					if (!isDateValid(document.endDate)) {
						document.endDate = this.getDate(document.endDate.toLocaleString());
					}
				}
				await this.documentService.put(document.id, document);
				document.startDate = startDate;
				document.endDate = endDate;
			}
			this.toastr.success('The documents were saved successfully');
			this.onFilterChange();
			this.isSaving = false;
		} catch (error) {
			this.toastr.error('Error Saving Documents', 'Failure', { timeOut: 5000 });
			this.isSaving = false;
		}
	}

	private getDate = (date: any) => {
		date = date.split(',');
		const splitDate = date[0].split('/');
		const year = splitDate[2];
		let month = splitDate[0];
		let day = splitDate[1];
		if (month < 10) {
			month = '0' + month;
		}
		if (day < 10) {
			day = '0' + day;
		}
		return year + month + day;
	}

	private setDateToToday = (document: DocumentPG) => {
		if (!document.startDate) document.startDate = gettoday();
		if (!document.endDate) document.endDate = gettoday();
		if (!document.notificationRange) document.notificationRange = 90;
	}

	public onContactSelect = (event, document) => {
		document.service = event.value;
		document.contactName = this.contactsArray.filter(contact => contact.id === event.value)[0].displayName;
	}

	isDocumentDownloading = new BehaviorSubject(false);
	public checkDocumentTypeIsUploaded(type: string) {
		if (this.filteredDocuments.find(document => document.type === type)) {
			this.mandatoryDocumentList = this.mandatoryDocumentList.filter(document => document.type !== type);
			if (this.mandatoryDocumentList.length === 0) {
				this.allMandatoryDocsUploaded.emit(true);
			}
			return true;
		} else {
			return false;
		}
	}

	public checkVisaPassportDocsUploaded() {
		if (this.filteredDocuments.find(document => document.type === 'eid' && !this.isShortTerm) || (this.filteredDocuments.find(document => document.type === 'pp') && (this.filteredDocuments.find(document => document.type === 'vi')))) {
			this.stillNeedVisaPassportDocs = false;
			this.mandatoryDocumentList = [];
			this.haveMandatory = true;
			this.allMandatoryDocsUploaded.emit(true);
			return '';
		}
		this.stillNeedVisaPassportDocs = true;
		this.allMandatoryDocsUploaded.emit(false);
		if (this.filteredDocuments.find(document => document.type === 'pp') && (!this.filteredDocuments.find(document => document.type === 'vi'))) {
			return (this.isShortTerm ? 'Visa Copy' : 'Emirates ID\nor\nVisa Copy');
		}
		if (this.filteredDocuments.find(document => document.type === 'vi') && (!this.filteredDocuments.find(document => document.type === 'pp'))) {
			return (this.isShortTerm ? 'Passport' : 'Emirates ID\nor\nPassport');
		}
		return (this.isShortTerm ? 'Visa Copy & Passport' : 'Emirates ID\nor\nVisa Copy & Passport');
	}

	public viewDoc = async (document) => {
		this.openedDocument = undefined;
		this.openedDocument = document;

		if (!this.isDocImage(document) && !this.isDocPDF(document)) {
			this.displayFileViewer = true;
			return;
		}

		this.displayFileViewer = true;
		if (this.isDocImage(document) || document.url.includes('firebasestorage.googleapis.com') || document.url.includes('storage.googleapis.com')) {
			this.openedDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(document.url);
		} else {
			this.isDocumentDownloading.next(true);
			let docType = document.contentType;
			if (this.imgTypes.includes(document.contentType)) docType = 'image/' + docType;
			else if (document.contentType === 'pdf') docType = 'application/pdf';
			this.openedDocumentUrl = await this.documentService.getDocumentBlob(document, docType);
			this.isDocumentDownloading.next(false);
			this.openedDocumentUrl = URL.createObjectURL(this.openedDocumentUrl);
			this.openedDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.openedDocumentUrl);

		}


	}
	public isDocImage(document) {
		return document.contentType.split('/')[0] === 'image' || this.imgTypes.includes(document.contentType);
	}
	public isDocPDF(document) {
		return ['application/pdf', 'pdf'].includes(document.contentType);
	}
	async changeDocumentType(documentId:string){
		this.displayFileViewer=false;
		this.openedDocument = undefined;
		this.confirmationService.confirm( {
			header: 'Change attachment type',
			message: `Are you sure you want to change document type to purchase invoice`,
			accept: async () => {
				try {
					const document= await this.documentService.getBy('id',documentId)
					await this.documentService.put(document[0].id,{...document[0], type:DocumentTypeEnum.PurchaseInvoice});
					await this.initialize();
					this.toastr.success(`Success! The document type has been changed successfully.`);
				} catch (error) {
				this.toastr.error('There was an error changing the document type. Please try to upload the document again.', 'Failure', { timeOut: 5000 });
				}
			}
		} );
    }

	onFilterChange() {
		this.filteredDocuments = this.documents.sort(sortByN(['type', 'name']));
		if (this.filterFrom) {
			this.filteredDocuments = this.filteredDocuments.filter(document => document.uploadDate && document.uploadDate !== 'Invalid date');
			this.filteredDocuments = this.filteredDocuments.filter(document => document.uploadDate >= format(new Date(this.filterFrom), 'yyyyMMdd'));
		}
		if (this.filterTo) {
			this.filteredDocuments = this.filteredDocuments.filter(document => document.uploadDate && document.uploadDate !== 'Invalid date');
			this.filteredDocuments = this.filteredDocuments.filter(document => document.uploadDate <= format(new Date(this.filterTo), 'yyyyMMdd'));
		}
		if (this.serviceProvider) {
			this.filteredDocuments = this.filteredDocuments.filter(document => document.contactName.toLowerCase().indexOf(this.serviceProvider.toLowerCase()) !== -1);
		}
		if (this.expiryDays && this.showExpiry) {
			this.filteredDocuments = this.filteredDocuments.filter(document => document.endDate && document.endDate !== 'Invalid date');
			switch (this.expiryDays) {
				case 'expired':
					this.filteredDocuments = this.filteredDocuments.filter(document => document.endDate <= format(new Date(), 'yyyyMMdd'));
					break;
				case '30':
					this.filteredDocuments = this.filteredDocuments.filter(document =>
						differenceInDays(parse(document.endDate, 'yyyyMMdd', new Date()), new Date()) <= 30 || document.endDate <= format(new Date(), 'yyyyMMdd')
					);
					break;
				case '60':
					this.filteredDocuments = this.filteredDocuments.filter(document =>
						differenceInDays(parse(document.endDate, 'yyyyMMdd', new Date()), new Date()) <= 60 || document.endDate <= format(new Date(), 'yyyyMMdd')
					);
					break;
				case '90':
					this.filteredDocuments = this.filteredDocuments.filter(document =>
						differenceInDays(parse(document.endDate, 'yyyyMMdd', new Date()), new Date()) <= 90 || document.endDate <= format(new Date(), 'yyyyMMdd')
					);
					break;
				default:
					break;
			}
		}
		if (this.filterValue) {
			this.filterValue = this.filterValue.trim();
			this.filteredDocuments = this.documents.filter(document => document.type === this.filterValue);
		}
	}

	async exportDocumentList() {
		const options = {
			filename: `Documents_Expiry_List_as_${format(new Date(), 'yyyymmdd')}`,
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			showLabels: true,
			showTitle: true,
			title: `Documents Expiry List as ${format(new Date(), 'yyyymmdd')}`,
			useTextFile: false,
			useBom: true,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		const documentsList = [];
		for (const document of this.filteredDocuments) {
			documentsList.push({
				FileName: document.name,
				StartDate: document.startDate,
				EndDate: document.endDate,
				notificationRange: document.endDate,
				UploadedBy: document.uploadedBy,
				DownloadURL: document.url,
			})
		}
		(documentsList.length > 0) ? await csvExporter.generateCsv(documentsList) : this.toastr.error('There is nod documents');
	}
	addedFile() {
		this.add.emit(true)
	}
	async approveRejectDocument(action: string, documentId: string) {
		if (!(this.vendorDocuments || this.approveByActorType || this.leaseId)) return;
		this.selectedVendorDocument = objectify(this.filteredDocuments.find(document => document.id === documentId))
		switch (action) {
			case 'approve':{
				this.isSaving = true;
				const { result, summary, msg } = checkApprovalByActorType({
					vendorDocument: this.selectedVendorDocument,
					approveByActorType: this.approveByActorType,
					isPropertyAccountanat: this.isPropertyAccountanat,
					isPropertyManager: this.isPropertyManager,
				});
				if (result) {
					if ((this.selectedVendorDocument?.documentType?.hasExpiryDate && !this.selectedVendorDocument.docExpiryDate) ||
						(this.selectedVendorDocument?.documentType?.hasEndDate && !this.selectedVendorDocument.docEndDate)) {
						this.toastr.error('Please Enter The Missing Date!');
					} else {
						if (this.isPropertyAccountanat && this.selectedVendorDocument.approvalStatus === 'approved') {
							this.selectedVendorDocument.approved = true;
						}
						this.selectedVendorDocument.approvalStatus = 'approved';
						this.selectedVendorDocument.rejectReason = '';
						await this.documentService.put(this.selectedVendorDocument.id, this.selectedVendorDocument);
						this.filteredDocuments.find(doc => doc.id == this.selectedVendorDocument.id).approvalStatus = 'approved';
						this.toastr.success('Document Approved Successfully!');
					}
				} else {
					this.documentService.addMessage('warn', summary, 'errorToaster', msg);
				}
				this.isSaving = false;
				break;
			}
			case 'reject':
				this.showReasonDialog = true;
				break;
		}
	}

	async approveRejectLeaseDocument(action: string, documentId: string, contactType: ContactType) {
		this.selectedLeaseDocument = objectify(this.filteredDocuments.find(document => document.id === documentId));
		this.contactType = contactType;
		switch (action) {
			case 'approve':
				this.approveLeaseDocument = true;
				break;
			case 'reject':
				this.rejectLeaseDocument = true;
				break;
		}
	}
	getFilteredSentHistory(document: DocumentPG, contactType: ContactType) {
		if (Array.isArray(document?.sendHistory)) {
			return document?.sendHistory.filter(d => d.contactType == contactType)
		}
		return []
	}
	async sendToTenantLandlord(document: DocumentPG, contactType: ContactType, leaseId: string) {
		try {
			const unitId = this.router.url.split('/')[4];
			if (contactType == 'landlord') { this.sendToLandlordLoadingMap[document.id] = true }
			else { this.sendToTenantLoadingMap[document.id] = true; }
			const docType = document.type as DocumentTypeValues;
			await this.leaseService.sendLeaseDocument(document.id, contactType, leaseId, '', '', '', unitId, docType).then(() => {
				if (contactType == 'landlord') { this.sendToLandlordLoadingMap[document.id] = false }
				else { this.sendToTenantLoadingMap[document.id] = false; }
			});
		} catch (error) {
			this.toastr.error('Document Send Failed!');
		}
		this.initialize()
	}
	async submitApproveRejectLeaseDocument(action: string) {
		this.isSaving = true;
		this.showReasonDialog = false;
		// add to approval history array and filter if any of the array element ...
		// ... are have one tenet or landlord approval then make approval for it disable
		switch (action) {
			case 'approve':
				this.contactType == 'landlord' ? this.selectedLeaseDocument.landlordApprovalStatus = 'approved' : this.selectedLeaseDocument.tenantApprovalStatus = 'approved';
				await this.documentService.put(this.selectedLeaseDocument.id, this.selectedLeaseDocument);
				this.toastr.success('Document Approved Successfully!');
				this.approveLeaseDocument = false;
				this.isSaving = false;
				await this.initialize();
				break;
			case 'reject':
				this.contactType == 'landlord' ? this.selectedLeaseDocument.landlordApprovalStatus = 'rejected' : this.selectedLeaseDocument.tenantApprovalStatus = 'rejected';
				await this.documentService.put(this.selectedLeaseDocument.id, this.selectedLeaseDocument);
				this.toastr.success('Document Rejected Successfully!');
				this.rejectLeaseDocument = false;
				this.isSaving = false;
				await this.initialize();
				break;
		}
	}
	async rejectDocument(rejectReason: string) {
		this.isSaving = true;
		if (!this.vendorDocuments && !this.approveByActorType && !this.leaseId) return
		this.showReasonDialog = false;
		this.selectedVendorDocument.approvalStatus = 'rejected';
		this.selectedVendorDocument.rejectReason = rejectReason;
		if (!this.leaseId)
			await this.documentService.put(this.selectedVendorDocument.id, this.selectedVendorDocument);
		this.toastr.success('Document Rejected Successfully!');
		this.isSaving = false;
		this.filteredDocuments.find(doc => doc.id == this.selectedVendorDocument.id).approvalStatus = 'rejected';
	}

	success() {
		this.toastr.success("Copied To Clipboard")
	}

	showApprovalHistory(document) {
		this.selectedDocumentHistory = document.approvalHistory || [];
		this.showApprovalHistoryDialog = true;
	}
}

export function checkApprovalByActorType(args: Partial<{
	vendorDocument: DocumentPG,
	approveByActorType: boolean,
	isPropertyAccountanat: boolean,
	isPropertyManager: boolean,
}>
): { result: boolean, summary: string, msg: string } {

	let msg = '';
	let summary = '';
	let result = true;
	if (!args.approveByActorType || args.vendorDocument.type !== DocumentTypeEnum.Attachment) return { result: true, msg: '', summary: '' };
	if (args.isPropertyManager && args.vendorDocument.approvalStatus === 'approved' && !args.isPropertyAccountanat) {
		summary = 'Invoice pending Accountant approval';
		msg = 'Please make sure the invoice is approved by accountant '
		result = false;
	}
	if (args.isPropertyAccountanat && !args.isPropertyManager && args.vendorDocument.approvalStatus !== 'approved') {
		summary = 'Invoice is not approved by CM';
		msg = 'Please make sure the invoice is approved by Community Manager in order to be able to approve and post it';
		result = false;
	}

	if (!args.isPropertyAccountanat && !args.isPropertyManager) {
		summary = 'You dont have access';
		msg = 'Only Accountants & Community Managers can approve invoices, please contact your Admin for the access you need';
		result = false;
	}

	return { result, summary, msg };
}
