
import { Component, OnInit } from '@angular/core';
import { SystemService } from './system.service';
import { MessageService } from 'primeng/api';
import { VersionCheckService } from './version-check.service';
import { ClientService } from './client.service';
import { setTheme } from 'ngx-bootstrap/utils';

@Component( {
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ],
} )
export class AppComponent implements OnInit {
	public clientInfo: any;
	public titleIcon = '';
	public title = '';
	constructor (
		private ss: SystemService,
		public messageService: MessageService,
		private readonly versionCheckService: VersionCheckService,
		private clientService: ClientService,
	) {
		setTheme('bs4'); 

		console.log( 'Remove error and loader classes from styles.scss' );
	}

	async ngOnInit () {
		this.clientInfo = await this.clientService.getClientByUrl();
		this.titleIcon = this.clientInfo?.titleIcon;
		this.title = this.clientInfo?.title;
		let link = document.querySelector( "link[rel~='icon']" );
		if ( !link ) {
			link = document.createElement( 'link' );
			link[ 'rel' ] = 'icon';
			document.getElementsByTagName( 'head' )[ 0 ].appendChild( link );
		}
		document.title = this.title;
		link[ 'href' ] = this.titleIcon;
		this.versionCheckService.initVersionCheck();
	}
}
