import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root',
} )

export class VersionCheckService {
	// this will be replaced by actual hash post-build.js
	private currentHash = '';

	constructor ( private http: HttpClient ) {
		this.http.get( `${ BASE_API_URL }/socientaVersion` )
			.pipe( first() )
			.subscribe(
				( response: any ) => {
					this.currentHash = response.hash;
				},
				( err ) => {
					console.error( err, 'Could not get version' );
				}
			);
	}

	/**
		* Checks in every set frequency the version of frontend application
		* @param {number} frequency - in milliseconds, defaults to 30 minutes
		*/
	public initVersionCheck ( frequency = 1000 * 60 * 1 ) {
		setInterval( () => {
			this.checkVersion();
		}, frequency );
	}

	/**
		* Will do the call and check if the hash has changed or not
		*/
	private checkVersion () {
		// timestamp these requests to invalidate caches
		this.http.get( `${ BASE_API_URL }/socientaVersion` )
			.pipe( first() )
			.subscribe(
				( response: any ) => {
					const hash: string = response.hash;
					// If new version, do something
					if ( hash !== this.currentHash ) {
						location.reload();
					}

					// store the new hash so we wouldn't trigger versionChange again
					// only necessary in case you did not force refresh
					this.currentHash = hash;
				},
				( err ) => {
					console.error( err, 'Could not get version' );
				}
			);
	}
}
