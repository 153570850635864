import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessTokenResponse, EmbedYFEntity, EmbedYFFilter, LoginTokenResponse, YFDashboard, YFReport } from '../../../models/yellowfin.models';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { BehaviorSubject, catchError, filter, interval, map, NEVER, of, retry, shareReplay, startWith, switchMap, take, tap } from 'rxjs';
import { SystemService } from './system.service';
import { environment } from '../environments/environment';
import { ApiResponse } from '../../../models/apiResponse.model';

const BASE_API_URL = environment.apiURL;
const yellowfinSetting = environment.yellowfinSetting;

const pollTime = 1000 * 60 * 29
const loginTokenCacheTime = 27 * 60 * 1000
@Injectable({
	providedIn: 'root'
})
export class YellowfinService {

	private currentSSOTokenBS$ = new BehaviorSubject(null);
	currentSSOToken$ = this.currentSSOTokenBS$.asObservable();
	isYellowfinLoaded = false;
	loginToken$ = interval(pollTime).pipe(
		startWith(''),
		switchMap(() => this.getLoginToken()),
		retry(5),
		shareReplay(1)
	)

	constructor(
		private http: HttpClient,
		private ss: SystemService,
		private toaster: CustomToastrService
	) { }


	getLoginToken() {
		return this.ss.isActive.pipe(
			filter(active => !!active),
			switchMap(() => {
				const now = Date.now();
				const stored = localStorage.getItem('yellowfin-login-token');
				if (stored) {
					const { token, timestamp } = JSON.parse(stored);
					if (now - timestamp < loginTokenCacheTime) {
						return of({ token } as LoginTokenResponse);
					}
				}
				return this.http.get<LoginTokenResponse>(`${BASE_API_URL}/yellowfin/loginToken`).pipe(
					tap(response => {
						localStorage.setItem('yellowfin-login-token', JSON.stringify({ token: response, timestamp: now }));
					})
				);
			}),
			catchError(() => {
				this.toaster.error("Error on loading dashboard on server side");
				return NEVER;
			})
		)
	}

	getSSOToken() {
		return this.ss.isActive.pipe(
			filter(active => !!active),
			switchMap(() => this.http.get<AccessTokenResponse>(`${BASE_API_URL}/yellowfin/sso`)),
			tap(token => this.currentSSOTokenBS$.next(token)),
			catchError(() => {
				this.toaster.error("Error on loading dashboard on server side");
				return NEVER;
			})
		)

	}

	createYFDashboard(dashboard: YFDashboard) {
		return this.http.post<YFDashboard>(`${BASE_API_URL}/yellowfin/dashboards`, dashboard)
	}

	getYFDashboards(dashboardFilters: Partial<YFDashboard> = {}) {
		const params = dashboardFilters
		return this.http.get<YFDashboard[]>(`${BASE_API_URL}/yellowfin/dashboards`, {
			params
		})
	}
	createYFReport(report: YFReport) {
		return this.http.post<YFReport>(`${BASE_API_URL}/yellowfin/reports`, report)
	}

	getYFReports(reportFilters: Partial<YFReport> = {}) {
		const params = reportFilters
		return this.http.get<YFReport[]>(`${BASE_API_URL}/yellowfin/reports`, {
			params
		})
	}

	getYFEntityById(id: string) {
		return this.http.get<ApiResponse<EmbedYFEntity>>(`${BASE_API_URL}/v2/yellowfin/embed-entity/${id}`)
			.pipe(map(res => res.data))
	}

	getYFEntityFiltersById(id: string) {
		return this.http.get<ApiResponse<EmbedYFFilter[]>>(`${BASE_API_URL}/v2/yellowfin/embed-entity/${id}/filters`)
			.pipe(map(res => res.data))
	}


	deleteYFReport(id: string) {
		return this.http.delete(`${BASE_API_URL}/yellowfin/reports/${id}`)
	}
	updateYFReport(id: string, report: YFReport) {
		return this.http.put(`${BASE_API_URL}/yellowfin/reports/${id}`, report)
	}


	async redirectToYellowfin() {
		this.getSSOToken().pipe(take(1)).subscribe(({ securityToken }) => {
			window.open(this.getYfRedirectUrl(securityToken))
		})
	}



	getYfRedirectUrl(securityToken: string): string {
		const yellowfinUrl = yellowfinSetting.url.replace('/api', '')
		return `${yellowfinUrl}/logon.i4?LoginWebserviceId=${securityToken}`;
	}


	destroySSOToken(tokenLink: string) {
		return this.http.post(`${BASE_API_URL}/yellowfin/sso/destroy`, { tokenLink: tokenLink })
	}
	destroyLoginToken(tokenLink: string) {
		localStorage.removeItem('yellowfin-login-token');
		return this.http.post(`${BASE_API_URL}/yellowfin/login/destroy`, { tokenLink: tokenLink })
	}



}


