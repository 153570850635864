import { BehaviorSubject } from 'rxjs';
import { waiter } from '../../../../utils/waiter';

export const getNonNullValue = async <T>( payload: BehaviorSubject<T>, duration = 300 ):Promise<T> => {
	const possibleReturn = payload.getValue();
	if ( possibleReturn ) {
		return possibleReturn;
	} else {
		await waiter( duration );
		return await getNonNullValue( payload, duration + 50 );
	}
};

export const getNonNullNonEmptyValue = async <T = any> ( payload: BehaviorSubject<T>, duration = 300 ): Promise<T> => {
	const possibleReturn = payload.getValue();
	let isNonEmpty = false;
	if ( possibleReturn && Array.isArray( possibleReturn ) && possibleReturn.length > 0 ) isNonEmpty = true;
	if ( possibleReturn && !Array.isArray( possibleReturn ) && Object.values( possibleReturn ).length > 0 ) isNonEmpty = true;
	if ( isNonEmpty ) {
		return possibleReturn;
	} else {
		await waiter( duration );
		return await getNonNullNonEmptyValue( payload, duration + 50 );
	}
};
