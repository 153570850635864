<!-- TODO ALI TO REVIEW -->
<p-dialog [(visible)]="display" [style]="{'width': '85vw', 'height':'80vh'}" *ngIf="display" [draggable]='false' (onHide)="close()"
	[closable]="false" [modal]="true" [breakpoints]="{'960px': '95vw', '640px': '100vw'}">
	<p-header>
		<span>Upload Document!</span>
		<p-fileUpload #fubauto mode="basic" [maxFileSize]="maxFileSize" (onSelect)="onSelectFile($event,fubauto)" [auto]="true"
			chooseIcon="pi pi-file" chooseLabel="Browse" class="template-btn float-right" [multiple]="allowMultiple"
			name="fileSelect" [accept]="acceptedFiles" invalidFileTypeMessageDetail="Invalid file type, Accepted formats are: {{acceptedFiles}}">
		</p-fileUpload>
	</p-header>
	<div #body class="p-col-12 p-py-3">
		<form #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
			<p-table data-cy="data-table" [value]="fileList" class="p-col-12" [autoLayout]="true" [scrollable]="true" scrollHeight="flex"
				[resizableColumns]="true">
			<ng-template pTemplate="header">
			<tr class="table-header">
				<th>Name</th>
				<th>Type</th>
				<th *ngIf="!isContact">Dates</th>
				<th class="m-cell" *ngIf="(ss.currentUser?.profileType==='Employee' || !isContact) && needNotificationRange">
					Notification Range</th>
				<th *ngIf="!isLeasingDocuments">Service Provider</th>
				<th class="s-cell" *ngIf="!(isContact || isLeasingDocuments)">Status</th>
				<th class="s-cell"></th>
			</tr>
			</ng-template>
		<ng-template pTemplate="body" let-file let-i="rowIndex">
			<tr>
				<td>
					<span class="p-column-title">Name:</span>
					{{file.name}}
				</td>
				<td>
					<span class="p-column-title">Type:</span>
					<div *ngIf="data.limitTo.length !==1 ">
						<select [attr.data-cy]="'select-s'" style="width: 100%;" class="input-group form-control IcurvedBorder ml-0 pl-1" name="doctype{{i}}"
							(ngModelChange)="onSelectDocumentType($event, file)" [(ngModel)]="file.type" placeholder="Choose Contact Type"
							id="doctype{{i}}" >
							<option value="">Please Select</option>
							<option *ngFor="let docType of documentTypes" [value]="docType.id" [attr.option-has-date1-cy]="docType.hasStartDate && docType.hasEndDate" [attr.option-has-date2-cy]="docType.hasExpiryDate && docType.hasIssueDate">
								{{ docType.description }}</option>
						</select>
					</div>
					<div *ngIf="data.limitTo.length == 1">
						<div *ngFor="let docType of documentTypes">
							{{docType.description}}
						</div>
					</div>
				</td>
					<td *ngIf="!isContact">
					<span class="p-column-title">Dates:</span>
					<ng-container *ngIf="file.showStartEndtDate">
						<div class="form-group">
							<div data-cy="startEndDate" class="input-group input-group-sm">
								<p-calendar [(ngModel)]="file.startDate" name="sd{{i}}" placeholder="Start date" dateFormat="yy-mm-dd" dataType="string"
									[panelStyle]="{'width': '24rem'}" appendTo="body">
								</p-calendar>
								<p-calendar [(ngModel)]="file.endDate" name="ed{{i}}" placeholder="End date" dateFormat="yy-mm-dd" dataType="string"
									[panelStyle]="{'width': '24rem'}" appendTo="body">
								</p-calendar>
								<ng-container *ngIf="file.showRenewalDate">
									<p-calendar [(ngModel)]="file.renewalDate" name="rd{{i}}" placeholder="Renwal date" dateFormat="yy-mm-dd"
										dataType="string" [panelStyle]="{'width': '24rem'}" appendTo="body">
									</p-calendar>
								</ng-container>
							</div>
						</div>
					</ng-container>
					<ng-container>
						<div class="form-group">
							<div data-cy="issueExpiryDate" class="input-group input-group-sm">
								<ng-container *ngIf="file.showIssueDate">
									<p-calendar [(ngModel)]="file.issueDate" name="isd{{i}}" placeholder="Issue date" dateFormat="yy-mm-dd"
										dataType="string" [panelStyle]="{'width': '24rem'}" appendTo="body">
									</p-calendar>
									</ng-container>
									<ng-container *ngIf="file.showExpiryDate">
										<p-calendar [(ngModel)]="file.expiryDate" name="exd{{i}}" placeholder="Expiry date" dateFormat="yy-mm-dd"
											dataType="string" [panelStyle]="{'width': '24rem'}" appendTo="body">
										</p-calendar>
									</ng-container>
									<p-calendar *ngIf="file.type === 'pp' && (ss.currentUser?.profileType==='Employee' || !isContact)"
										[(ngModel)]="file.dob" name="dob{{i}}" placeholder="DOB" dateFormat="yy-mm-dd" dataType="string"
										[panelStyle]="{'width': '24rem'}" appendTo="body">
									</p-calendar>
							</div>
							</div>
							</ng-container>
							<ng-container *ngIf="file.showDueDate">
								<div class="form-group">
									<div class="input-group input-group-sm">
										<p-calendar [(ngModel)]="file.dueDate" name="dued{{i}}" placeholder="Due date" dateFormat="yy-mm-dd" dataType="string"
											[panelStyle]="{'width': '24rem'}" appendTo="body">
										</p-calendar>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="file.showIssueDate">
								<div class="form-group">
									<div class="input-group input-group-sm">
										<p-calendar [(ngModel)]="file.issueDate" name="isd{{i}}" placeholder="Issue date" dateFormat="yy-mm-dd"
											dataType="string" [panelStyle]="{'width': '24rem'}" appendTo="body">
										</p-calendar>
									</div>
								</div>
							</ng-container>
							</td>
				<td  class="m-cell" *ngIf="!isContact && needNotificationRange">
					<span class="p-column-title">Notification Range:</span>
					<div class="form-group">
						<div class="mt-1 mb-1 ml-1 mr-1 noBorders" *ngIf="file.notification_range !== undefined">
							<input class="form-control form-control-sm" name="notification_range{{i}}" [(ngModel)]="file.notification_range"
								type="text" style="width:100% !important">
						</div>
						</div>
						</td>
						<td *ngIf="!isLeasingDocuments">
							<span class="p-column-title">Service Provider:</span>
							<p-dropdown [virtualScroll]="true" [virtualScrollItemSize]="30"
								*ngIf="file.type === 'project-documents-section-a01' || file.type === 'project-documents-section-a11'"
								[filter]="true" filterBy="displayName"  name="contact{{i}}" appendTo="body"
								[options]="contactsArrayUploader" [(ngModel)]="file.service" autoWidth="false" [style]="{'width':'100%'}"
								optionLabel="displayName" optionValue="id" placeholder="Please Choose Service Provider "
								(onChange)="onContactSelect($event, file)">
							</p-dropdown>
						</td>
				<td class="s-cell" *ngIf="!(isContact || isLeasingDocuments)" data-cy="status-msg">
					<span class="p-column-title">Status:</span>
					<span *ngIf="file.status">
						{{ file.status }}
					</span>
				</td>
				<td class="s-cell"><button type="button" (click)="onDeleteDocument(i)"
						class="btn btn-sm btn-outline-none">
						<i class="fa fa-trash text-dark icon-style mt-1 mr-1" style="color: #3f3d56;" aria-hidden="true"
							(onclick)="onDeleteDocument(i)"></i>
					</button></td>
				</tr>
				</ng-template>
				<ng-template #uploadingRef>
					<div class="text-center mx-3 my-1">
						<div class="spinner-border	" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</ng-template>
				</p-table>
			</form>
	</div>
	<p-footer>
		<button *ngIf="!isUploading;else uploadingRef" pButton type="button" class="p-button-outlined" label="Cancel"
			(click)="close()"> </button>
		<button data-cy="submit-" type="button" pButton class="p-button-primary" label="Submit" (click)="onSubmit()"
			[disabled]="form.invalid || fileList.length==0 || isUploading"></button>
	</p-footer>
	
</p-dialog>
