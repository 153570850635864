import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class FcmTokenService {

	constructor (
		private http: HttpClient,
	) { }

	register ( token: string ): Promise<any> {
		return firstValueFrom( this.http.post( `${ BASE_API_URL }/token/register-token`, { token } ) );
	}

	unregister = async ( token: string ) => {
		return firstValueFrom( this.http.post( `${ BASE_API_URL }/token/unregister-token`, { token } ) );
	}
}
