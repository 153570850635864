<!-- TODO ALI TO REVIEW -->
<div id="main-wrapper-id">

	<ng-container *ngIf="isEmailVerified">
		<div class="custom-navbar-wrapper">
			<div class="fixed-top">
				<app-nav-bar>
				</app-nav-bar>
			</div>
		</div>

	</ng-container>


	<div #container class="content-main" [@routerTransition]="getAnimationState(outlet)" [style]="{overflow: 'auto'}">
		<router-outlet #outlet="outlet"></router-outlet>
	</div>

</div>

