
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploaderComponent } from './document-uploader.component';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { CXFileUploaderComponent } from '../cx-file-uploader/cx-file-uploader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { FileViewerComponent } from '../cx-file-uploader/file-viewer/file-viewer.component';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AppAdditionalModule } from '../../shared/components/app-additional/app-additional.module';

@NgModule( {
	declarations: [ DocumentUploaderComponent, CXFileUploaderComponent, FileViewerComponent ],
    imports: [
        CommonModule,
        FormsModule,
        NgxMaskModule.forChild(),
        BsDatepickerModule,
        DialogModule,
        ButtonModule,
        TableModule,
        FileUploadModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        TooltipModule,
        TagModule,
        DropdownModule,
        CalendarModule,
        AppAdditionalModule,
    ],
	exports: [ DocumentUploaderComponent, CXFileUploaderComponent, FileViewerComponent ],
} )
export class DocumentUploaderModule { }
