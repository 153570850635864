
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMap } from '../../../models/generic.models';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { getNonNullNonEmptyValue, getNonNullValue } from 'src/app/utils/getNonNullValue';
import { arraytomap, maptoarray } from '../../../utils/mapandarray';
import { AuthService } from './auth.service';
import { DocumentType } from '../../../models/document.pg.model';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class DocumentTypesService {
	private initialized = false;

	public documentTypes: BehaviorSubject<IMap<DocumentType>> = new BehaviorSubject( {} );
	public documentTypesArray: BehaviorSubject<DocumentType[]> = new BehaviorSubject( [] );
	public documentType: BehaviorSubject<DocumentType> = new BehaviorSubject( null );
	public documentTypeId: BehaviorSubject<string> = new BehaviorSubject( null );

	private subs: Subscription[] = [];
	constructor (
		private http: HttpClient,
		private tt: CustomToastrService,
		private authService: AuthService,
	) {
		this.initialize();
	}

	private initialize = async () => {
		if ( this.initialized ) return;
		this.initialized = true;

		this.authService.isActive.pipe( distinctUntilChanged() ).subscribe( async ( isActive ) => {
			if ( isActive ) {
				this.documentTypesGet();
				this.subs.push( this.documentTypeId.pipe( distinctUntilChanged() ).subscribe( ( id: string ) => this.documentTypeGet( id ) ) );
			} else {
				this.subs.forEach( sub => {
					sub.unsubscribe()
				} );
			}
		} );
	}

	public documentTypesFetch = async () => {
		try {
			// to check index.ts
			const documentTypes = await firstValueFrom( this.http.get<DocumentType[]>( `${ BASE_API_URL }/documentType` ) );
			this.documentTypesArray.next( documentTypes );
			this.documentTypes.next( arraytomap( documentTypes ) );
			return documentTypes;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Document Types from the server' );
			console.error( error );
			throw error;

		}

	}

	public procuremtDocumentTypesFetch = async () => {
		try {
			const documentTypes = await firstValueFrom( this.http.get<DocumentType[]>( `${ BASE_API_URL }/procuremtDocumentType` ) );
			return documentTypes;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Document Types from the server' );
			console.error( error );
			throw error;

		}

	}


	private documentTypeFetch = async ( documentTypeId: string ) => {
		if ( documentTypeId ) {
			try {
				// to check index.ts
				return await firstValueFrom( this.http.get<DocumentType>( `${ BASE_API_URL }/documentType/${ documentTypeId }` ) );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch document type from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Document Type fetch is requested without a document Type ID' );
			throw new Error( 'Document Type fetch is requested without a document Type ID' );
		}
	}

	private documentTypesGet = async () => {
		const documentTypes = await this.documentTypesFetch();
		this.documentTypesArray.next( documentTypes );
		this.documentTypes.next( arraytomap( documentTypes ) );
		return true;
	}

	private documentTypeGet = async ( documentTypeId: string ) => {
		this.documentType.next( null );
		if ( documentTypeId ) {
			const tmp = ( await this.documentTypesGet() )[ documentTypeId ];
			if ( tmp ) {
				this.documentType.next( tmp );
			} else {
				this.documentType.next( await this.documentTypeFetch( documentTypeId ) );
			}
		}
	}

	public documentTypeCreate = async ( documentTypePayload: Partial<DocumentType> ) => {
		let newDocumentType = null;
		try {
			// to check index.ts
			newDocumentType = await firstValueFrom( this.http.post<DocumentType>( `${ BASE_API_URL }/documentType`, documentTypePayload ) );
		} catch ( error ) {
			this.tt.error( 'Failed to create the document type on the server' );
			console.error( error );
			throw error;
		}
		await this.documentTypesGet();
		return newDocumentType;
	}


	public documentTypeUpdate = async ( documentTypeId: string, documentTypePayload: Partial<DocumentType> ) => {
		if ( !documentTypeId ) {
			this.tt.warning( 'Document type update is requested without a document type ID' );
			throw new Error( 'Document type update is requested without a document type ID' );
		}
		try {
			// to check index.ts
			const response: any = await firstValueFrom( this.http.put( `${ BASE_API_URL }/documentType/${ documentTypeId }`, documentTypePayload ) );
			documentTypePayload.id = documentTypeId;
			let documentType = await this.getDocumentTypeById( documentTypeId );
			documentType = { ...documentType, ...documentTypePayload };
			const documentTypes = await this.getDocumentTypes();
			documentTypes[ documentTypeId ] = documentType;
			this.documentTypes.next( documentTypes );
			this.documentTypesArray.next( maptoarray( documentTypes ) );
		} catch ( error ) {
			this.tt.error( 'Failed to update the document type' );
			console.error( error );
			throw error;
		}

		return true;
	}

	public documentTypeDelete = async ( documentTypeId: string ) => {
		if ( !documentTypeId ) {
			this.tt.warning( 'Document type delete is requested without a document type ID' );
			throw new Error( 'Document type delete is requested without a document type ID' );
		}
		try {
			const response: any = await firstValueFrom( this.http.delete( `${ BASE_API_URL }/documentType/${ documentTypeId }` ) );
			this.documentTypesFetch();
		} catch ( error ) {
			this.tt.error( 'Failed to delete the document type' );
			console.error( error );
			throw error;
		}

		return true;
	}


	public getDocumentTypesObservable = (): Observable<DocumentType[]> => {
		try {
			// to check index.ts
			return this.http.get<DocumentType[]>( `${ BASE_API_URL }/documentType`, );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Document Types from the server' );
			console.error( error );
			throw error;

		}

	}
	public getDocumentType = async () => await getNonNullNonEmptyValue<DocumentType>( this.documentType );
	public getDocumentTypeId = async () => await getNonNullValue( this.documentTypeId );
	public getDocumentTypes = async () => await getNonNullNonEmptyValue<IMap<DocumentType>>( this.documentTypes );
	public getDocumentTypesArray = async () => await getNonNullNonEmptyValue<DocumentType[]>( this.documentTypesArray );
	public getDocumentTypeById = async ( id: string ) => ( await this.getDocumentTypes() )[ id ] || await this.documentTypeFetch( id );

	public getAllDocumentsTypes = async () => {
		try {
			const documentTypes = await firstValueFrom( this.http.get<DocumentType[]>( `${ BASE_API_URL }/documentTypes` ) );
			return documentTypes;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Document Types from the server' );
			console.error( error );
			throw error;

		}

	}
}
