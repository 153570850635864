import { objectify } from '../utils/objectify';
import { RequestTypeEnumKey } from './request.models';

export interface RequestDetails {
	number: string;
	rating: number;
	notes: string;
	cm: string;
	migratedDetails?: MigratedDetails;
	/* TODO this index signature is a dirty fix for functions.request.ts requestGetBy function, the function should be refactored with a different approach */
	[key:string]: any
}

export interface MigratedDetails {
	unitName: string;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	buildingName: string,
	communityName: string,
}

export const getDefaultMigratedDetails = () => {
	return objectify( defaultMigratedDetails );
};

const defaultMigratedDetails: MigratedDetails = {
	unitName: '',
	contactName: '',
	contactEmail: '',
	contactPhone: '',
	buildingName: '',
	communityName: '',
};

export const getDefaultRequestDetails = () => {
	return objectify( defaultRequestDetails );
};

const defaultRequestDetails: RequestDetails = {
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export interface RequestDetailsMove extends RequestDetails {
	moveInDate?: string,
	moveOutDate?: string,
	leaseStartDate: string,
	leaseEndDate?: string,
	vehicleDetails?: string,
	comments?: string,
	isShortTerm?: boolean,
	isOwnUnit?: boolean,
	booking?: string,
	building?: string,
	moveOutReason?: string,
	petDetails?: string,
	petDetailedDetails?: PetDetailedDetails,
	nationality?: string,
	numberOfPeople?: number,
	ownerNumber?: string,
	movingCompanyDetails?: MovingCompanyDetails,
	numberOfResidents?: number,
	granteeContact?: GranteeContact,
	newContacts?: any[],
	ejariNumber?: string,
}

export interface PetDetailedDetails {
	petType: string;
	vaccinationDate: string;
	petId: string;
}
const defaultPetDetailedDetails: PetDetailedDetails = {
	petType: '',
	vaccinationDate: '',
	petId: '',
};

export const getDefaultPetDetailedDetails = () => {
	return objectify( defaultPetDetailedDetails );
};
export interface MovingCompanyDetails {
	name: string,
	vehicleDetails: string,
	contractNumber: string,
}

const defaultMovingCompanyDetails: MovingCompanyDetails = {
	name: '',
	vehicleDetails: '',
	contractNumber: '',
};

export const getDefaultMovingCompanyDetails = () => {
	return objectify( defaultMovingCompanyDetails );
};

export interface GranteeContact {
	id: string,
	name: string,
	middleName: string,
	surname: string,
	emails: any[],
	phones: any[],
	mailsj: string,
	granteeContactIsNew: boolean,
}
const defaultGranteeContactDetails: GranteeContact = {
	id: '',
	name: '',
	middleName: '',
	surname: '',
	emails: [],
	phones: [],
	mailsj: '',
	granteeContactIsNew: false,
};

export const getDefaultedRequestForR = () => {
	return objectify( defaultGranteeContactDetails );
};

const defaultRequestDetailsMove: RequestDetailsMove = {
	moveInDate: '',
	moveOutDate: '',
	leaseEndDate: '',
	leaseStartDate: '',
	vehicleDetails: '',
	comments: '',
	isShortTerm: false,
	isOwnUnit: false,
	booking: '',
	building: '',
	moveOutReason: '',
	petDetails: '',
	petDetailedDetails: getDefaultPetDetailedDetails(),
	nationality: '',
	numberOfPeople: 1,
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
	ownerNumber: '',
	movingCompanyDetails: getDefaultMovingCompanyDetails(),
	numberOfResidents: 0,
	granteeContact: getDefaultedRequestForR(),
	newContacts: [],
	ejariNumber: '',
};



export const getDefaultRequestDetailsMoveRequest = () => {
	return objectify( defaultRequestDetailsMove );
};

export interface RequestDetailsOwnership extends RequestDetails {
	ownershipDate: string,
	comments?: string,
}

const defaultRequestDetailsOwnership: RequestDetailsOwnership = {
	ownershipDate: '',
	comments: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsOwnership = () => {
	return objectify( defaultRequestDetailsOwnership );
};

export type Reply = { text: string, author: string, time: string };
export interface RequestDetailsGeneral extends RequestDetails {
	subject: string,
	comments?: string,
	replies?: Reply[],
	building?: string,
}

const defaultRequestDetailsGeneral: RequestDetailsGeneral = {
	subject: '',
	comments: '',
	replies: [],
	rating: 0,
	notes: '',
	cm: '',
	building: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsGeneral = () => {
	return objectify( defaultRequestDetailsGeneral );
};
export type MaintenanceLocation = 'In Unit' | 'Common Area';
export interface RequestDetailsMaintenance extends RequestDetails {
	building: string,
	description: string,
	snagCategory?: number,
	snagSubCategory?: number,
	snag?: number,
	maintenanceCategoryName?: string,
	maintenanceLocation?: MaintenanceLocation,
}

const defaultRequestDetailsMaintenance: RequestDetailsMaintenance = {
	building: '',
	description: '',
	snagCategory: 0,
	snagSubCategory: 0,
	snag: 0,
	maintenanceCategoryName: '',
	maintenanceLocation: 'Common Area',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsMaintenance = () => {
	return objectify( defaultRequestDetailsMaintenance );
};

export type WorkPermitCategory = 'Major' | 'Minor' | 'Pre-Approval' | '';
export type DurationOfWork = '48 hours' | '24 hours' | '6 months' | 'more than 48 hours' | '';
export interface RequestDetailsWorkPermit extends RequestDetails {
	category: WorkPermitCategory;
	from: string;
	to: string;
	tradeLicenseValidity?: string;
	durationOfWork: DurationOfWork;
	contractorCompanyName: string;
	contractorName: string;
	contractorPhone: string;
	contractorEmergencyName?: string;
	contractorEmergencyPhone?: string;
	typeOfWork: string;
	descriptionOfWork: string;
	otherTypeSpecification?: string,
	location: string;
	workDescription?: string;
	tools?: string;
	building?: string,
	worksLocation: string,
}


const defaultRequestDetailsWorkPermit: RequestDetailsWorkPermit = {
	workDescription: '',
	tradeLicenseValidity: '',
	tools: '',
	contractorEmergencyPhone: '',
	contractorEmergencyName: '',
	typeOfWork: '',
	descriptionOfWork: '',
	to: '',
	location: '',
	from: '',
	durationOfWork: '',
	contractorPhone: '',
	contractorName: '',
	contractorCompanyName: '',
	category: '',
	otherTypeSpecification: '',
	building: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
	worksLocation: 'In Unit',
};

export const getDefaultRequestDetailsWorkPermit = () => {
	return objectify( defaultRequestDetailsWorkPermit );
};

export interface RequestDetailsLandlord extends RequestDetails {
	titleDeedDate: string,
	titleDeedValue: string,
	building: string,
}

const defaultRequestDetailsLandlord: RequestDetailsLandlord = {
	building: '',
	titleDeedDate: '',
	titleDeedValue: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsLandlord = () => {
	return objectify( defaultRequestDetailsLandlord );
};

export interface RequestDetailsTenant extends RequestDetails {
	tenancyDate: string,
	building: string,
}

const defaultRequestDetailsTenant: RequestDetailsTenant = {
	building: '',
	tenancyDate: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsTenant = () => {
	return objectify( defaultRequestDetailsTenant );
};

export type BarrierRemoteReuestType = 'initialRequest' | 'lost' | 'damaged';
export type ContactType = 'landlord' | 'tenant';
export enum BarrierRemoteReuestTypeStatusEnum {
	initialRequest = 'Initial Request',
	lost = 'Lost',
	damaged = 'Damaged',
}
export interface RequestDetailsRemoteBarrier extends RequestDetails {
	building: string;
	comments: string;
	type: BarrierRemoteReuestType;
	vehiclesNum: number;
	vehiclesDetails: VehicleDetails[];
	contactType?: ContactType;
}

export type VehicleDetails = { details: string, plateNumber: string };

const defaultRequestDetailsRemoteBarrier: RequestDetailsRemoteBarrier = {
	building: '',
	comments: '',
	type: 'initialRequest',
	contactType: 'landlord',
	vehiclesNum: 0,
	vehiclesDetails: [],
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsRemoteBarrier = () => {
	return objectify( defaultRequestDetailsRemoteBarrier );
};

export type VisitorDetail = { name: '', id: '' };
export interface RequestDetailsBooking extends RequestDetails {
	date: string;
	startHour: string;
	endHour: string;
	remarks: string;
	duration?: number;
	building: string;
	visitorsNum: number;
	visitorsDetails: VisitorDetail[];
}


export type AccessCard = { serialNo: string };
export interface RequestDetailsAccessCard extends RequestDetails {
	building: string,
	comments: string,
	type: BarrierRemoteReuestType,
	cards: AccessCard[],
	contactType?: ContactType,
	issuedCardNo: string,
	masterCard: boolean,
}
const defaultRequestDetailsAccessCard: RequestDetailsAccessCard = {
	building: '',
	comments: '',
	type: 'initialRequest',
	cards: [],
	contactType: 'landlord',
	issuedCardNo: '',
	masterCard: false,
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsAccessCard = () => {
	return objectify( defaultRequestDetailsAccessCard );
};



const defaultRequestDetailsBooking: RequestDetailsBooking = {
	date: '',
	startHour: '',
	endHour: '',
	remarks: '',
	duration: 60,
	building: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
	visitorsNum: 0,
	visitorsDetails: [],
};

export const getDefaultRequestDetailsBooking = () => {
		return objectify( defaultRequestDetailsBooking );
	};

export interface RequestDetailsViewing extends RequestDetails {
	building: string,
	comments: string,
	viewingDateTime: string,
	brokerName: string,
	brokerCompany: string,
	brokerEmail: string,
}
const defaultRequestDetailsViewing: RequestDetailsViewing = {
	building: '',
	comments: '',
	viewingDateTime: '',
	brokerName: '',
	brokerCompany: '',
	brokerEmail: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	number: '',
};

export const getDefaultRequestDetailsViewing = () => {
	return objectify( defaultRequestDetailsViewing );
};

export const getRequestDetailsByType = ( type: RequestTypeEnumKey ) => {
	switch ( type ) {
		case 'moveIn':
			return getDefaultRequestDetailsMoveRequest();

		case 'moveOut':
			return getDefaultRequestDetailsMoveRequest();

		case 'landlord':
			return getDefaultRequestDetailsLandlord();

		case 'tenant':
			return getDefaultRequestDetailsTenant();

		case 'barrierRemote':
			return getDefaultRequestDetailsRemoteBarrier();

		case 'accessCard':
			return getDefaultRequestDetailsAccessCard();

		case 'booking':
			return getDefaultRequestDetailsBooking();

		case 'maintenance':
			return getDefaultRequestDetailsMaintenance();

		case 'workPermit':
			return getDefaultRequestDetailsWorkPermit();

		default:
			return getDefaultRequestDetails();
	}
};

export enum WorkPermitCategoriesEnum {
	'Major' = 'Major Works',
	'Minor' = 'Minor Works',
	'Pre-Approval' = 'Work Permit Pre-Approval',
}


export interface BuyerInfo {
	name: string;
	email: string;
	phone: string;
	passportNO: string,
	eidNO: string,
	alternateBuyer: string,
}

export const getDefaultBuyerInfo = () => {
	return objectify( defaultBuyerInfo );
};

const defaultBuyerInfo: BuyerInfo = {
	name: '',
	email: '',
	phone: '',
	passportNO: '',
	eidNO: '',
	alternateBuyer: '',
};

export type PaymentStatus = 'notReceived' | 'received';
export type SCStatus = 'notReceived' | 'received';

export interface RequestDetailsSCCL extends RequestDetails {
	building: string,
	buyerInfo: BuyerInfo,
	nationality: string,
	passportNO: string,
	dueDate: string,
	paymentStatus: PaymentStatus,
	signatory: string,
}
const defaultRequestDetailsSCCL: RequestDetailsSCCL = {
	migratedDetails: getDefaultMigratedDetails(),
	building: '',
	buyerInfo: getDefaultBuyerInfo(),
	nationality: '',
	passportNO: '',
	dueDate: '',
	paymentStatus: 'notReceived',
	signatory: '',
	rating: 0,
	notes: '',
	cm: '',
	number: '',
};

export const getDefaultRequestDetailsSCCL = () => {
	return objectify( defaultRequestDetailsSCCL );
};

export const maintenanceCategories = [ '', 'Civil/Carpentry', 'Firefighting', 'Electrical Works', 'Plumbing', 'Cleaning/Painting', 'Mechanical', 'Health and Safety',
	'Landscaping', 'Signage', 'Critical HSE', 'Memorable Experience', 'Community Violation', 'Gymnasium', 'Swimming Pool', 'Irrigation' ];

export interface RequestDetailsVisitor extends RequestDetails {
	purpose?: string,
	name?: string,
	idNumber?: string,
	nationality?: string,
	idIssueDate?: string,
	idExpiryDate?: string,
	phone?: string,
	plateNumber?: string,
	visitorCode?: string,
	checkInDate?: string,
	checkOutDate: string,
	autoCheckout?: boolean,
	checkoutHours?: number,
	expectedCheckout?: string,
	autoCheckIn?: boolean

}

export interface RequestDetailsVisitorVM extends RequestDetailsVisitor {
	formattedCheckIn?: string,
	formattedCheckOut?: string,
	formattedExpectedCheckOut?: string,
	formattedCheckInTime?: string,
	formattedCheckOutTime?: string,
	formattedExpectedCheckOutTime?: string,
	checkOutWay?: string,
	editInfo?: boolean,
}

const defaultRequestDetailsVisitor: RequestDetailsVisitor = {
	purpose: '',
	name: '',
	phone: '',
	idNumber: '',
	nationality: '',
	idIssueDate: '',
	idExpiryDate: '',
	plateNumber: '',
	visitorCode: '',
	checkInDate: '',
	checkOutDate: '',
	autoCheckout: false,
	checkoutHours: 24,
	number: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	expectedCheckout: '',
	autoCheckIn: false,

};

export const getDefaultRequestDetailsVisitor = () => {
	return objectify( defaultRequestDetailsVisitor );
};

export interface RequestDetailsProofOfPayment extends RequestDetails {
	amount: number,
	bankReference: string,
	unitDetails: string,
	receiptCreated: boolean,
	receiptDate: string,
}

const defaultRequestDetailsProofOfPayment: RequestDetailsProofOfPayment = {
	number: '',
	rating: 0,
	notes: '',
	cm: '',
	migratedDetails: getDefaultMigratedDetails(),
	amount: 0,
	bankReference: '',
	unitDetails: '',
	receiptDate: '',
	receiptCreated: false,
};

export const getDefaultRequestDetailsProofOfPayment = () => {
	return objectify( defaultRequestDetailsProofOfPayment );
};

export enum FamilyRelationEnum {
	parent = 'Parent',
	sibling = 'Sibling',
	child = 'Child',
	uncle = 'Uncle',
	aunt = 'Aunt',
	grandparent = 'Grandparent',
	cousin = 'Cousin',
	spouse = 'Spouse',
	grandchild = 'Grandchild',
	ancestor = 'Ancestor',
	friend = 'Friend',
}
