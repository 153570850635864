
import { Injectable } from '@angular/core';
import { ClientVM } from '../../../models/client.model';
import { DocumentControlSettings } from '../../../models/documentControlSettings.models';
import { Observable } from 'rxjs';
import { ContactReferenceService } from 'src/app/contact-reference.service';
import { ClientService } from './client.service';
import { ContactsService } from './contacts.service';
import { UserService } from './user.service';

@Injectable( {
	providedIn: 'root'
} )
export class DocsWhitelistService {
	private client: ClientVM;
	public whitelist: string[];

	constructor (
		private userService: UserService,
		private contactsService: ContactsService,
		private clientService: ClientService,
		private contactRefService: ContactReferenceService,
	) {

	}

	getWhitelistByUser () {
		if ( !this.whitelist )
			this.userService.getUser()
				.then( user => {
					if ( user?.client ) {
						this.clientService.getClientById( user.client )
							.then( client => this.client = client )
							.then( () => {
								this.getWhitelist().then( list => this.whitelist = list )
							} )
					}
				} );
	}

	async getWhitelist (): Promise<string[]> {
		this.whitelist = [];
		if ( this.client?.id )
			this.contactRefService.getContactReferenceByReference( 'client', this.client?.id )
			.then( list => {
				list.forEach( li => {
					if ( li.function != 'cdc' ) return;
					this.whitelist.push( li.contact );
				} )
			} );
		return this.whitelist;
	}

	getWhitelistObservable (): Observable<DocumentControlSettings> {
		// TODO: refactor to get data from PG
		return null;
	}

}
