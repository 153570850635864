
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { User } from '../../../models/users.models';
import { SystemService } from 'src/app/system.service';
import { ContactReferenceService } from 'src/app/contact-reference.service';
import { AuthService } from './auth.service';
import { DocsWhitelistService } from './docs-whiltelist.service';
import { UserService } from './user.service';
import { LeasePGService } from './lease-pg.service';

@Injectable( {
	providedIn: 'root'
} )
export class RouteGuardService  {
	public currentUser: User;
	public notAllowedOnDocuments = false;
	public epcexternalPartyContact = false;
	public lawyerUser = false;

	constructor (
		public ss: SystemService,
		private userService: UserService,
		private router: Router,
		private wl: DocsWhitelistService,
		private authService: AuthService,
		private contactReferenceService: ContactReferenceService,
		private leaseService: LeasePGService,
	) { }

	async canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean | UrlTree> {
		const url: string = this.authService.redirectUrl || state.url;
		if ( !this.authService.redirectUrl ) {
			this.authService.redirectUrl = url;
		}
		let isActivate: any = true;
		const contactUrlSegments = [ 'welcome-customer', 'my-units', 'my-requests', 'oc-tenders',
			'community-wall', 'helpline-faq', 'event-calendar', 'community-documents', 'profile', 'contact-us' ];
		const vendorUrlSegments = [ 'vendor-home', 'vendor-register', 'profile' ];
		const externalURLs = [ 'visitors', 'dashboard-bi' ]
		const tenantSegments = [ 'my-units', 'offer-to-lease' ]

		if ( this.ss.currentUser === undefined && !this.userService.user.getValue() ) {
			try {
				if ( route.url[ 0 ].path === 'forgot-password' || route.url[ 0 ].path === 'reset-password' ) {
					return isActivate = true;
				}
				this.currentUser = await this.userService.currentUser();
			} catch {
				this.router.navigate( [ '/signin' ] );
				return isActivate = false;
			}
		}
		let checkURL = contactUrlSegments.find( u => u === route.url[ 0 ].path );
		const actorType = this.authService?.user?.actorType;
		let isDraft = false;
		if ( actorType === 'contact' ) {
			const refs = await this.contactReferenceService.getContactReferenceByContact( this.authService?.user?.contact );
			isDraft = (await this.leaseService.isDraftLease(this.authService?.user?.contact)).isDraft;
			// for (const ref of refs) {
			// 	if(ref.function === 'tenant'){
			// 		if(isDraft === false) break;
			// 	}
			// }
			if ( ( actorType && !checkURL ) || ( !actorType && checkURL ) ) {
				if ( checkURL === 'profile' ) return isActivate = true;
				checkURL = '';
				this.router.navigate( [ '/signin' ] );
				return isActivate = false;
			}
				else {
				const isTenantUser = this.ss.currentUser?.id?.includes( 'contact-' )
					? !!refs?.find( ref => ref.function === 'tenant') : false;
				if ((route.url[ 0 ].path !== 'my-units' && isTenantUser && isDraft) ||
					(route.url[ 0 ].path === 'my-units' && isTenantUser && isDraft && !route?.children[0].routeConfig.path.includes('offer-to-lease'))
					){
					this.router.navigate( [ '/signin' ] );
					return isActivate = false;
				}
			}
		} else if ( actorType === 'vendor' ) {
			let checkVendorURL = vendorUrlSegments.find( u => u === route.url[ 0 ].path );
			const vendorCheck = this.authService?.user?.actorType === 'vendor';
			if ( ( vendorCheck && !checkVendorURL ) || ( !vendorCheck && checkVendorURL ) ) {
				if ( checkVendorURL === 'profile' ) return isActivate = true;
				checkVendorURL = '';
				this.router.navigate( [ '/signin' ] );
				return isActivate = false;
			}
		}


		const checkAdmin = route.data.checkAdmin ? true : false;
		const checkClientAdmin = route.data.checkClientAdmin ? true : false;
		const checkProfileComplete = route.data.checkProfileComplete ? true : false;
		const checkApproved = route.data.checkApproved ? true : false;
		const checkKaizenEmployee = route.data.checkApproved ? true : false;

		const contact = this.ss.currentUser?.contact;
		try {
			this.wl.getWhitelistByUser();
			const whitelist = this.wl.whitelist;
			this.notAllowedOnDocuments = ( whitelist === undefined || whitelist.indexOf( contact ) === -1 );
		} catch ( error ) {
			console.error( error );
		}
		if ( state.url.indexOf( 'docs-admin' ) !== -1 && this.ss.currentUser && this.notAllowedOnDocuments ) {
			console.log( 'Denied by email' );
			isActivate = false;
		}
		if ( this.ss.currentUser && this.ss.currentUser?.isAdmin !== true && this.ss.currentUser?.isClientAdmin !== true && checkAdmin ) {
			isActivate = false;
		}
		if ( this.ss.currentUser && this.ss.currentUser?.isClientAdmin !== true && checkClientAdmin ) {
			isActivate = false;
		}
		if ( this.ss.currentUser && this.ss.currentUser?.isApproved !== true && checkProfileComplete ) {
			isActivate = false;
		}
		if ( this.ss.currentUser && this.ss.currentUser?.isApproved !== true && checkApproved ) {
			isActivate = false;
		}
		if ( this.ss.currentUser && this.ss.currentUser?.profileType && checkKaizenEmployee ) {
			if ( this.ss.currentUser?.profileType !== 'Employee' ) isActivate = false;
		}
		if ( actorType !== 'contact' && actorType !== 'vendor' ) {
			const refs = await this.contactReferenceService.getContactReferenceByContact( this.ss.currentUser?.contact );
			this.epcexternalPartyContact = !!refs?.find( ref => ref.function === 'epc' ) || !!refs?.find( ref => ref.function === 'svcr' );
			this.lawyerUser = !!refs?.find( ref => ref.function === 'law' );
			const isRequestReadOnly = !this.ss.currentUser?.id?.includes( 'contact-' )
				? !!refs?.find( ref => ref.function === 'requestro' ) : false;

			if ( route.url[ 0 ].path !== 'request-admin' && isRequestReadOnly ) {
				isActivate = this.router.createUrlTree( [ 'request-admin' ] );
			}
			const checkExternalURL = externalURLs.find( u => u === route.url[ 0 ].path );
			if ( ( route.url[ 0 ].path !== 'global-view' && this.epcexternalPartyContact && !checkExternalURL ) ) {
				isActivate = this.router.createUrlTree( [ '/global-view/' ] );
			}
			let contactId;
			if ( route.url[ 0 ].path === 'collections' ) {
				contactId = route?.children[ 0 ]?.firstChild?.params[ 'contactid' ];
			}
			if ( route.url[ 0 ].path !== 'collections' && this.lawyerUser ) {
				isActivate = false;
			}
			if ( route.url[ 0 ].path === 'collections' && this.lawyerUser &&
				contactId === 'portfolioDashboard' && contactId === 'dashboard' &&
				contactId === 'priority-list' && contactId === 'monthlyCollection' &&
				contactId === 'totalCollection' && contactId === 'collectionManagement'
			) {
				isActivate = false;
			}
		}
		return isActivate;
	}
}
