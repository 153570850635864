import { objectify } from '../utils/objectify';

export enum NotificationTitle {
	'comment' = 'New Comment',
	'new_request' = 'Request Submitted',
	'request_approved' = 'Request Approved',
	'request_declined' = 'Request Declined',
	'registration_request_approved' = 'Registration Request Approved',
	'registration_request_declined' = 'Registration Request Declined',
	'change' = 'Request Type Changed',
	'document_approved' = 'Document Approved',
	'document_rejected' = 'Document Rejected',
	'tender_awarded' = 'Tender Awarded',
	'purchase_approved' = 'Order Approved',
	'payment_added' = 'Payment Added',
	'penalty_added' = 'Penalty Added',
	'tender_finalized' = 'Tender Finalized',
	'tender_lost' = 'Tender Lost',
	'message' = 'New Message',
	'vendor_payment_expire' = 'Payment Required',
	'admin-close-lost-found-request' = 'Admin Close Lost/Found Request',
}
export interface Notification {
	id?: string,
	refType: string,
	refId: string,
	requestType?: string,
	projectId?: string,
	postedBy: string,
	postedOn: string,
	notifyTime: string,
	title: string,
	body: string,
	url: string,
	status: string,
	contact: string,
	icon: string,
}

const defaultRequestNotification: Notification = {
	refType: '',
	refId: '',
	projectId: '',
	requestType: '',
	postedBy: '',
	postedOn: '',
	notifyTime: '',
	title: '',
	body: '',
	url: '',
	status: 'new',
	contact: '',
	icon: '',
};

export const getDefaultRequestNotification = () => {
	return objectify( defaultRequestNotification );
};

const defaultNotification: Notification = {
	refType: '',
	refId: '',
	postedBy: '',
	postedOn: '',
	notifyTime: '',
	title: '',
	body: '',
	url: '',
	status: 'new',
	contact: '',
	icon: '',
};

export const getDefaultNotification = () => {
	return objectify( defaultNotification );
};

export interface FBNotification {
	registration_ids: string[],
	notification: {
		type: string,
		body: string,
		title: string,
		request_id?: string,
		requestType?: string,
		project_id?: string,
		document_id?: string,
		purchase_id?: string,
		lease_id?: string,
		unit_id?: string,
		vendor_client_id?: string,
		url: string,
		icon: string,
		click_action: string,
	},
	data: {
		type: string,
		body: string,
		title: string,
		request_id?: string,
		requestType?: string,
		project_id?: string;
		document_id?: string,
		purchase_id?: string,
		lease_id?: string,
		unit_id?: string,
		vendor_client_id?: string,
		url: string,
		icon: string,
	}
}
