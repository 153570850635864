export const dateFormatYYYYMMDD = (params: string) => {
	if (params === null || params === undefined || params.length !== 8 || isNaN(Number(params))) {
		return params;
		}
		
	try {
		const toRet: string = params;
		const y = toRet.substring(0, 4);
		const m = toRet.substring(4, 6);
		const d = toRet.substring(6, 8);
		return y + '-' + m + '-' + d;
	} catch (error) {
		return params;
	}
 };
 