import { HttpErrorResponse } from "@angular/common/http";
import { fallbackErrorMessage, forbiddenErrorMessage } from "./constants";
import { PrimeIcons } from "primeng/api";
import { CustomMessageService } from "./services/customPriemngMessage.service";

function getHttpResponseError(error: unknown, extraMessage?: string): { message: string, title?: string, autoClose: boolean, data?:string[] } {
  if (!(error instanceof HttpErrorResponse)) {
    return {
      autoClose: true,
      message: fallbackErrorMessage
    };
  }

  switch (error.status) {
    case 403:
      return {
        autoClose: false,
        message: forbiddenErrorMessage.concat(extraMessage),
        title: "Access Denied!"
      };
    case 400: {
      let message: string;
      if (error.error.data?.name === 'ZodError' ) {
        message = `Failed to issue request. Please contact support for more information. To speed up process please share error details below: \n\n${JSON.stringify(error.error.data.issues)}`;
      } else {
        message = error.error.message;
      }
      return {
          autoClose: false,
          message,
          title: "Validation error",
          data: error.error.data
      };
     }
     case 422:
        return {
          autoClose: false,
          message: error.error.message,
          title: "Action failed"
        };  
    default:
      return {
        autoClose: true,
        message: fallbackErrorMessage
      };
  }
}

export function apiErrorHandler(messageService: CustomMessageService, options:{accessDeniedExtraMessage?: string}) {
  return (err: unknown): never => {
    const parsedError = getHttpResponseError(err,options.accessDeniedExtraMessage); 
		messageService.error( { 
      title: parsedError.title,
      message: parsedError.message,
      closable: !parsedError.autoClose,
      sticky: !parsedError.autoClose,
      icon: PrimeIcons.TIMES
    } );
    if(parsedError.data.length > 0){
      parsedError.data.forEach(message =>{
        messageService.error({
          title: parsedError.title,
          message,
          closable: !parsedError.autoClose,
          sticky: !parsedError.autoClose,
          icon: PrimeIcons.TIMES
        })
      })
    }
    throw err;
  };
}
