import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppAdditionalComponent } from "./app-additional.component";
import { ChipModule } from "primeng/chip";

@NgModule({
	declarations: [AppAdditionalComponent],
	imports: [
		CommonModule,
		ChipModule
	],
	exports: [AppAdditionalComponent]
})
export class AppAdditionalModule {}
