import { Injectable } from "@angular/core";
import { IndividualConfig, ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
  })
  export class CustomToastrService {
    
    constructor(private toastr: ToastrService){}

    success(message?: string, title ='Success!', override?: Partial<IndividualConfig>) {
      return this.toastr.success(message, title);
    }
    error(message?: string, title = 'Error!', override?: Partial<IndividualConfig>){
      return this.toastr.error(message, title);
    }
    warning(message?: string, title = 'Warning!', override?: Partial<IndividualConfig>){
      return this.toastr.warning(message, title);
    }
    show(message?: string, title = 'Show!', override?: Partial<IndividualConfig>){
      return this.toastr.show(message, title);
    }
    info(message?: string, title = 'Info!', override?: Partial<IndividualConfig>){
      return this.toastr.info(message, title);
    }

  }