
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentListerComponent } from './document-lister.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DocumentUploaderModule } from '../document-uploader/document-uploader.module';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule( {
	declarations: [ DocumentListerComponent ],
	imports: [
		CommonModule,
		NgxMaskModule.forChild(),
		FormsModule,
		BsDatepickerModule,
		TypeaheadModule,
		DocumentUploaderModule,
		ButtonModule,
		DialogModule,
		ProgressSpinnerModule,
		DropdownModule,
		TableModule,
		MultiSelectModule,
		TooltipModule,
		CalendarModule,
		ClipboardModule,
		BadgeModule,
		ConfirmDialogModule
	],
	exports: [ DocumentListerComponent ]
} )
export class DocumentListerModule { }
