import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AppTracingService } from "../app-tracing.service";
import { uuidv4 } from "../../../../utils/uuid";

const BASE_API_URL = environment.apiURL;

@Injectable()
export class TracingInterceptor implements HttpInterceptor {
  constructor(private appTracingService: AppTracingService) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(BASE_API_URL)) {
      return next.handle(request);
    }

    const requestTraceId = uuidv4();
    const trackingInfo = this.appTracingService.getTrackingInfo();
    const sessionTraceId = trackingInfo.sessionId;
    const clientVersion = trackingInfo.clientVersion;
    const clientPlatform = trackingInfo.clientPlatform;

    // Clone the request and add the session trace ID to the headers
    const requestWithSessionTrace = request.clone({
      headers: request.headers
        .set("x-client-trace-request-id", requestTraceId)
        .set("x-client-trace-session-id", sessionTraceId)
        .set("x-client-version", clientVersion)
        .set("x-client", clientPlatform)
    });

    return next.handle(requestWithSessionTrace);
  }
}
