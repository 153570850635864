import { objectify } from '../utils/objectify';
import { ContactType } from './requestDetails.models';

export interface DocumentFireBase {
	content_type: string,
	created_at: string,
	dob: string,
	due_date: string,
	end_date: string,
	expiry_date: string,
	full_path: string,
	id: string,
	issue_date: string,
	name: string,
	notification_range: number,
	position: number,
	reference: string,
	renewal_date: string,
	service: string,
	service_frequency: string,
	start_date: string,
	type:  string | DocumentTypeValues,
	upload_date: string,
	uploaded_by: string,
	url: string,
}

// add the rest of refType for document table 
export enum RefTypeEnum {
	Leasing = 'leasing'
}

// add the rest of content type for document table 
export enum contentTypeEnum {
	'application/pdf' = 'application/pdf'
}

export interface DocumentPG {
	contentType?: string,
	createdAt: string,
	dob?: string,
	dueDate?: string,
	endDate?: string,
	expiryDate?: string,
	fullPath?: string,
	id: string,
	issueDate?: string,
	name: string,
	notificationRange?: number,
	position: number,
	refType: string,
	refId: string,
	renewalDate?: string,
	service?: string,
	serviceFrequency?: string,
	startDate?: string,
	type:  string | DocumentTypeValues,
	uploadDate: string,
	uploadedBy: string | null,
	url: string,
	client?: string,
	approvalStatus?: string,
	rejectReason?: string,
	isCurrent?: boolean,
	isRequired?: boolean,
	approved?: boolean,
	approvalHistory?: DocumentApprovalHistory[] | string | Object;
	sendHistory?:DocumentSendHistory[] | string;
	tenantApprovalDate?:string,
	tenantApprovalStatus?:'pending'|'approved'|'rejected',
	landlordApprovalDate?:string,
	landlordApprovalStatus?:'pending'|'approved'|'rejected',
	
}

const defaultDocumentPG: Required<DocumentPG> = {
	contentType: '',
	createdAt: '',
	dob: '',
	dueDate: '',
	endDate: '',
	expiryDate: '',
	fullPath: '',
	id: '',
	issueDate: '',
	name: '',
	notificationRange: 0,
	position: 0,
	refType: '',
	refId: '',
	renewalDate: '',
	service: '',
	serviceFrequency: '',
	startDate: '',
	type: '',
	uploadDate: '',
	uploadedBy: null,
	url: '',
	client: '',
	approvalStatus: '',
	rejectReason: '',
	isCurrent: true,
	isRequired: false,
	approved: false,
	approvalHistory: [],
	sendHistory: [],
	tenantApprovalStatus:'pending',
	landlordApprovalStatus:'pending',
	tenantApprovalDate:'',
	landlordApprovalDate:'',
}

export const getDefaultDocument = () => {
	return objectify( defaultDocumentPG );
};

export const transformDocument = ( fromEntity: DocumentFireBase ) => {
	const [ referenceType, referenceId ] = fromEntity.reference?.split( '/' ).slice( -2 );
	return {
		contentType: fromEntity.content_type,
		createdAt: fromEntity.created_at,
		dob: fromEntity.dob,
		dueDate: fromEntity.due_date,
		endDate: fromEntity.end_date,
		expiryDate: fromEntity.expiry_date,
		fullPath: fromEntity.full_path,
		id: fromEntity.id,
		issueDate: fromEntity.issue_date,
		name: fromEntity.name,
		notificationRange: fromEntity.notification_range,
		position: fromEntity.position,
		refType: referenceType,
		refId: referenceId,
		renewalDate: fromEntity.renewal_date,
		service: fromEntity.service,
		serviceFrequency: fromEntity.service_frequency,
		startDate: fromEntity.start_date,
		type: fromEntity.type,
		uploadDate: fromEntity.upload_date,
		uploadedBy: fromEntity.uploaded_by,
		url: fromEntity.url,
	} as DocumentPG;
};

export const reverseTransformDocument = ( fromEntity: DocumentPG ) => {
	return {
		content_type: fromEntity.contentType,
		created_at: fromEntity.createdAt,
		dob: fromEntity.dob,
		due_date: fromEntity.dueDate,
		end_date: fromEntity.endDate,
		expiry_date: fromEntity.expiryDate,
		full_path: fromEntity.fullPath,
		id: fromEntity.id,
		issue_date: fromEntity.issueDate,
		name: fromEntity.name,
		notification_range: fromEntity.notificationRange,
		position: fromEntity.position,
		reference: fromEntity.refType + '/' + fromEntity.refId, // this is wrong but going on anyway!!
		renewal_date: fromEntity.renewalDate,
		service: fromEntity.service,
		service_frequency: fromEntity.serviceFrequency,
		start_date: fromEntity.startDate,
		type: fromEntity.type,
		upload_date: fromEntity.uploadDate,
		uploaded_by: fromEntity.uploadedBy,
		url: fromEntity.url,
	} as DocumentFireBase;
};

export interface DocumentType {
	id: string;
	description?: string;
	hasStartDate: boolean;
	hasEndDate: boolean;
	hasIssueDate: boolean;
	hasExpiryDate: boolean;
	hasRenewalDate: boolean;
	hasDueDate: boolean;
	isContactDocument:boolean;
};
export const getDefaultDocumentType = () => {
	return objectify( defaultDocumentType );
};
const defaultDocumentType: Required<DocumentType> = {
	id: '',
	description: '',
	hasStartDate: false,
	hasEndDate: false,
	hasIssueDate: false,
	hasExpiryDate: false,
	hasRenewalDate: false,
	hasDueDate: false,
	isContactDocument: false,
};

export interface FileMetaData {
	name: string,
	type: string,
	startDate?: string,
	endDate?: string,
	renewalDate?: string,
	issueDate?: string,
	expiryDate?: string,
	dueDate?: string,
	dob?: string
	docType?: DocumentType
}
export interface BaseDocFileData extends FileMetaData {
	file: Blob,
	apiTempPath?: string
}
export interface DocFile extends BaseDocFileData {
	showIssueExpiryDate?: boolean;
	showIssueDate?: boolean;
	invalidIssueDate?: string;
	invalidExpiryDate?: string;

	showStartEndtDate?: boolean;
	showRenewalDate?: boolean;
	invalidStartDate?: string;
	invalidEndDate?: string;
	invalidRenewalDate?: string;

	invalidDueDate?: any;
	showDueDate?: boolean;
	status?: string;
	document?: DocumentPG;
	notification_range?: number;
	dob?: string;
}

export type DocumentListerInputs = { refId: any, refType: any, exclude?: string[], limitTo?: string[], isUnique?: boolean, community?: string, type?: any };

export interface ContactDocument extends DocumentPG {
	community: string,
	communityName: string
}

export interface DocumentVM extends DocumentPG {
	status: string,
	uploadedByName?: string,
}

export interface DocumentApprovalHistory {
	status: ApprovalStatus,
	user: string,
	date: string,
	remark?: string,
	finalApproval: ApprovalStatus,
	contactType?: string,
	visibleName?: string,
}

export interface DocumentSendHistory{
	contact:string[],
	date:string,
	contactType: ContactType
}


export type ApprovalStatus= 'approved'|'rejected' | 'pending' | 'Pending' |'Accountant Approved' | 'Accountant Rejected' | '';
export enum ApprovalStatusEnum{
	Approved = 'approved',
	Rejected = 'rejected' ,
	pending =  'pending' ,
	Pending =  'Pending' , /* this is kept duplicated because I suspect these flags have different meaning in db */
	AccountantApproved= 'Accountant Approved' ,
	AccountantRejected= 'Accountant Rejected' ,
	Empty =  ''
} ;

export enum DocumentTypeEnum {
	BrokerLicenseCopy= 'broker-license',
	EmiratesID= 'eid',
	BrokerEmiratesID= 'broker-id',
	ConfirmationOnTheApprovalOfViewingTheUnitFromTheOwnersRegisteredEmailAddress= 'viewing-approval-confirmation',
	NOCOfReleasingKeys= 'releasing-keys-noc',
	NOCFromDeveloper= 'developer-noc',
	NOCFromISTAChilledWaterChargesClearanceLetter= 'ista-noc',
	NOCMoveOutFromOwner= 'owner-noc',
	AttachmentAfterMaintenanceIsDone= 'after-fixed',
	SouthEnergyReceipt= 'south-energy',
	CopyOfBCC= 'bcc',
	CopyOfFinalGasPaymentReceipt= 'final-payment-gas',
	CopyOfFinalChillerPaymentReceipt= 'final-payment-chiller',
	CopyOfFinalDewaPaymentReceipt= 'final-payment-dewa',
	CopyOfGasRegistration= 'registration-gas',
	CopyOfChilledWaterConnection= 'chilled-water-connection',
	CopyOfChillerRegistration= 'registration-chiller',
	CopyOfDewaRegistration= 'registration-dewa',
	CopyOfPaymentClearanceCertificateFromUnitOwner= 'payment-clearance',
	PaidServiceCharge= 'paid-sc',
	ChillerNOC= 'chiller-noc',
	OwnerPassportCopy= 'owner-pp',
	OwnerTitleDeedCopy= 'owner-td',
	Contract= 'co',
	AuthorizedSignatory= 'as',
	Attachment= 'at',
	CampaignImage= 'ci',
	CollectionLegal= 'collection-legal',
	PropertyPortalContract= 'corporate-document-con1',
	FacilityManagement= 'corporate-document-con2',
	PropetyHandover= 'corporate-document-con3',
	OwnersAssociationManagementServices= 'corporate-document-con4',
	PropertyManagement= 'corporate-document-con5',
	OtherContracts= 'corporate-document-con6',
	SoftwareContracts= 'corporate-document-con7',
	VATRegistrationCertificate= 'corporate-document-fin1',
	LetterOfEngagement= 'corporate-document-fin2',
	ISODocuments= 'corporate-document-iso1',
	QualityPolicy= 'corporate-document-iso2',
	QualityManual= 'corporate-document-iso3',
	AffectionPlan= 'corporate-document-off1',
	EjariContractWithDateNotification= 'corporate-document-off2',
	EmiratesIDAuthorizedSignatory= 'corporate-document-off3',
	PassportAuthorizedSignatory= 'corporate-document-off4',
	VisaAuthorizedSignatory= 'corporate-document-off5',
	RERACard= 'corporate-document-off6',
	CorporateDocument= 'corporate-document-off7',
	TitleDeed= 'corporate-document-off8',
	ArticleOfIncorporation= 'corporate-document-rec1',
	CompanyCertificates= 'corporate-document-rec2',
	MemorandumOfAssociation= 'corporate-document-rec3',
	PowerOfAttorney= 'corporate-document-rec4',
	TradeLicense= 'corporate-document-rec5',
	EstablishmentCard= 'corporate-document-rec6',
	RERACertificate= 'corporate-document-rec7',
	DrawingPlans= 'dnp',
	DubaiTourismLicense= 'dtl',
	EHSCertificate= 'ec',
	EjariRegistration= 'er',
	FloorPlan= 'fp',
	Invoice= 'in',
	PurchaseInvoice='puIn',
	Insurance= 'ins',
	LetterHead= 'lh',
	MarketingImage= 'mi',
	MethodStatement= 'ms',
	NationalID= 'ni',
	NOC= 'no',
	OperatorsCertificate= 'oc',
	OtherProjectDocuments= 'opd',
	PowerOfAttourney= 'pa',
	Passport= 'pp',
	AnnualMaintenaceContract= 'project-documents-section-a01',
	ServiceProviderContractControlListWhichIsUsedDuringMollak= 'project-documents-section-a02',
	ManpowerList= 'project-documents-section-a03',
	ComprehensiveAssetRegister= 'project-documents-section-a04',
	PPMSchedule= 'project-documents-section-a05',
	CompleteDocumentationOfServiceRoomsKeyHandover= 'project-documents-section-a06',
	HealthAndSafetyPlanCrisesManagementPlan= 'project-documents-section-a07',
	MeterReadingLogSheetsOfElectricityChilledWaterGasAndWaterMetersEtc= 'project-documents-section-a08',
	OMManualsOfAllTheBuildingAssetsShouldBeSavedWithAssetName= 'project-documents-section-a09',
	IncidentReportsShouldBeSavedWithIncidentName= 'project-documents-section-a10',
	PPMServiceReport= 'project-documents-section-a11',
	PPMAttachment= 'project-documents-section-a12',
	WorkCompletionReport= 'project-documents-section-a13',
	PPMWeeklyChecklist= 'project-documents-section-a14',
	PPMMonthlyChecklist= 'project-documents-section-a15',
	RFSCompletionServiceReport= 'project-documents-section-a16',
	CompleteElectricalDrawingsSchedulesWithDEWAApprovals= 'project-documents-section-b02',
	LowVoltageStructuralCablingApprovedLayoutsAndSchedules= 'project-documents-section-b03',
	EarthLightingProtectionDetailsDrawings= 'project-documents-section-b04',
	GasProvisionDrawings= 'project-documents-section-b05',
	ApprovedArchitecturalLayouts= 'project-documents-section-b06',
	ApprovedFireAlarmFirefightingEquipmentFireLiftsSoundersEmergencyLightingDrawings= 'project-documents-section-b07',
	ApprovedSprinklerBuildingSmokeClearingVentilationLayoutsDrawings= 'project-documents-section-b08',
	LiftsAndExternalAccessSystemPlansDrawings= 'project-documents-section-b09',
	ApprovedACLayoutSchedulesAndDrawings= 'project-documents-section-b10',
	BuildingManagementSystemBMSLayoutsAndDrawings= 'project-documents-section-b11',
	CCTVSecuritySystemStatutoryApprovedLayouts= 'project-documents-section-b12',
	TelecommunicationLayoutsAndEtisalatDuApplication= 'project-documents-section-b13',
	ApprovedUndergroundDrainageLayoutByStatutoryAuthority= 'project-documents-section-b14',
	StatutoryAuthorityApprovalForCommonAreasPlan= 'project-documents-section-b15',
	ApprovedCarParksAndStorageAllocationsDocumentation= 'project-documents-section-b16',
	WaterTankAndPumpRoomLayoutScheduleAndDrawings= 'project-documents-section-b17',
	StructuralLayoutScheduleAndDrawings= 'project-documents-section-b18',
	PlumbingLayoutScheduleAndDrawings= 'project-documents-section-b19',
	CivilLayoutScheduleAndDrawings= 'project-documents-section-b20',
	InteriorDesignLayoutScheduleAndDrawings= 'project-documents-section-b21',
	FirefightingSystemDCDApproval= 'project-documents-section-c01',
	FirefightingSystem3rdPartyInspectionAndTestingCertificates= 'project-documents-section-c02',
	WaterTanksCleaningCertificates= 'project-documents-section-c03',
	BuildingWaterQualityTestCertificates= 'project-documents-section-c04',
	BuilidngFlushingAndChlorination= 'project-documents-section-c05',
	SwimmingPoolChlorinationAndWaterQualityTestReport= 'project-documents-section-c06',
	AirPermeabilityTestResultsCertificates= 'project-documents-section-c07',
	BuildingMaterialTestResultsCertificates= 'project-documents-section-c08',
	LiftsAndInspectionAndTestingCertificates= 'project-documents-section-c09',
	ExternalAccessSystemAndAnchoragePointInspectionAndTestingCertificates= 'project-documents-section-c10',
	BuildingManagementSystemBMSCertificatesAndTrainingInformation= 'project-documents-section-c11',
	PoliceApprovalCertificateForCCTVSecuritySystem= 'project-documents-section-c12',
	MaintenanceOccupancyTrainingCertifications= 'project-documents-section-c13',
	WarranteesAndGuaranteesDocumentsShouldBeSavedWithAssetServiceName= 'project-documents-section-c14',
	CompletionCertificates= 'project-documents-section-c15',
	SustainabilityLEEDCertification= 'project-documents-section-c16',
	StatutorySignageCertificationOrApproval= 'project-documents-section-c17',
	BuildingInsurance= 'project-documents-section-c18',
	DCD247AnnualSubscription= 'project-documents-section-c19',
	RERAApprovedBudgetsShouldBeSavedYearWise= 'project-documents-section-e01',
	BudgetReviewFinalApprovedReportsByMollakAuditor= 'project-documents-section-e02',
	ApprovedAuditReportsShouldBeSavedYearWise= 'project-documents-section-e03',
	ReserveFundStudies= 'project-documents-section-f01',
	ReInstatementInsuranceValuation= 'project-documents-section-f02',
	EnergyEfficiencyStudy= 'project-documents-section-f03',
	ResourceCostAllocationStudy= 'project-documents-section-f04',
	BuildingTechnicalAudit= 'project-documents-section-f05',
	ProjectTitleDeed= 'project-documents-section-h01',
	ProjectCompletionDocumentCivilDefence= 'project-documents-section-h02',
	ProjectCompletionDocumentDMTarkheesDSO= 'project-documents-section-h03',
	DeveloperTradeLicence= 'project-documents-section-h04',
	AreaSheetsAsPerDeveloer= 'project-documents-section-h05',
	AreaSheetAsPerMollak= 'project-documents-section-h06',
	DeveloperPassportCopy= 'project-documents-section-h07',
	DeveloperEmiratesID= 'project-documents-section-h08',
	JOPD= 'project-documents-section-h09',
	BMS= 'project-documents-section-h10',
	CommunityRules= 'project-documents-section-h11',
	ManagementContract= 'project-documents-section-h20',
	AppointmentLetter= 'project-documents-section-h21',
	RiskAssessments= 'ra', StatutoryApprovals= 'sa',
	UndertakingLetters= 'ul',
	UnitPlan= 'up',
	VATCertificate= 'vc',
	VehiclePlateCardNumber= 'vhc',
	VisaCopy= 'vi',
	WorkmenCompensation= 'wc',
	ChequeAcknowledgement= 'cheque-ack',
	RequestForProposal= 'rfp',
	Proposal= 'pd',
	ChequeCopy= 'cheque-copy',
	LeaseAgreement= 'lease-agreement',
	ZennerRegisteration= 'zenner-reg',
	ZennerClearance= 'zenner-clearance',
	EmpowerRegisteration= 'empower-reg',
	EmpowerClearance= 'empower-clear',
	RoyalGasRegisteration= 'royal-gas-reg',
	RoyalGasClearance= 'royal-gas-clear',
	HappyWayRegisteration= 'happy-way-reg',
	HappyWayClearance= 'happy-way-clear',
	IstaRegisteration= 'ista-reg',
	IstaClearance= 'ista-clear',
	SouthEnergyRegisteration= 'south-energy-reg',
	SouthEnergyClearance= 'south-energy-clear',
	SignedPurchaseRequest= 'signed-purchase-request',
	PurchaseRequest= 'purchase-request',
	PowerOfAttorneyPOAAndMemorandumOfAssociationMOA= 'procurement-documents-a03',
	PassportCopyOfAuthorizedSignatoryAsPerPOAAndMOA= 'procurement-documents-a04',
	EHSCertificateOrPolicy= 'procurement-documents-a05',
	EmiratesCopyOfAuthorizedSignatoryAsPerPOAAndMOA= 'procurement-documents-a06',
	VisaCopyOfAuthorizedSignatoryAsPerPOAAndMOA= 'procurement-documents-a07',
	ElectronicCompanyCardSpecimenSignatureForMinistryOfLabor= 'procurement-documents-a08',
	CompanyProfile= 'procurement-documents-a09',
	PassportCopyOfOwnersAndPartnersAsPerTradeLicense= 'procurement-documents-a10',
	EmiratesCopyOfOwnersAndPartnersAsPerTradeLicense= 'procurement-documents-a11',
	VisaCopyOfOwnersAndPartnersAsPerTradeLicense= 'procurement-documents-a12',
	ThirdPartyLiabilityInsurance= 'procurement-documents-a13',
	WorkmenCompensationInsurance= 'procurement-documents-a14',
	SIRACertificate= 'procurement-documents-a15',
	ListOfClientsWithContactDetails= 'procurement-documents-a16',
	ProfessionalIndemnityInsurance= 'procurement-documents-a17',
	OtherDocuments= 'oth',
	ProofOfPayment= 'payProof',
	SewaElectricityWaterAndGas= 'sewa',
	AppliancesManual= 'project-documents-section-h12',
	AutomationManual= 'project-documents-section-h13',
	WarrantyCertificate= 'project-documents-section-h14',
	UrbanFarm= 'project-documents-section-h15',
	BioDomes= 'project-documents-section-h16',
	Initiates= 'project-documents-section-h17',
	RecommendedServicesProducts= 'project-documents-section-h18',
	MasterCommunityDeclaration= 'project-documents-section-h19',
	AttestedTenancyContract= 'atc',
	ProposedDrawing= 'proposedDrawing',
	SewaElectricalDrawing= 'sewaEd',
	SharjahMunicipalityApprovedDrawingAndApplication= 'smadaa',
	VehicleRegistrationCard= 'vrc',
	MovingCompanyTradeLicense= 'mctdl',
	SignedOfferTOLease= 'o2l',
	TenderTermsAndConditions= 'ttac',
	StatutoryAuthority='st',
	FactSheet='fact-sheet',
	Notices= 'note',
	TenancyContract='tc',
	Ejari='ejari',
	Other='other',
	Default='',
	titleDeedOrInitialContractOfSaleOrSPA='titleDeedOrInitialContractOfSaleOrSPA',
	chequeCopyOrThirdLiabilityInsurance='chequeCopyOrThirdLiabilityInsurance',
}

export type DocumentTypeValues = `${DocumentTypeEnum}`;
