
import { Component } from '@angular/core';

@Component({
  selector: 'app-route-loading-indicator',
  templateUrl: './route-loading-indicator.component.html',
  styleUrls: ['./route-loading-indicator.component.scss']
})
export class RouteLoadingIndicatorComponent {

  constructor() { }

  

}
