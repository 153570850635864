
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactFunction, ContactReference } from '../../../models/contact-reference.models';
import { Contact } from '../../../models/contacts.models';
import { IMap } from '../../../models/generic.models';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map, retry, tap } from 'rxjs/operators';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';
import { arraytomap, maptoarray } from '../../../utils/mapandarray';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;
const MAX_API_CALL_RETRIES = environment.maxAPICallRetries;

@Injectable( {
	providedIn: 'root'
} )
export class ContactReferenceService {
	public allContactReferences: BehaviorSubject<IMap<ContactReference>> = new BehaviorSubject( {} );
	public allContactReferenceArray: BehaviorSubject<ContactReference[]> = new BehaviorSubject( [] );
	private fetching = false;
	constructor (
		private http: HttpClient,
		private tt: CustomToastrService
	) {
		if ( window[ 'Cypress' ] ) {
			window[ 'ContactReferenceService' ] = this;
		}
	}

	public getSiteContacts = ( community: string ) => {
		const params = new HttpParams().set( 'community', community );
		const url = `${ BASE_API_URL }/contactReferences/siteContacts`
		return this.http.get<Contact[]>( url, { params } );
	}
	public getContactReferenceByReference = async ( refType: string, refId: string ) => {
		if ( !refType || !refId ) {
			throw new Error( 'Contact reference requested with no reference' );
		}

		const result = await firstValueFrom( this.http.get<ContactReference[]>( `${ BASE_API_URL }/contactReferences/${ refType }/${ refId }` ) );
		return result;
	}

	public getBy = async ( key: string, value: string, operator: 'equal' | 'include' | 'startsWith' | 'endsWith' = 'equal' ) => {
		if ( !key || !value ) throw new Error( 'invalid arguments' );
		key = key.trim();
		value = value.trim();
		switch ( operator ) {
			case 'equal':
				return ( await this.getAllContactReferenceArray() ).filter( ref => ref[ key ] === value );
			case 'include':
				return ( await this.getAllContactReferenceArray() ).filter( ref => ref[ key ]?.toString().includes( value ) );
			case 'startsWith':
				return ( await this.getAllContactReferenceArray() ).filter( ref => ref[ key ]?.toString().startsWith( value ) );
			case 'endsWith':
				return ( await this.getAllContactReferenceArray() ).filter( ref => ref[ key ]?.toString().endsWith( value ) );
			default:
				break;
		}

	}

	public getContactReferenceByFunction = async ( refFunction: ContactFunction ) => {
		if ( !refFunction ) {
			throw new Error( 'Contact reference requested with no function' );
		}
		try {
			const contactReferences = await firstValueFrom( this.http.get<ContactReference[]>( `${ BASE_API_URL }/contactReferences/function/${ refFunction }` ) )
			return contactReferences;
		} catch ( error ) {
			this.tt.error( 'Failed to get contact reference' );
			console.error( error );
			throw error;
		}
	}
	public getContactReferenceByContact = async ( contactId: string ) => {
		if ( !contactId ) {
			return;
		}
		try {
			const contactReferences = await firstValueFrom( this.http.get<ContactReference[]>( `${ BASE_API_URL }/contactReferences/contact/${ contactId }` ) );
			return contactReferences;
		} catch ( error ) {
			this.tt.error( 'Failed to get contact references' );
			console.error( error );
			throw error;
		}
	}
	public getAllContactReferences = async (): Promise<IMap<ContactReference>> => {
		if ( this.fetching ) return getNonNullNonEmptyValue( this.allContactReferences );
		this.fetching = true;
		try {
			return await this.fetchContactReferencesAndCache();
		} catch ( error ) {
			this.tt.error( 'Failed to fetch all units from the server' );
			console.error( error );
			throw error;
		}
	}
	public fetchContactReferencesAndCache = async (): Promise<IMap<ContactReference>> => {
		return await firstValueFrom( this.http.get<ContactReference[]>( `${ BASE_API_URL }/contactReferences` ).pipe( retry( MAX_API_CALL_RETRIES ),
			tap( res => ( this.allContactReferenceArray.next( res ) ) ), map( res => arraytomap( res ) ),
			tap( res => ( this.allContactReferences.next( res ) ) ) ) );
	}
	public getAllContactReferenceArray = async () => {
		await this.getAllContactReferences();
		return this.allContactReferenceArray.getValue();
	}
	private syncDeleteCache = ( id: string ) => {
		const contactRefs = this.allContactReferences.getValue();
		if ( contactRefs[ id ] ) {
			delete contactRefs[ id ];
			this.allContactReferences.next( contactRefs );
			this.allContactReferenceArray.next( maptoarray( contactRefs ) );

		}
	}
	public contactReferenceDelete = async ( id: string ) => {
		if ( !id ) {
			this.tt.warning( 'Contact reference delete with no id' );
			throw new Error( 'Contact reference delete with no id' );
		}
		try {
			await firstValueFrom( this.http.delete( `${ BASE_API_URL }/contactReferences/${ id }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to get contact reference' );
			console.error( error );
			throw error;
		}
	}
	private syncDeleteArrayCache = ( ids: string[] ) => {
		const contactRefs = this.allContactReferences.getValue();
		ids.forEach( id => {
			if ( id in contactRefs ) delete contactRefs[ id ];
		} );
		this.allContactReferences.next( contactRefs );
		this.allContactReferenceArray.next( maptoarray( contactRefs ) );
	}

	public deleteContactReferenceByRef = async ( refType: string, refId: string ) => {

		if ( !refType || !refId ) {
			this.tt.warning( 'Contact reference requested with no reference' );
			throw new Error( 'Contact reference requested with no reference' );
		}
		try {
			const contactRefIds = await firstValueFrom( this.http.delete<string[]>( `${ BASE_API_URL }/contactReferences/${ refType }/${ refId }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to get contact reference' );
			console.error( error );
			throw error;
		}
	}
	private syncUpdateCache = ( updatePayload: ContactReference ) => {
		const contactRefs = this.allContactReferences.getValue();
		if ( contactRefs[ updatePayload.id ] ) {
			contactRefs[ updatePayload.id ] = updatePayload;
			this.allContactReferences.next( contactRefs );
			this.allContactReferenceArray.next( maptoarray( contactRefs ) );
		}
	}
	public updateContactReference = async ( id: string, update: ContactReference ) => {
		if ( !id ) {
			this.tt.warning( 'Contact reference update with no id' );
			throw new Error( 'Contact reference update with no id' );
		}
		try {
			const updated = await firstValueFrom( this.http.put<ContactReference>( `${ BASE_API_URL }/contactReferences/${ id }}`, update ) );
			return updated;
		} catch ( error ) {
			this.tt.error( 'Failed to get contact reference' );
			console.error( error );
			throw error;
		}
	}
	public updateRefenceRef = async ( oldRefType: string, oldRefId: string, newRefType: string, newRefId: string ) => {
		if ( !oldRefType || !oldRefId || !newRefType || !newRefId ) {
			this.tt.warning( 'Contact reference  update with no reference' );
			throw new Error( 'Contact reference  update with no reference' );
		}
		try {
			const updated = await firstValueFrom( this.http.put<ContactReference[]>( `${ BASE_API_URL }/contactReferences/${ oldRefType }/${ oldRefId }`, { refType: newRefType, refId: newRefId } ) );
			return updated;
		} catch ( error ) {
			this.tt.error( 'Failed to get contact reference' );
			console.error( error );
			throw error;
		}

	}
	private syncCreateCache = ( newContactRef: ContactReference ) => {

		if ( Object.values( this.allContactReferences.getValue() ).length > 0 ) {
			const allContactReferences = this.allContactReferences.getValue();
			allContactReferences[ newContactRef.id ] = newContactRef;
			this.allContactReferences.next( allContactReferences );
			this.allContactReferenceArray.next( maptoarray( allContactReferences ) );
		}

	}
	public contactReferenceCreate = async ( contactRefPayload: ContactReference ) => {


		try {
			const newContactRef = await firstValueFrom( this.http.post<ContactReference>( `${ BASE_API_URL }/contactReferences`, contactRefPayload ) );
			return newContactRef;
		} catch ( error ) {
			this.tt.error( error.error.message );
			console.error( error );
			throw error;
		}

	}
	public syncBulkCache = ( contactRefs: ContactReference[] ) => {
		if ( Object.values( this.allContactReferences.getValue() ).length > 0 ) {
			const allContactReferences = this.allContactReferences.getValue();
			contactRefs.forEach( ( contactRef ) => {

				allContactReferences[ contactRef.id ] = contactRef;
			} );
			this.allContactReferences.next( allContactReferences );
			this.allContactReferenceArray.next( maptoarray( allContactReferences ) );
		}
	}
	public contactReferenceBulkCreate = async ( contactRefPayloads: ContactReference[] ) => {


		try {
			const newContactRefs = await firstValueFrom( this.http.patch<ContactReference[]>( `${ BASE_API_URL }/contactReferences`, contactRefPayloads ) );
			return newContactRefs;
		} catch ( error ) {
			this.tt.error( 'Failed to create the Unit on the server' );
			console.error( error );
			throw error;
		}

	}

	public contactReferenceBulkCreateDelete = async ( contactFunctions: ContactReference[], ids: string[] ) => {
		try {
			const newContactRefs = await firstValueFrom( this.http.post<ContactReference[]>( `${ BASE_API_URL }/contactReferences/bulk`, { contactFunctions, ids } ) );
			this.tt.success( 'Contact functions updated successfully' );
			return newContactRefs;
		} catch ( error ) {
			this.tt.error( 'Failed to update the contact functions on the server' );
			console.error( error );
			throw error;
		}
	}
	public async getContactReferenceByRefNoCache ( refType: string, refId: string ) {
		const url = `${ BASE_API_URL }/contactReferences/${ refType }/${ refId }`
		return await firstValueFrom( this.http.get<ContactReference[]>( url ) )
	}
}
