
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JWTPayload } from '../../../models/jwt.model';
import { BehaviorSubject, catchError, EMPTY, firstValueFrom, NEVER } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { FcmTokenService } from './fcm-token.service';
import { NgForage } from 'ngforage';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;
const jwtTokenConfig = environment.jwtTokenConfig;

interface TokenResponse {
	token: string;
}

@Injectable( {
	providedIn: 'root'
} )
export class AuthService {
	private AUTH_API = `${ BASE_API_URL }/auth`;
	public currentUserData: BehaviorSubject<JWTPayload> = new BehaviorSubject( null );
	public user: JWTPayload = null;
	public contact;
	public userType: string;
	public isActive = new BehaviorSubject( false );
	public isAttacked = false;
	redirectUrl: string | null = null;

	constructor (
		private http: HttpClient,
		private router: Router,
		private toastrService: CustomToastrService,
		private fcmTokenService: FcmTokenService,
		protected storageService: NgForage,
	) {
		if ( window[ 'Cypress' ] ) {
			window[ 'Auth' ] = this
		}
	}

	public signInUser = async ( email: string, pass: string ): Promise<JWTPayload> => {
		if ( !email || !pass ) return null;
		try {
			// do check email and pass in backend
			const tokenResponse = await firstValueFrom( this.http.post<TokenResponse>( this.AUTH_API, { email, password: pass } ) );
			const decodedToken = decodeToken(tokenResponse.token || '');
			localStorage.setItem( jwtTokenConfig.storagePathUI, JSON.stringify( decodedToken ) );
			window.removeEventListener( 'storage', () => { } );
			window.addEventListener( 'storage', () => {
				this.isAttacked = true;
				this.router.navigate( [ '/signin' ] );
				return EMPTY;
			} );
			this.currentUserData.next( decodedToken );
			this.isActive.next( true );
			this.user = decodedToken;
			if ( this.redirectUrl ) {
				const url = this.redirectUrl;
				this.redirectUrl = null;
				this.router.navigate( [ url ] );
			}
			return decodedToken;
		} catch ( error ) {
			this.signOutUser();
			throw error;
		}
	}

	public checkUser = async ( email: string, pass: string ): Promise<JWTPayload> => {
		if ( !email || !pass ) return null;
		const jwtData = await firstValueFrom( this.http.post<TokenResponse>( `${ this.AUTH_API }/verified`, { email, password: pass } ) );
		return <JWTPayload> jwt_decode( jwtData.token || '' );
	}
	public googleSignInUser = async ( email: string, idToken: string ): Promise<JWTPayload> => {
		if ( !email ) return null;
		try {
			// do check email and pass in backend
			const jwtData = await firstValueFrom( this.http.post<TokenResponse>( `${ this.AUTH_API }/googleAuth`, { email, idToken } ) );
			const decodedToken = decodeToken(jwtData.token || '');
			localStorage.setItem( jwtTokenConfig.storagePathUI, JSON.stringify( decodedToken ) );
			window.removeEventListener( 'storage', () => { } );
			window.addEventListener( 'storage', () => {
				this.isAttacked = true;
				this.router.navigate( [ '/signin' ] );
				return EMPTY;
			} );
			this.currentUserData.next( decodedToken );
			this.isActive.next( true );
			this.user = decodedToken;
			return decodedToken;
		} catch ( error ) {
			this.signOutUser();
			throw error;
		}
	}


	public signInAndSendEmailVerification = async ( email: string, password: string ) => {
		if ( !email || !password ) return false;
		try {
			const jwtData = await firstValueFrom( this.http.post<TokenResponse>( `${ this.AUTH_API }/verified`, { email, password } ) );
			const decodedToken = decodeToken(jwtData.token || '');
			localStorage.setItem( jwtTokenConfig.storagePathUI, JSON.stringify( decodedToken ) );
			window.removeEventListener( 'storage', () => { } );
			window.addEventListener( 'storage', () => {
				this.isAttacked = true;
				this.router.navigate( [ '/signin' ] );
				return EMPTY;
			} );
			await this.sendEmailVerification( email, decodedToken.uid );
			localStorage.removeItem( jwtTokenConfig.storagePathUI );
			this.currentUserData.next( null );
			this.isActive.next( false );
			this.user = null;
			return true;
		} catch ( error ) {
			localStorage.removeItem( jwtTokenConfig.storagePathUI );
			console.error( error );
			return false;
		}
	}

	public verifyUserToken = async () => {
		try {
			const locallyStoredUser = localStorage.getItem( jwtTokenConfig.storagePathUI );
			if ( locallyStoredUser ) {
				const userData = JSON.parse( locallyStoredUser );
				if ( userData ) {
					this.doCorrectNavigateByUser( userData );
					if ( userData.actorType !== 'contact' && userData.actorType !== 'vendor' ) {
						this.currentUserData.next( userData );
						this.isActive.next( true );
						this.user = userData;
					} else {
						this.user = userData;
						this.userType = userData.actorType;
						this.contact = userData.contact;
					}
					return true;
				}
			} else {
				this.currentUserData.next( null );
				this.isActive.next( false );
				this.user = null;
				return false;
			}
		} catch ( error ) {
			localStorage.removeItem( jwtTokenConfig.storagePathUI );
			console.error( error );
			this.signOutUser();
			return false;
		}

	}

	public signOutUser = async () => {
		try {
			this.currentUserData.next( null );
			this.isActive.next( false );
			this.user = null;
			localStorage.removeItem( jwtTokenConfig.storagePathUI );
			const token = await this.getFCMToken();
			const hasToken = await this.hasFCMToken();
			this.router.navigate( [ '/signin' ] )
			if ( hasToken && token !== null ) {
				await this.storageService.removeItem( 'fcm_token' );
				await this.fcmTokenService.unregister( token );
			}
		} catch ( error ) {
			console.error( error );
		}
	}

	public sendEmailVerification = async ( email: string, uid: string ) => {
		await firstValueFrom( this.http.post( `${ BASE_API_URL }/emailVerify/send`, { email, id: uid } ) );
	}
	public sendOTP = async ( email: string ) => {
		if ( !email ) return null;
		await firstValueFrom( this.http.post<any>( `${ BASE_API_URL }/auth/emailOTP`, { email } ) );
	}
	public verifyOTP = async ( email: string, password: string, vendor?: boolean ): Promise<JWTPayload> => {
		try {
			let url = `${ BASE_API_URL }/auth/verifyOTP`;
			if ( vendor ) {
				url = `${ BASE_API_URL }/auth/vendor/verifyOTP`;
			}
			const jwtData = await firstValueFrom( this.http.post<{jwt: string}>( url, { email, password } ) );
			const decodedToken = decodeToken(jwtData.jwt || '');
			localStorage.setItem( jwtTokenConfig.storagePathUI, JSON.stringify( decodedToken ) );
			window.removeEventListener( 'storage', () => { } );
			window.addEventListener( 'storage', () => {
				this.isAttacked = true;
				this.router.navigate( [ '/signin' ] );
				return EMPTY;
			} );
			this.currentUserData.next( decodedToken );
			this.user = decodedToken;
			return decodedToken;
		} catch ( error ) {
			this.toastrService.warning( 'Failed to verify OTP' );
			localStorage.clear();
			throw error;
		}
	}




	doCorrectNavigateByUser ( userData ) {
		const currentUrl = this.CurrentUrl || this.redirectUrl;
		if ( !currentUrl ) {
			switch ( userData.actorType ) {
				case 'contact':
					if ( !this.redirectUrl ) {
						this.router.navigateByUrl( '/welcome-customer' );
					} else {
						this.router.navigate( [ this.redirectUrl ] );
					}
					break;
				case 'vendor':
					this.router.navigateByUrl( '/vendor-home' );
					break;
				default:
					this.router.navigateByUrl( '/global-view' );
					break;
			}
		}
	}


	verifyOTPAndGetCustomer ( email: string, password: string, vendor?: boolean ) {
		let url = `${ BASE_API_URL }/auth/verifyOTP`;
		if ( vendor ) {
			url = `${ BASE_API_URL }/auth/vendor/verifyOTP`;
		}
		return this.http.post<any>( url, { email, password } ).pipe(
			catchError( () => {
				this.toastrService.warning( 'Failed to verify OTP' );
				return NEVER;
			} )
		);
	}

	public verifyOTPByJwtData = async ( jwtData ): Promise<JWTPayload> => {
		try {
			const decodedToken = decodeToken(jwtData.jwt || '');
			localStorage.setItem( jwtTokenConfig.storagePathUI, JSON.stringify( decodedToken ) );
			window.removeEventListener( 'storage', () => { } );
			window.addEventListener( 'storage', () => {
				this.isAttacked = true;
				this.router.navigate( [ '/signin' ] );
				return EMPTY;
			} );
			this.currentUserData.next( decodedToken );
			this.user = decodedToken;
			return decodedToken;
		} catch ( error ) {
			this.toastrService.warning( 'there are something wrong' );
			localStorage.clear();
			throw error;
		}
	}


	get CurrentUrl () {
		return window.location.href.split( '/' )[ 3 ];
	}

	async setFCMToken ( token: string ) {
		await this.storageService.setItem( 'fcm_token', token );
	}

	async getFCMToken () {
		const token: any = await this.storageService.getItem( 'fcm_token' );
		return token;
	}

	async hasFCMToken () {
		const token = await this.getFCMToken();
		if ( token != null ) {
			return true;
		}
		return false;
	}

}

function decodeToken(token: string): JWTPayload & TokenResponse {
	const decodedToken = <JWTPayload> jwt_decode( token);
	return {
		...decodedToken,
		token,
	}
}
