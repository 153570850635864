
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { distinctUntilChanged, retry, tap, map } from 'rxjs/operators';
import { IMap } from '../../../models/generic.models';
import { SystemService } from './system.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { arraytomap, maptoarray } from '../../../utils/mapandarray';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';
import { sortBy } from '../../../utils/sortby';
import { CommunityService } from './community.service';
import { LeaseUnitDetails, MyUnit, reverseTransformUnit, transformUnit, Unit, UnitFireBase, UnitType, updateUnitsData } from '../../../models/unit.pg.model';
import { ContactRelation } from '../../../models/contacts.models';
import { CostCenterVM } from '../../../models/costCenter.models';
import {  NEVER } from 'rxjs'
import { environment } from '../environments/environment';
import { CustomMessageService } from './shared/services/customPriemngMessage.service';
import { objectify } from '../../../utils/objectify';

const BASE_API_URL = environment.apiURL;
const MAX_API_CALL_RETRIES = environment.maxAPICallRetries;


@Injectable( {
	providedIn: 'root'
} )
export class UnitService {

	public units: BehaviorSubject<IMap<UnitFireBase>> = new BehaviorSubject( {} );
	public unitArray: BehaviorSubject<UnitFireBase[]> = new BehaviorSubject( [] );
	public unitWithBuildingArray= new BehaviorSubject<any>( [] );
	public unit: BehaviorSubject<UnitFireBase> = new BehaviorSubject( {} as UnitFireBase );
	public unitID: BehaviorSubject<string> = new BehaviorSubject( '' );
	private unitTypeCounts: BehaviorSubject<any[]> = new BehaviorSubject( [] );
	private allUnits: BehaviorSubject<IMap<UnitFireBase>> = new BehaviorSubject( {} );
	private subs: Subscription[] = [];
	private mainSub: Subscription;

	constructor (
		private http: HttpClient,
		private ss: SystemService,
		private communityService: CommunityService,
		private tt: CustomToastrService,
		private messageService: CustomMessageService,
	) {
		this.mainSub = ss.isActive.pipe( distinctUntilChanged() ).subscribe( user => {

			if ( !user ) {
				this.subs.forEach( sub => {
					if ( sub?.unsubscribe ) {
						sub.unsubscribe();
					}
				} );
				return;
			}

			this.communityUnitTypeCountsGet();
			this.subs.push( communityService.projectID.pipe( distinctUntilChanged() ).subscribe( this.communityUnitsGet ) );
			this.subs.push( this.unitID.pipe( distinctUntilChanged() ).subscribe( this.unitGet ) );
			this.subs.push( this.ss.url.pipe( distinctUntilChanged() ).subscribe( url => {
				const urlSegments = url.split( '/' );
				if ( urlSegments[ 3 ] === 'units' ) {
					const uID = urlSegments[ 4 ] || '';
					this.unitID.next( uID );
				}
			} ) );
		} );

	}

	public unitCreate = async ( unitPayload: Partial<UnitFireBase> ) => {

		let newUnit = null;
		try {
			const newUnitPG = await firstValueFrom( this.http.post<Unit>( `${ BASE_API_URL }/unit`, transformUnit( unitPayload ) ) );
			newUnit = reverseTransformUnit( newUnitPG );
		} catch ( error ) {
			this.tt.error( 'Failed to create the Unit on the server' );
			console.error( error );
			throw error;
		}
		await this.communityUnitsGet();
		return newUnit;

	}
	/* to do for id's */
	public unitUpdate = async ( unitId: string, unitPayload: Partial<UnitFireBase> ) => {
		if ( !unitId ) {
			this.tt.warning( 'Community update is requested without a unit ID' );
			throw new Error( 'Community update is requested without a unit ID' );
		}
		try {
			await firstValueFrom( this.http.put( `${ BASE_API_URL }/unit/${ unitId }`, transformUnit( unitPayload ) ) );
			await this.communityUnitsGet();

		} catch ( error ) {
			this.messageService.error({ sticky: true, message: 'Failed to update the unit.' } );
			console.error( error );
			throw error;
		}
		return true;
	}

	public unitsUpdate = async (data:updateUnitsData[] ) => {
		data.map(data=>{
			data.payload = objectify(data.payload)
			delete data.payload.createdAt			
		})
		try {
			await firstValueFrom( this.http.put<Unit[]>( `${ BASE_API_URL }/units`, { data :data}) );
			await this.communityUnitsGet();
			this.messageService.success( { sticky: true, message: 'Units Updated Successfully.' } );
		} catch ( error ) {
			this.tt.error( 'Failed to upsert unit batch' );
			console.error( error );
			throw error;
		}
		return true ;
	}

	public unitsFromCsv = async ( payload: {} ) => {
		try {
			const res = await firstValueFrom( this.http.post<{}>( `${ BASE_API_URL }/unitFromCSV`, payload ) );
			await this.communityUnitsGet();
			return res;
		} catch ( error ) {
			this.tt.error( 'Failed to upsert unit batch' );
			console.error( error );
			throw error;
		}
	}

	public unitDelete = async ( unitId: string ) => {

		if ( !unitId ) {
			this.tt.warning( 'Unit delete is requested without a community ID' );
			throw new Error( 'Unit delete is requested without a community ID' );
		}

		try {
			await firstValueFrom( this.http.delete( `${ BASE_API_URL }/unit/${ unitId }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to delete the unit' );
			console.error( error );
			throw error;

		}

		await this.communityUnitsGet();
		return true;

	}
	public updateCachedUnits = async ( communityId?: string ) => {
		await this.communityUnitsGet( communityId );
	}
	public unitsWithDataGet = async ( communityId?: string, constCenter?: string ) => {
    try {
			this.unitWithBuildingArray.next([])
			if ( !communityId ) communityId = await this.communityService.getCommunityId();
			const units=( await firstValueFrom( this.http.get( `${ BASE_API_URL }/unitsWithData/${ communityId }/?costCenter=${ constCenter }` ) ) )
		this.unitWithBuildingArray.next(units)
			return true
		} catch (error) {
			this.tt.error( 'Failed to fetch units with data from the server' );
			console.error( error );
			throw error;
		}
  };

	public unitsWithCcName = async ( constCenter:string ,communityId?: string ): Promise<CostCenterVM> => {
		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		return ( await firstValueFrom( this.http.get( `${ BASE_API_URL }/unitsWithData/${ communityId }/?costCenter=${ constCenter }` ) ) as CostCenterVM )
	};
	public communityUnitsGet = async ( communityId?: string ) => {
		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		const units = ( await this.communityUnitsFetch( communityId ) ).sort( sortBy( 'name', false, false ) );
		this.unitArray.next( units );
		this.units.next( arraytomap( units ) );
		await this.unitsWithDataGet();
		return true;

	}
	public communityUnitsWithDataFetch = async ( communityId: string ) => {

		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		if ( communityId ) {
			try {
				const units = await firstValueFrom( this.http.get<Unit[]>( `${ BASE_API_URL }/unitsWithData/${ communityId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
				console.log(units)
				return units.map( reverseTransformUnit );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch units from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Units fetch is requested without a community ID' );
			throw new Error( 'Units fetch is requested without a community ID' );
		}

	}
	public communityUnitsFetch = async ( communityId: string ) => {

		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		if ( communityId ) {
			try {
				const units = await firstValueFrom( this.http.get<Unit[]>( `${ BASE_API_URL }/units/${ communityId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
				return units.map( reverseTransformUnit );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch units from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Units fetch is requested without a community ID' );
			throw new Error( 'Units fetch is requested without a community ID' );
		}

	}

	private unitGet = async ( unitId: string ) => {

		if ( !unitId ) {
			this.unit.next( null );
			return;
		}
		const units = this.getUnits();
		const unit = ( await units[ unitId ] ) || ( await this.unitFetch( unitId ) );
		this.unit.next( unit );
		return true;

	}

	public unitFetch = async ( unitId: string ) => {

		if ( unitId ) {
			try {
				const unit = await firstValueFrom( this.http.get<Unit>( `${ BASE_API_URL }/unit/${ unitId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
				return reverseTransformUnit( unit );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch unit from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Unit fetch is requested without a unit ID' );
			throw new Error( 'Unit fetch is requested without a unit ID' );
		}

	}

	public getLeaseUnitDetails= async(unitId:string)=>{
		try {
			return await firstValueFrom( this.http.get<LeaseUnitDetails>( `${ BASE_API_URL }/lease-unit/${ unitId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch unit details from the server' );
			console.error( error );
			throw error;
		}
	}

	private communityUnitTypeCountsGet = async () => {

		try {

			return await firstValueFrom( this.http.get<any[]>( `${ BASE_API_URL }/unit/typecounts` )
				.pipe( retry( MAX_API_CALL_RETRIES ),
					tap( res => ( this.unitTypeCounts.next( res ) ) ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch typecounts from the server' );
			console.error( error );
			throw error;
		}

	}
	public getAllUnits = async (): Promise<IMap<UnitFireBase>> => {

		if ( Object.values( this.allUnits.getValue() ).length > 0 ) return this.allUnits.getValue();
		try {
			return await firstValueFrom( this.http.get<Unit[]>( `${ BASE_API_URL }/unit` )
				.pipe( retry( MAX_API_CALL_RETRIES ),
					map( res => res.map( reverseTransformUnit ) ),
					map( res => arraytomap( res ) ),
					tap( res => ( this.allUnits.next( res ) ) ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch all units from the server' );
			console.error( error );
			throw error;
		}
	}
	public getUnitTypes = async () => {
		try {
			const types = await firstValueFrom( this.http.get<UnitType[]>( `${ BASE_API_URL }/unitTypes/` ) );
			return types;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the types from the server' );
			console.error( error );
			throw error;
		}
	}
	public getMyUnit = async ( contact = '' ) => {
		try {
			const myUnits = await firstValueFrom( this.http.get<MyUnit[]>( `${ BASE_API_URL }/myUnits/`, { params: { contact } } ) );
			return myUnits;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the units from the server' );
			console.error( error );
			throw error;
		}
	}

	public getMyUnitById = async ( id: string ) => {
		try {
			const myUnit = await firstValueFrom( this.http.get<MyUnit>( `${ BASE_API_URL }/myUnitsById/${ id }` ) );
			return myUnit;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the unit from the server' );
			console.error( error );
			throw error;
		}
	}

	public getMyUnitByEmail = async ( email: string ) => {
		try {
			const myUnits = await firstValueFrom( this.http.get<ContactRelation[]>( `${ BASE_API_URL }/myUnits/${ email }` ) );
			return myUnits;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the units from the server' );
			console.error( error );
			throw error;
		}
	}

	public getMyUnitsByEmail = async () => {
		try {
			const myUnits = await firstValueFrom( this.http.get<ContactRelation[]>( `${ BASE_API_URL }/myUnitsByEmail` ) );
			return myUnits;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the units from the server' );
			console.error( error );
			throw error;
		}
	}

	public getMyUnitByContactId = async ( contact: string ) => {
		try {
			const myUnits = await firstValueFrom( this.http.get<ContactRelation[]>( `${ BASE_API_URL }/myUnitsByContactId/${ contact }` ) );
			return myUnits;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the units from the server' );
			console.error( error );
			throw error;
		}
	}
	public getAllUnitArray = async (): Promise<UnitFireBase[]> => {
		return maptoarray( await this.getAllUnits() );
	}
	public getUnitsArrayByCommunityId = async ( id: string ): Promise<UnitFireBase[]> => {
		return maptoarray( await this.getAllUnits() ).filter( unit => unit.project === id ).sort( sortBy( 'name', false, false ) );
	}
	public getUnitsArrayByCommunityIdAndBuildingId = async ( ProjectId: string, BuildingId: string ): Promise<UnitFireBase[]> => {
		return maptoarray( await this.getAllUnits() ).filter( unit => unit.project === ProjectId && unit.building === BuildingId ).sort( sortBy( 'name', false, false ) );
	}
	public getUnitsByCommunityId = async ( id: string ): Promise<IMap<UnitFireBase>> => {
		return arraytomap( await this.getUnitsArrayByCommunityId( id ) );
	}
	public getUnit = async () => await getNonNullNonEmptyValue<UnitFireBase>( this.unit );
	public getUnits = async () => await getNonNullNonEmptyValue<IMap<UnitFireBase>>( this.units );
	public getUnitArray = async () => await getNonNullNonEmptyValue<UnitFireBase[]>( this.unitArray );

	public getUnitById = async ( id: string ): Promise<UnitFireBase> => ( ( this.units.getValue() ? this.units.getValue()[ id ] : null ) || ( await this.getAllUnits() )[ id ] || await this.unitFetch( id ) || { id, name: 'N/A' } as UnitFireBase );
	public getCommunityUnitTypeCounts = async () => await getNonNullNonEmptyValue( this.unitTypeCounts );

	public communityBuildingUnitsFetch = async ( community: string, building: string ) => {
		try {
			return await firstValueFrom( this.http.get<Unit[]>( `${ BASE_API_URL }/communityBuildingUnits/${ community }/${ building }` ) );
		} catch ( error ) {
			this.messageService.error({ sticky: true, message: 'Failed to fetch units from the server' } );
			console.error( error );
			throw error;
		}
	}

	public async getContactUnits ( contact: string, withTenant = false ): Promise<any> {
		try {
			return await firstValueFrom( this.http.get<any>( `${ BASE_API_URL }/contactUnits/${ contact }/${ withTenant }` ) );
		} catch ( error ) {
			this.messageService.error({ sticky: true, message: 'Failed to fetch contact units from the server' } );
			console.error( error );
			throw error;
		}
	}

	public downloadSamples = (): any[] => {
		const url = `${ BASE_API_URL }/downloadSamples`;
		const result = [];
		const ownerFile = this.http.post( url, { type: 'owner' }, {
			responseType: 'blob',
			headers: new HttpHeaders().append( 'Accept', 'text/csv' )
		} );
		result.push( ownerFile );

		const areaFile = this.http.post( url, { type: 'area' }, {
			responseType: 'blob',
			headers: new HttpHeaders().append( 'Accept', 'text/csv' )
		} );
		result.push( areaFile );

		const tenantFile = this.http.post( url, { type: 'tenant' }, {
			responseType: 'blob',
			headers: new HttpHeaders().append( 'Accept', 'text/csv' )
		} );
		result.push( tenantFile );

		return result;
	}

	public unitsByCommunities = async ( communities: string[] ) => {
		try {
			const res = ( await firstValueFrom( this.http.post<Unit[]>( `${ BASE_API_URL }/communitiesUnits`, { communities } ) ) ) || [];
			return res;
		} catch ( error ) {
			console.error( error );
			throw error;
		}
	}

		adapter = (unitsAreas) => {
    const unitsAreasAdapter = []
    unitsAreas.map((unitsArea) => {
      unitsAreasAdapter.push({
        id: unitsArea.id,
				name:unitsArea.name,
        area: unitsArea.area,
        actualArea: unitsArea.actualArea,
        balconyArea: unitsArea.balconyArea,
        suitArea: unitsArea.suitArea,
      })
    })
		return unitsAreasAdapter
  }
  public updateUnitsAreas = async (unitsAreas) => {
    await firstValueFrom(
      this.http.put(`${BASE_API_URL}/updateAreas`, { unitsAreas: this.adapter(unitsAreas) }),
    ).catch(() => {
      this.tt.error(`Error in update Areas`)
      return NEVER
    })
  }

		public downloadUpdateTemplate ( community?: string ) {
		return this.http.get( `${ BASE_API_URL }/getAreasUpdateTemplate/${ community }`, {
			responseType: 'blob',
			observe: 'response',
		} );
	}


}


