
export const StorageKeys = {
    currentCommunityId: 'currentCommunityId',
    deniedAccessUrl: 'deniedAccessUrl',
};

export class SessionStorageClient {
    static getItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    static setItem(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    static removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    static clear(): void {
        sessionStorage.clear();
    }
}
