import { AppEnvironment } from "./AppEnvironment";

export const environment = {
	production: false,
	appEnv: AppEnvironment.prod,
	apiURL: 'https://api.socienta.io',
	vendorPortalURL: 'https://vendorportal.socienta.io/',
	maxAPICallRetries: 1,
		jwtTokenConfig: {
		storagePathUI: 'currentUserData',
	},
	snagReportSettings: {
		api: {
			url: 'https://api.snagreport.com',
			reportsStorage: 'reportscache',
		},
		front: 'https://snagreport.com',
	},
	yellowfinSetting: {
		url: 'https://yf.socienta.io/api',
	},
	/**  it's safe to expose firebase -> https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public */
	firebase: {
		apiKey: 'AIzaSyBH2QJMLc3QEnMxOyOAiREsQR6ljri33iM',
		authDomain: 'socienta-app.firebaseapp.com',
		projectId: 'socienta-app',
		databaseURL: 'https://socienta-app.firebaseio.com',
		storageBucket: 'socienta-app.appspot.com',
		messagingSenderId: '593374044825',
		appId: '1:593374044825:web:e4e740e27c73f5da5e8e08',
		measurementId: 'G-5QCN7V6JJF',
	},
	googleAppSettings: {
		clientId: '593374044825-679cm9mun6a9d9kupk8gqpj7i6ci4qin.apps.googleusercontent.com',
	},
};