import { sortBy } from '../../../../../../utils/sortby';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { CommunityService } from 'src/app/community.service';
import { UserService } from 'src/app/user.service';
import { SystemService } from 'src/app/system.service';
import { DocsWhitelistService } from 'src/app/docs-whiltelist.service';
import { ContactsService } from 'src/app/contacts.service';
import { Contact } from '../../../../../../models/contacts.models';
import { AuthService } from 'src/app/auth.service';
import { ClientService } from 'src/app/client.service';
import { YellowfinService } from 'src/app/yellowfin.service';
import { NotificationsService } from 'src/app/notifications.service';
import { MessagingService } from 'src/app/messaging.service';
import { FcmTokenService } from 'src/app/fcm-token.service';
import { NotificationTitle } from '../../../../../../models/notifications.model';
import { ContactReferenceService } from 'src/app/contact-reference.service';
import { UnitService } from 'src/app/unit.service';
import { BuildingService } from 'src/app/building.service';
import { Project } from '../../../../../../models/project.models';
import { maptoarray } from '../../../../../../utils/mapandarray';
import { LeasePGService } from 'src/app/lease-pg.service';
import { CustomMessageService } from "../../../shared/services/customPriemngMessage.service";

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
	items = [];
	private ngUnsubscribe = new Subject();
	public notificationTitle = NotificationTitle;
	public communityList = [];
	public dashboardsList = [];
	public communityOptionsList = [];
	public accountingOptionsList = [];
	public administrationList = [];
	public globalAdministrationList = [];
	public settingsAdministrationList = [];
	public requestsAdministrationList = [];
	public facilitiesAdministrationList = [];
	public displayCommunityOptionsList = false;
	public displayAdministrationListOnly = false;
	public displayGlobalAdministrationListOnly = false;
	public displayAccountingOptionsList = false;
	public displaySettingsAdministrationList = false;
	public displayRequestsAdministrationList = false;
	public displayFacilitiesAdministrationList = false;
	public displayClientsAdministrationList = false;
	public communityListHeader: string;
	public communityOptionsListHeader: string;
	public AccountingListHeader: string;
	public administrationListHeader: string;
	public globalAdministrationListHeader: string;
	public administrationSettingsListHeader: string;
	public administrationRequestsListHeader: string;
	public administrationinitiativesListHeader: string;
	public administrationFacilitiesListHeader: string;
	public administrationClientsListHeader: string;
	public urlSegments: string[] = [];
	public selectedProjectName: string;
	public isKaizenEmployee = false;
	public isLandlord = false;
	public isTenant = false;
	public isDraftTenant = false;
	public isDraft = false;
	public allowOnDocuments = false;
	public isCollapsed = true;
	private sub: Subscription;
	public currentCommunityURL = '';
	public contact: Contact;
	public userEmail = '';
	public displayName = '';
	public createdProject: Subscription;
	public currentUser;
	public selectedProject: any = null;
	public homeRoute;
	public isContact;
	public isVendor: boolean;
	public logoUrl = '';
	public isContactHome = false;
	public epcexternalPartyContact = false;
	public showOnPortal: boolean;
	public clientInfo: any;
	public profileMenuItems = [];
	public selectedProfileItem: any;
	public notifications = [];
	public recentNotifications = [];
	public oldNotifications = [];
	public notificationsCount = 0;
	public notificationsIcon = [];
	public canViewAnnouncements = false;
	public showLostFound = false;
	public isOwnersCommitteeMember = false;

	filteredGroups: any[];
	buildings: any[];
	communities: Project[];
	toSearch: string;
	viewContacts = true;
	viewUnits = true;
	showNavbarSearch = true;

	constructor(
		public ss: SystemService,
		public userService: UserService,
		public contactService: ContactsService,
		public contactRefService: ContactReferenceService,
		private authService: AuthService,
		private router: Router,
		private communityService: CommunityService,
		private wl: DocsWhitelistService,
		private clientService: ClientService,
		private yfService: YellowfinService,
		private notificationsService: NotificationsService,
		private messagingService: MessagingService,
		private fcmTokenService: FcmTokenService,
		private unitService: UnitService,
		private buildingService: BuildingService,
		private leaseService: LeasePGService,
		private messageService: CustomMessageService,
	) {
		this.communityOptionsList = [
			{ id: 0, name: 'Property Group Home', link: '/', icon: '/assets/icons/community/Community.svg' },
			{ id: 1, name: 'Profile', link: 'profile', icon: '/assets/icons/community/profile.svg' },
			{ id: 4, name: 'Cost Centers', link: 'costcenters', icon: '/assets/icons/community/costcenters.svg' },
			{ id: 5, name: 'Property Subgroups', link: 'buildings', icon: '/assets/icons/community/buildings.svg' },
			{ id: 6, name: 'Units', link: 'units', icon: '/assets/icons/community/units.svg' },
			{ id: 7, name: 'Owners Association', link: 'oa', icon: '/assets/icons/community/oa.svg' },
			{ id: 8, name: 'Helpline & FAQ\'s', link: 'helpline-faq', icon: '/assets/icons/community/importantinformation.svg' },
			{ id: 10, name: 'Event Calendar', link: 'event-calendar', icon: '/assets/icons/community/eventcalendar.svg' },
			{ id: 12, name: 'Accounting', link: 'accounting', icon: '/assets/icons/community/accounting.svg' },
			{ id: 14, name: 'Announcements', link: 'announcements', icon: '/assets/icons/community/announcement.svg' },
			{ id: 15, name: 'Work Orders', link: 'workorders', icon: '/assets/icons/community/workorders.svg' },
			{ id: 16, name: 'Documents', link: 'documents', icon: '/assets/icons/community/documents.svg' },
			{ id: 18, name: 'Facilities', link: 'facilities', icon: '/assets/icons/community/eventcalendar.svg' },
			{ id: 17, name: 'Integrations', link: 'integrations', icon: '/assets/icons/ledgerv2-icons/adjustments.svg' },
			{ id: 19, name: 'Property Group Settings', link: 'community-settings', icon: '/assets/icons/community/community-settings.svg' },
			{ id: 20, name: 'Terms & Conditions', link: 'terms-conds', icon: '/assets/icons/community/terms.svg' },
			{ id: 21, name: 'Contracts\' Terms', link: 'contracts-terms-conditions', icon: '/assets/icons/settings/acclist.svg' },
		];
		this.accountingOptionsList = [
			{ id: 0, name: 'Accounting Home', link: '/accounting/', icon: '/assets/icons/community/accounting.svg' },
			{ id: 1, name: 'Unit Financials', link: '/accounting/unit-financials', icon: '/assets/icons/ledgerv2-icons/unit-based-revenue.svg' },
			{ id: 2, name: 'Customers & Sales', link: '/accounting/sales', icon: '/assets/icons/ledgerv2-icons/customers-sales.svg' },
			{ id: 3, name: 'Purchases', link: '/accounting/purchases', icon: '/assets/icons/ledgerv2-icons/vendors-purchases.svg' },
			{ id: 4, name: 'General Ledger', link: '/accounting/gl', icon: '/assets/icons/ledgerv2-icons/general-ledger.svg' },
			{ id: 4, name: 'General Ledger New View', link: '/accounting/general-ledger', icon: '/assets/icons/ledgerv2-icons/general-ledger.svg' },
			{ id: 5, name: 'Cash & Cheque Movements', link: '/accounting/cp', icon: '/assets/icons/ledgerv2-icons/cash-position.svg' },
			{ id: 5, name: 'Adjustments', link: '/accounting/adjustments', icon: '/assets/icons/ledgerv2-icons/adjustments.svg' },
			{ id: 6, name: 'Reports', link: '/accounting/report', icon: '/assets/icons/ledgerv2-icons/reports.svg' },
			{ id: 7, name: 'Financial Settings', link: '/accounting/financial-settings', icon: '/assets/icons/ledgerv2-icons/management.svg' },
			{ id: 8, name: 'Budgets', link: 'budgets', icon: '/assets/icons/community/budgets.svg' },
		];
		this.administrationList = [
			{ id: 0, name: 'Administration Home', link: 'administration', icon: '/assets/icons/user.svg' },
			{ id: 1, name: 'General Settings', link: 'administration/general-settings', icon: '/assets/icons/administration/settings.svg' },
			{ id: 2, name: 'Users', link: 'users', icon: '/assets/icons/administration/users.svg' },
			{ id: 3, name: 'Account List', link: 'administration/acclist', icon: '/assets/icons/settings/acclist.svg' },
			{ id: 4, name: 'Mail Sending', link: 'administration/mail-sending', icon: '/assets/icons/settings/mail-sending.svg' },
			{ id: 5, name: 'Contacts', link: 'contacts', icon: '/assets/icons/administration/contacts.svg' },
			{ id: 10, name: 'Integrations', link: 'administration/integrations', icon: '/assets/icons/ledgerv2-icons/adjustments.svg' },
			{ id: 11, name: 'Land Lords', link: 'administration/landlords', icon: '/assets/icons/administration/landlords.svg' },
			{ id: 13, name: 'Access Control', link: 'administration/management', icon: '/assets/icons/administration/Accessrights.svg' },
			{ id: 8, name: 'Voiding Requests', link: 'administration/voiding-requests', icon: '/assets/icons/ledgerv2-icons/voiding-requests.svg' },
		];
		this.globalAdministrationList = [
			{ id: 0, name: 'Global Administration Home', link: '', icon: '/assets/icons/user.svg' },
			{ id: 1, name: 'Global Account List', link: 'acclist', icon: '/assets/icons/settings/acclist.svg' },
			{ id: 2, name: 'Clients', link: 'clients', icon: '/assets/icons/administration/clients.svg' },
			{ id: 3, name: 'Users', link: 'users-list', icon: '/assets/icons/administration/users.svg' },
		];
		this.settingsAdministrationList = [
			{ id: 0, name: 'Settings Home', link: 'settings/', icon: '/assets/icons/administration/settings.svg' },
			{ id: 1, name: 'Account List', link: 'settings/acclist', icon: '/assets/icons/settings/acclist.svg' },
			{ id: 3, name: 'SMTP', link: 'settings/smtp', icon: '/assets/icons/settings/smtp.svg' },
			{ id: 4, name: 'MailGun', link: 'settings/mailgun', icon: '/assets/icons/settings/mailgun.svg' },
		];
		this.requestsAdministrationList = [
			{ id: 0, name: 'Requests Home', link: 'request-admin/', icon: '/assets/icons/administration/requests.svg' },
			{ id: 1, name: 'Move-In List', link: 'request-admin/move-in/list', icon: '/assets/icons/administration/Move-In.svg' },
		];
		this.facilitiesAdministrationList = [
			{ id: 0, name: 'Facilities Home', link: '/facilities', icon: '/assets/icons/community/Community.svg' },
			{ id: 1, name: 'Facilities', link: '/facilities/facilities', icon: '/assets/icons/community/buildings.svg' },
			{ id: 2, name: 'Settings', link: '/facilities/settings', icon: '/assets/icons/administration/settings.svg' },
		];
	}

	async ngOnInit() {
		this.buildings = maptoarray(await this.buildingService.getAllbuildings());
		this.communities = (await this.communityService.getAllCommunities());

		this.messagingService.requestPermission().subscribe(async (token) => {
			const hasToken = await this.authService.hasFCMToken();
			if (!hasToken && token !== null) {
				this.authService.setFCMToken(token);
				this.fcmTokenService.register(token)
					.then(v => {
						this.messagingService.receiveMessage();
					})
					.catch(error => console.error(error))
			} else {
				this.messagingService.receiveMessage();
			}
		},
			(err) => {
				console.error('Unable to get permission to notify.', err);
			});
		this.messagingService.notificationsCount.subscribe(value => this.notificationsCount = value);
		this.clientInfo = await this.clientService.getClientByUrl();
		this.logoUrl = this.clientInfo?.logoUrl;
		this.logoUrl === 'assets/video/color.svg' ? this.logoUrl = 'assets/images/socienta-logo-white.png' : this.logoUrl;
		this.showOnPortal = this.clientInfo?.showOnPortal || true;
		this.currentUser = this.authService.user;
		const contact = this.currentUser.contact;
		this.contactRefService.getContactReferenceByContact(contact)
			.then(res => {
				res.forEach(cr => {
					switch (cr.function) {
						case 'epc':
							this.epcexternalPartyContact = true;
							break;
						case 'cvanng':
							this.canViewAnnouncements = true;
							break;
						case 'oa':
							this.isOwnersCommitteeMember = true;
							break;
					}
				})
				if (!this.canViewAnnouncements) {
					this.communityOptionsList = this.communityOptionsList.filter(item => item.name !== 'Announcements')
				}
			});
		this.contact = await this.contactService.contactFetch(this.authService.user.contact);
		switch (this.currentUser.actorType) {
			case 'vendor':
				this.isContact = false;
				this.isVendor = true;
				this.homeRoute = '/vendor-home';
				break;
			case 'contact': {
				this.isContact = true;
				const refs = await this.contactRefService.getContactReferenceByContact(this.authService?.user?.contact);
				this.isDraft = (await this.leaseService.isDraftLease(this.authService?.user?.contact)).isDraft;
				this.isDraftTenant = this.ss.currentUser?.id?.includes('contact-')
					? (!!refs?.find(async ref => ref.function === 'tenant') && this.isDraft) : false;
				this.isVendor = false;
				const contactCommunitiesList = await this.contactService.fetchContactCommunities();
				this.showLostFound = !!contactCommunitiesList.filter(community => !!community.communitySettings?.settings?.enableLostFound).length;
				this.homeRoute = '/welcome-customer';
				break;
			}
			default:
				this.isContact = false;
				this.isVendor = false;
				this.homeRoute = '/global-view';
				break;
		}
		this.userEmail = this.authService.user.email;
		this.sub = this.ss.url
			.subscribe(async (url) => {
				this.urlSegments = url.split('/');
				const parts = url.split('/').splice(1);
				if (parts[0] === 'projects') {
					if (parts[1]) {
						this.displayCommunityOptionsList = true;
						this.currentCommunityURL = `/projects/${parts[1]}`;
						if (parts[2] === 'accounting') {
							this.displayAccountingOptionsList = true;
						}
						if (parts[2] === 'facilities') {
							this.displayFacilitiesAdministrationList = true;
						}
					} else {
						this.currentCommunityURL = '';
						this.displayCommunityOptionsList = false;
						this.displayAccountingOptionsList = false;
						this.displayFacilitiesAdministrationList = false;
					}
					this.displayRequestsAdministrationList = false;
					this.displaySettingsAdministrationList = false;
				} else {
					if (parts[0] === 'welcome-customer') {
						this.isContactHome = true;
					} else {
						this.isContactHome = false;
					}
					if (parts[0] === 'global-view') {
						this.administrationListHeader = this.administrationList[0].name;
						this.globalAdministrationListHeader = this.globalAdministrationList[0].name;
						this.displayAdministrationListOnly = false;
						this.displayGlobalAdministrationListOnly = false;
					}

					for (const iterator of this.globalAdministrationList) {
						if (parts[0] === iterator.link) {
							this.globalAdministrationListHeader = iterator.name;
							this.displayGlobalAdministrationListOnly = true;
						}
					}
					for (const iterator of this.administrationList) {
						if (parts[0] === iterator.link) {
							this.administrationListHeader = iterator.name;
							this.displayAdministrationListOnly = true;
						}
						this.currentCommunityURL = '';
						if (parts[0] === 'settings') {
							this.displayFacilitiesAdministrationList = false;
							this.displayCommunityOptionsList = false;
							this.displayAccountingOptionsList = false;
							this.displayRequestsAdministrationList = false;
							this.displaySettingsAdministrationList = true;
							this.displayAdministrationListOnly = true;
							if (parts[1]) {
								for (const settingsIterator of this.settingsAdministrationList) {
									if (parts[1] === settingsIterator.link.split('/')[1]) {
										this.administrationSettingsListHeader = settingsIterator.name;
									}
								}
							} else {
								this.administrationSettingsListHeader = this.settingsAdministrationList[0].name;
							}
						} else if (parts[0] === 'request-admin') {
							this.displayCommunityOptionsList = false;
							this.displayAccountingOptionsList = false;
							this.displayRequestsAdministrationList = true;
							this.displayFacilitiesAdministrationList = false;
							this.displaySettingsAdministrationList = false;
							if (parts[1]) {
								for (const requestsIterator of this.requestsAdministrationList) {
									if (parts[1] === requestsIterator.link.split('/')[1]) {
										this.administrationRequestsListHeader = requestsIterator.name;
									}
								}
							} else {
								this.administrationRequestsListHeader = this.requestsAdministrationList[0].name;
							}
						} else if (parts[0] === 'globalAdministration') {
							this.displayCommunityOptionsList = false;
							this.displayAccountingOptionsList = false;
							this.displayRequestsAdministrationList = false;
							this.displaySettingsAdministrationList = false;
							this.displayGlobalAdministrationListOnly = true;
							this.displayFacilitiesAdministrationList = false;
							if (parts[1]) {
								for (const requestsIterator of this.globalAdministrationList) {
									if (parts[1] === requestsIterator.link.split('/')[0]) {
										this.globalAdministrationListHeader = requestsIterator.name;
									}
								}
							} else {
								this.globalAdministrationListHeader = this.globalAdministrationList[0].name;
							}
						} else if (parts[0] === 'administration') {
							this.displayCommunityOptionsList = false;
							this.displayAccountingOptionsList = false;
							this.displayRequestsAdministrationList = false;
							this.displaySettingsAdministrationList = false;
							this.displayGlobalAdministrationListOnly = false;
							this.displayAdministrationListOnly = true;
							this.displayFacilitiesAdministrationList = false;
							if (parts[1]) {
								for (const requestsIterator of this.administrationList) {
									if (parts[1] === requestsIterator.link.split('/')[1]) {
										this.administrationListHeader = requestsIterator.name;
									}
								}
							} else {
								this.administrationListHeader = this.administrationList[0].name;
							}
						} else {
							this.displayCommunityOptionsList = false;
							this.displayAccountingOptionsList = false;
							this.displayRequestsAdministrationList = false;
							this.displaySettingsAdministrationList = false;
							this.displayFacilitiesAdministrationList = false;
						}
					}
				}
				await this.initNavMenu();
			});
		this.communityList = await this.communityService.getCommunityArray();
		this.communityListHeader = 'My Property Groups';
		this.communityOptionsListHeader = this.communityOptionsList[0].name;
		this.AccountingListHeader = this.accountingOptionsList[0].name;
		this.administrationRequestsListHeader = this.requestsAdministrationList[0].name;
		this.administrationSettingsListHeader = this.settingsAdministrationList[0].name;
		this.ss.url.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (url) => {
			this.urlSegments = url.split('/');
			if (this.urlSegments[1] === 'global-view') {
				this.communityListHeader = 'Global View'
			} else if (this.urlSegments[2]) {
				const selectedCommunity = (await this.communityService.getCommunity());
				this.selectedProjectName = selectedCommunity.name;
				this.selectedProject = this.communityList.filter(obj => {
					return obj.id === selectedCommunity.id
				})[0]
				const pmOptionExist = this.communityOptionsList.find(com=>com.name==='PM Units')
				if (this.selectedProject.isPM && !pmOptionExist) {
					this.communityOptionsList.splice(4, 0, { id: 23, name: 'PM Units', link: 'pm-units', icon: '/assets/icons/community/units.svg' })
				}
				if (!this.selectedProject.isPM) {
					this.communityOptionsList = this.communityOptionsList.filter(com => com.name !== 'PM Units')
				}

				this.communityListHeader = this.selectedProjectName;
				if (this.urlSegments[3]) {
					for (const iterator of this.communityOptionsList) {
						if (iterator.link === this.urlSegments[3]) {
							this.communityOptionsListHeader = iterator.name;
						}
					}
					if (this.urlSegments[3] === 'accounting') {
						this.displayAccountingOptionsList = true;
						if (this.urlSegments[4]) {
							for (const AccIterator of this.accountingOptionsList) {
								if (AccIterator.link === '/accounting/' + this.urlSegments[4]) {
									this.AccountingListHeader = AccIterator.name;
								}
							}
						} else {
							this.AccountingListHeader = this.accountingOptionsList[0].name;
						}
					} else if (this.urlSegments[3] === 'facilities') {
						this.displayAccountingOptionsList = false;
						this.displayFacilitiesAdministrationList = true;
						if (this.urlSegments[4]) {
							for (const AccIterator of this.facilitiesAdministrationList) {
								if (AccIterator.link === '/facilities/' + this.urlSegments[4]) {
									this.administrationFacilitiesListHeader = AccIterator.name;
								}
							}
						} else {
							this.administrationFacilitiesListHeader = this.facilitiesAdministrationList[0]?.name;
						}
					} else {
						this.displayFacilitiesAdministrationList = false;
					}
				} else {
					this.communityOptionsListHeader = this.communityOptionsList[0].name;
					this.displayAccountingOptionsList = false;
					this.displayFacilitiesAdministrationList = false;
				}
			} else {
				this.communityListHeader = 'My Property Groups';
				this.communityOptionsListHeader = this.communityOptionsList[0].name;
				this.AccountingListHeader = this.accountingOptionsList[0].name;
			}
			await this.initNavMenu();
		});
		if (this.ss.hasFullAccess && this.ss.currentUser.isAdmin) {
			this.userService.getUsers();
		}

		this.wl.getWhitelistByUser();
		const whitelist = this.wl.whitelist;

		this.allowOnDocuments = (whitelist !== undefined && whitelist.indexOf(this.ss.currentUser.contact) !== -1);



		this.userService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {

			this.isKaizenEmployee = user.profileType === 'Employee';
			this.isLandlord = user.profileType === 'Landlord';
			this.isTenant = user.profileType === 'Resident';
		});

		this.createdProject = this.communityService.createdProject.subscribe(async (newProject) => {
			if (newProject) {
				this.communityList = await this.communityService.getCommunityArray();
			}
		});
		await this.initNavMenu();
		this.addingMollakNameToPropertyGroupName();
	}

	private addingMollakNameToPropertyGroupName() {

		this.communityList = this.communityList.map(e => ({
			...e,
			name: e.name?.toLowerCase() !== (e.mollakName || "").toLowerCase() ? `${e.name}${e.mollakName ? ` (${e.mollakName}) ` : ''}` : e.name,
			icon: 'fa fa-building fa-lg'
		}))
		this.communityList.unshift({ name: "Global View", icon: 'fa fa-globe fa-lg', });
	}

	public getSubMenu(input: any[], base: string, icon?: string): any[] {
		const items = [];
		input.forEach(item => {
			items.push({
				label: item.name,
				routerLink: `/${base}/${item.link ? item.link : item.id}`,
				icon: icon ? icon : '',
			});
		});
		return items;
	}

	public initNavMenu() {
		if (this.currentUser.isVerified && !this.isContact) {
			this.profileMenuItems = [{
				label: 'Profile',
				routerLink: '/profile',
				visible: this.currentUser.isVerified,
				icon: 'pi pi-user-edit',
			},
			{
				label: 'Sign Out',
				command: () => {
					this.onLogout().then();
				},
				icon: 'pi pi-power-off',
			}];
		} else if (this.isContact && this.showOnPortal) {
			this.profileMenuItems = [{
				label: 'Profile',
				routerLink: '/profile',
				visible: this.currentUser.isVerified,
				icon: 'pi pi-user-edit',
			},
			{
				label: 'Contact Us',
				routerLink: '/contact-us',
				icon: 'pi pi-info-circle',
				visible: (this.isContact && this.showOnPortal)
			}, {
				label: 'Sign Out',
				command: () => {
					this.onLogout().then();
				},
				icon: 'pi pi-power-off',
			}];
		} else {
			this.profileMenuItems = [{
				label: 'Profile',
				routerLink: '/profile',
				visible: this.currentUser.isVerified,
				icon: 'pi pi-user-edit',
			},
			{
				label: 'Sign Out',
				command: () => {
					this.onLogout().then();
				},
				icon: 'pi pi-power-off',
			}];
		}
		this.items = [
			{
				label: 'Home',
				routerLink: this.homeRoute,
				visible: this.isDraftTenant ? false : ((this.currentUser.isApproved && this.isKaizenEmployee) || this.currentUser.isAdmin || !this.isContactHome),
				command: () => this.selectedProject = '',
			},
			{
				label: 'Payments',
				routerLink: this.homeRoute + '/payments',
				visible: this.currentUser.isVerified && this.currentUser.type === 'vc',
				command: () => this.selectedProject = '',
			},
			{
				label: this.communityListHeader,
				visible: false,
				items: [
					{
						label: 'Global View',
						routerLink: '/global-view',
						icon: 'fa fa-globe fa-lg',
						visible: false,
					},
					...this.getSubMenu(this.communityList, 'projects', 'fa fa-building fa-lg'),
				]
			},
			{
				label: this.communityOptionsListHeader,
				visible: (this.currentUser.isApproved && this.isKaizenEmployee || this.currentUser.isAdmin) && this.displayCommunityOptionsList,
				items: this.getSubMenu(this.communityOptionsList, this.currentCommunityURL),
			}, {
				label: this.administrationFacilitiesListHeader,
				visible: !this.displayAdministrationListOnly && (this.currentUser.isApproved && this.isKaizenEmployee || this.currentUser.isAdmin) && this.displayFacilitiesAdministrationList,
				items: this.getSubMenu(this.facilitiesAdministrationList, this.currentCommunityURL),
			}, {
				label: this.AccountingListHeader,
				visible: (this.currentUser.isApproved && this.isKaizenEmployee || this.currentUser.isAdmin) && this.displayAccountingOptionsList,
				items: this.getSubMenu(this.accountingOptionsList, this.currentCommunityURL),
			}, {
				label: 'Administration',
				visible: !this.epcexternalPartyContact && !this.displayCommunityOptionsList && !this.displayAdministrationListOnly && !this.displayGlobalAdministrationListOnly && this.currentUser.isApproved && this.currentUser.isClientAdmin,
				command: () => this.goToAdminPage(),
			}, {
				label: this.administrationListHeader,
				visible: !this.epcexternalPartyContact && !this.displayCommunityOptionsList && this.displayAdministrationListOnly && !this.displayGlobalAdministrationListOnly && this.currentUser.isApproved && (this.isKaizenEmployee || this.currentUser.isAdmin),
				items: this.getSubMenu(this.administrationList, this.currentCommunityURL),
			}, {
				label: this.administrationSettingsListHeader,
				visible: !this.displayCommunityOptionsList && this.displayAdministrationListOnly && !this.displayGlobalAdministrationListOnly && (this.currentUser.isApproved && this.isKaizenEmployee || this.currentUser.isAdmin) && this.displaySettingsAdministrationList,
				items: this.getSubMenu(this.settingsAdministrationList, this.currentCommunityURL),
			}, {
				label: this.administrationRequestsListHeader,
				visible: !this.displayCommunityOptionsList && this.displayAdministrationListOnly && !this.displayGlobalAdministrationListOnly && (this.currentUser.isApproved && this.isKaizenEmployee || this.currentUser.isAdmin) && this.displayRequestsAdministrationList,
				items: this.getSubMenu(this.requestsAdministrationList, this.currentCommunityURL),
			}, {
				label: 'Global Administration',
				visible: !this.displayCommunityOptionsList && !this.displayAdministrationListOnly && !this.displayGlobalAdministrationListOnly && this.currentUser.isAdmin,
				command: () => this.goToGlobalAdminPage(),
			}, {
				label: this.globalAdministrationListHeader,
				visible: !this.displayCommunityOptionsList && !this.displayAdministrationListOnly && this.displayGlobalAdministrationListOnly && this.currentUser.isAdmin,
				items: this.getSubMenu(this.globalAdministrationList, 'globalAdministration'),
			}, {
				label: 'My Units',
				visible: this.isDraftTenant ? false : this.isLandlord,
				routerLink: '/my-units-balances',
			}, {
				label: 'My Property Group',
				visible: this.isDraftTenant ? false : ((this.isTenant || this.isLandlord) && !this.isKaizenEmployee && !this.currentUser.isAdmin),
				items: [{
					label: 'Helpline & FAQ\'s',
					icon: 'fa fa-info-circle fa-fw mr-1',
					routerLink: '/helpline-faq',
				}, {
					label: 'Announcements',
					icon: 'fa fa-newspaper-o fa-fw',
					routerLink: '/community-wall',
				}, {
					label: 'Event Calendar',
					icon: 'fa fa-calendar fa-fw mr-1',
					routerLink: '/event-calendar',
				}, {
					label: 'Property Group Documents',
					icon: 'fa fa-drivers-license-o fa-fw mr-1',
					routerLink: '/community-documents',
				}],
			},
			{
				label: 'My Requests',
				routerLink: '/my-requests',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome)),
			}, {
				label: 'Event Calendar',
				routerLink: '/event-calendar',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome)),
			}, {
				label: 'My Units',
				routerLink: '/my-units',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome)),
			}, {
				label: 'Property Group Documents',
				routerLink: '/community-documents',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome)),
			}, {
				label: 'Helpline & FAQ\'s',
				routerLink: '/helpline-faq',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome)),
			}, {
				label: 'Announcements',
				routerLink: '/community-wall',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome)),
			}, {
				label: 'Lost/Found',
				routerLink: '/my-requests/lostFound',
				visible: this.isDraftTenant ? false : ((this.isLandlord && !this.isKaizenEmployee && !this.currentUser.isAdmin && !this.currentUser.isClientAdmin) || (this.isContact) && (!this.isContactHome) && this.showLostFound),
			},
			{
				label: 'Tenders',
				routerLink: '/oc-tenders',
				visible: this.isDraftTenant ? false : (((this.isContact) && this.isOwnersCommitteeMember && (!this.isContactHome))),
			},
		];
	}

	public goToCommunityArea = (selectedCommunityArea: string) => {
		this.currentCommunityURL = this.currentCommunityURL.replace('/accounting', '');
		if (selectedCommunityArea === 'accounting/old') {
			this.router.navigate([this.currentCommunityURL, 'accounting', 'old']);
		}
		else if (selectedCommunityArea) {
			this.router.navigate([this.currentCommunityURL, selectedCommunityArea]);
		} else {
			this.router.navigate([this.currentCommunityURL]);
		}
	}

	public goToAdminPage = () => {
		this.displayAdministrationListOnly = true;
		this.router.navigate(['administration']);
	}
	public goToGlobalAdminPage = () => {
		this.displayGlobalAdministrationListOnly = true;
		this.router.navigate(['globalAdministration']);
	}
	private async onLogout() {
		await this.destroySSOToken();
		const yf = window.yellowfin
		if (yf) {
			const login = await firstValueFrom(this.yfService.loginToken$)
			await firstValueFrom(this.yfService.destroyLoginToken((login as any)._links.self.href))
			await yf.logoff();
		}
		await this.ss.signout();
	}

	private async destroySSOToken() {
		const sso = await firstValueFrom(this.yfService.currentSSOToken$);
		if (sso) {
			await firstValueFrom(this.yfService.destroySSOToken((sso as any)._links.logout.href));
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(null);
		this.ngUnsubscribe.complete();
		if (this.sub) this.sub.unsubscribe();
		if (this.createdProject) this.createdProject.unsubscribe();
	}
	changeValue() {
		const item = this.selectedProject
		if (this.communityList.length > 0 && item == this.communityList[0])
			this.router.navigate(['/global-view'])
		else
			this.router.navigate([`/projects/${item.link ? item.link : item.id}`])
	}
	profileChangeValue(item: any) {
		const selectedItem = item.value;
		switch (selectedItem.label) {
			case 'Profile':
				this.router.navigate(['/profile']);
				break;
			case 'Contact Us':
				this.router.navigate(['/contact-us']);
				break;
			case 'Sign Out':
				this.onLogout().then();
				break;
			default:
				break;
		}
	}

	async getNotifications() {
		this.notifications = [];
		this.notifications = (await this.notificationsService.getNotifications()).sort(sortBy('notifyTime', true));
		this.recentNotifications = this.notifications.filter(not => not.status === 'new') || [];
		this.oldNotifications = this.notifications.filter(not => not.status !== 'new') || [];
	}

	filterGrouped(e) {
		const query = e.query.toLowerCase();
	
		const filteredGroups = [
			{
				label: 'Contacts',
				value: 'contacts',
				image: '/assets/icons/administration/contacts.svg',
				items: [],
			},
			{
				label: 'Units',
				value: 'units',
				image: '/assets/icons/community/units.svg',
				items: [],
			}
		];
	
		this.contactService.getcontactArray()
			.then(contacts => {
				const conts = (contacts || [])
					.filter(c => {
						return (!!c.displayName && c.displayName.toLowerCase().includes(query)) ||
							(Array.isArray(c.emails) && c.emails.some(email => !!email?.email && email.email.toLowerCase().includes(query))) ||
							(Array.isArray(c.phones) && c.phones.some(phone => !!phone?.number && phone.number.toString().includes(query)));
					})
					.map(contact => {
						return {
							label: contact.displayName,
							value: contact,
							type: 'contact',
						};
					});
				filteredGroups[0].items = conts;
			})
			.then(() => {
				this.unitService.getAllUnitArray()
					.then(_units => {
						const units = (_units || [])
							.filter(u => {
								if (!u.building) return false;
								const bld = (this.buildings || []).find(bld => bld.id === u.building);
								const com = (this.communities || []).find(com => com.id === bld?.community);
								return (u.name && u.name.toLowerCase().includes(query)) ||
									(bld?.name && bld.name.toLowerCase().includes(query)) ||
									(com?.name && com.name.toLowerCase().includes(query)) ||
									(u.name + '/' + bld?.name + '/' + com?.name).toLowerCase().includes(query);
							})
							.map(unit => {
								const bld = (this.buildings || []).find(bld => bld?.id === unit.building);
								const com = (this.communities || []).find(com => com.id === bld?.community);
								return {
									label: unit.name + '/' + bld?.name + '/' + com?.name,
									value: unit,
									type: 'unit',
								};
							});
						filteredGroups[1].items = units;
					})
					.then(() => this.filteredGroups = filteredGroups);
			});
	}

	searchResult($event) {
		const srchItm = $event;
		this.toSearch = $event.label.toString();
		this.selectedProject = 'Global View';
		const url = `global-view/search/${srchItm.type}/${srchItm.value.id}`;
		this.router.navigate([url]);
	}

}
