export const uuidtimeint = ( payload: string ) => {
	const uuidArray = payload.split( '-' );
	const timeString = [
		uuidArray[ 2 ].substring( 1 ),
		uuidArray[ 1 ],
		uuidArray[ 0 ],
	].join( '' );
	return parseInt( timeString, 16 );
};

export const uuidtimestring = ( payload: string ) => {
	return uuidtimeint( payload ).toString();
};

export const uuidtodate = ( payload: string ) => {
	const intTime = uuidtimeint( payload ) - 122192928000000000;
	const intMillisec = Math.floor( intTime / 10000 );
	return new Date( intMillisec );
};
