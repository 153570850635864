
import { Component, OnDestroy, EventEmitter, ChangeDetectorRef, Input, Output, OnChanges } from '@angular/core';
import { v1 as uuid } from 'uuid';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SystemService } from 'src/app/system.service';
import { isDateValid } from '../../../../../utils/datevalidator';
import { Subscription } from 'rxjs';
import { sortBy } from '../../../../../utils/sortby';
import { gettoday, dateToOurDate } from '../../../../../utils/gettoday';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ContactsService } from 'src/app/contacts.service';
import { DocumentService } from 'src/app/document.service';
import { DocFile, DocumentPG  } from '../../../../../models/document.pg.model';
import * as moment from 'moment';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { DocumentType } from '../../../../../models/document.pg.model';
import { AuthService } from 'src/app/auth.service';
import { DocumentTypesService } from 'src/app/documentTypes.service';
import { CommunityService } from 'src/app/community.service';


import { Contact } from '../../../../../models/contacts.models';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';
import { ListerData } from '../document-lister/document-lister.component';


@Component( {
	selector: 'app-document-uploader',
	templateUrl: './document-uploader.component.html',
	styleUrls: [ './document-uploader.component.scss' ]
} )
export class DocumentUploaderComponent implements OnDestroy, OnChanges {
	@Output() hideEvent: EventEmitter<any> = new EventEmitter();
	@Output() uploadDocumentsEvent: EventEmitter<DocumentPG[]> = new EventEmitter();
	private subscription: Subscription;
	public documentTypes: DocumentType[];
	@Input() data: ListerData;
	@Input() display = false;
	@Input( 'needNotificationRange' ) needNotificationRange = false;
	@Input() maxFileSize = this.documentService.MAX_DOCUMENT_SIZE;
	@Input() isAlreadyApproved = false;
	@Input() isLeasingDocuments = false;
	@Input() acceptedFiles=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .csv, .odt";
	public fileList: Array<DocFile & { contentType?: string }> = [];
	private subscriptions: Subscription[] = [];
	private preDocTypeSel;
	allowMultiple = true;
	public showTypes = true;
	public isUploading = false;
	public isValid = true;
	public isContact;
	public community;
	public allProject = [];





	public contactsArrayUploader: Contact[] = [];
	public serviceUploader;


	public bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD' };

	constructor (
		public bsModalRef: BsModalRef,
		public ss: SystemService,
		private contactsService: ContactsService,
		private documentTypesService: DocumentTypesService,
		private documentService: DocumentService,
		private toaster: CustomToastrService,
		private changeDetection: ChangeDetectorRef,
		private as: AuthService,
		private communityService: CommunityService,


		public csUploader: ContactsService
	) {
		if ( window[ 'Cypress' ] ) {
			window[ 'Document-Uploader' ] = this
		}
	}


	


	async ngOnChanges () {

		this.contactsArrayUploader = (await getNonNullNonEmptyValue(this.csUploader.contactArray))?.filter(c=>c.type='vc');

		if ( this.as.user.actorType === 'contact' ) {
			this.isContact = true;
			this.allProject = await this.communityService.getAllCommunities();
		} else {
			this.isContact = false;
		}
		if ( !this.data.refId || !this.data.refType ) throw new Error( 'Please provide refId and refType to the document uploader' );

		if ( !this.data.limitTo ) this.data.limitTo = [];
		if ( !this.data.exclude ) this.data.exclude = [];
		if ( !this.data.community ) this.data.community = '';
		this.allowMultiple = !this.data.isUnique;
		if ( !this.isContact ) {
			this.subscriptions.push( this.ss.documentTypeArray.subscribe( docTypes => {
				this.documentTypes = docTypes.filter( type => {
					if ( this.data.limitTo.length > 0 ) {
						return ( this.data.limitTo?.find( ( limit: string ) => limit === type.id ) );
					}
					if ( this.data.exclude.length > 0 ) {
						return ( !this.data.exclude.find( ( limit: string ) => limit === type.id ) );
					}
					if ( this.data.limitTo.length === 0 && this.data.exclude.length === 0 ) {
						return true;
					}
				} ).sort( sortBy( 'description' ) );
			} ) );
		}
		else {
			this.documentTypesService.getDocumentTypesObservable().subscribe( docTypes => {
				this.documentTypes = docTypes.filter( type => {
					if ( this.data.limitTo.length > 0 ) {
						return ( this.data.limitTo?.find( ( limit: string ) => limit === type.id ) );
					}
					if ( this.data.exclude.length > 0 ) {
						return ( !this.data.exclude.find( ( limit: string ) => limit === type.id ) );
					}
					if ( this.data.limitTo.length === 0 && this.data.exclude.length === 0 ) {
						return true;
					}
				} ).sort( sortBy( 'description' ) );
			} );
		}
		if ( this.data.limitTo.length === 1 ) {
			this.preDocTypeSel = this.data.limitTo[ 0 ];
		}
	}
	ngOnDestroy () {
		if ( this.subscription ) this.subscription.unsubscribe();
	}

	async onSelectFile ( e: any, fileUpload ): Promise<void> {
		const files: FileList = e.currentFiles;

		const filesArray: File[] = Array.from( files );
		if ( files ) {
			if ( !this.allowMultiple ) {
				this.fileList = [];
			}
			// prepare map includes files data buffers

			filesArray.forEach( async file => {

				const docFile: DocFile & { contentType: string } = {
					name: file.name,
					file,
					type: '',
					contentType: file.type,
				};
				if ( this.preDocTypeSel ) {
					docFile.type = this.preDocTypeSel;
					this.onSelectDocumentType( this.preDocTypeSel, docFile );
				}
				this.fileList.push( docFile );
			} );
		}
		fileUpload.clear();
		this.isValid = true;
	}



	async onSelectDocumentType ( id, file ) {
		const doc = this.getDocumentType( id );
		file.type = doc.id;
		file.status = '';
		file.showIssueDate = doc.hasIssueDate;
		file.showExpiryDate = doc.hasExpiryDate;
		file.showIssueExpiryDate = doc.hasIssueDate && doc.hasExpiryDate;
		file.showStartEndtDate = doc.hasStartDate && doc.hasEndDate;
		file.showDueDate = doc.hasDueDate;
		file.showRenewalDate = doc.hasRenewalDate;
		file.docType = doc;
		file.notification_range = 90;

		if ( file.showIssueExpiryDate && doc.id === 'pp' && this.data.refType?.includes( 'contact' ) ) {
			const contact = await this.contactsService.getContactbyId( this.data.refId );
			file.dob = contact?.birthDate;
		} else if ( doc.id === 'project-documents-section-e04' ) {
			file.notification_range = 15;
		}
		for ( const document of this.fileList ) {
			if ( document.showStartEndtDate || document.showIssueExpiryDate ) {
				this.needNotificationRange = true;
			}
		}
	}


	public onContactSelect = ( event, document ) => {
		document.service = event.value;
		document.contactName = this.contactsArrayUploader.filter( contact => contact.id === event.value )[ 0 ].displayName;
		this.serviceUploader = document.service;
	}

	onDeleteDocument = ( index ) => {
		this.fileList.splice( index, 1 );
	}


	onSubmit = async () => {
		if ( this.data.community ) {
			this.community = this.allProject.filter( com => com.id === this.data.community )[ 0 ];
		}
		try {
			this.isUploading = true;
			this.prepareFilesList();
			let uploadedFilesIds: string[];
			if ( this.isValid ) {
				const contactId = this.as.user.contact;
				const contactClient = ( await this.contactsService.contactFetch( contactId ) ).client;
				const uploadedDocs = this.fileList;
				this.fileList.forEach( doc => {
					if ( this.isContact ) {
						doc.document.client = contactClient;
					} else {
						doc.document.client = this.ss.currentUser.client;
					}
					if ( this.isAlreadyApproved ) doc.document.approvalStatus = 'approved';
					if ( doc.document.startDate )
						doc.document.startDate = this.getDate( doc.document.startDate );
					if ( doc.document.endDate )
						doc.document.endDate = this.getDate( doc.document.endDate );
					if ( doc.document.renewalDate )
						doc.document.renewalDate = this.getDate( doc.document.renewalDate );
					if ( doc.document.dueDate )
						doc.document.dueDate = this.getDate( doc.document.dueDate );
					if ( doc.document.issueDate )
						doc.document.issueDate = this.getDate( doc.document.issueDate );
					if ( doc.document.expiryDate )
						doc.document.expiryDate = this.getDate( doc.document.expiryDate );
					if(this.isLeasingDocuments)
						doc.document.approvalStatus='pending'
					doc.document.notificationRange = doc.notification_range;

					doc.document.service = this.serviceUploader;

					doc.document.expiryDate = doc.expiryDate;
					doc.document.issueDate = doc.issueDate;
				} );



				const uploaded = await this.documentService.uploadFiles( this.fileList, this.data.isUnique ) || [];
				this.uploadDocumentsEvent.emit( uploaded );
				this.fileList.forEach( fileDoc => fileDoc.status = 'Uploaded & updated' );
				this.close();
			}
			this.isUploading = false;
			this.isValid = true;
			this.changeDetection.detectChanges();
		} catch ( err ) {
			console.error( err );
			if ( err.message ) this.toaster.error( err.message || 'An error happened' );
			this.fileList.forEach( async fileDoc => {
				fileDoc.status = err;
			} );
			this.isUploading = false;
		}


	}

	prepareFilesList = () => {


		const currentTime = moment().format();

		if ( !this.isValid ) {
			return;
		}

		const letterheadAcceptedTypes = [ 'image/jpeg', 'image/png', 'image/jpg' ];

		for ( const [ index, fileDoc ] of this.fileList.entries() ) {
			fileDoc.status = 'Uploading';

			if ( fileDoc.docType?.hasStartDate && fileDoc.docType?.hasEndDate ) {
				if ( fileDoc.startDate ) { fileDoc.startDate = typeof ( fileDoc.startDate ) !== 'string' ? dateToOurDate( fileDoc.startDate ) : fileDoc.startDate.replace( /-/g, '' ); }
				if ( fileDoc.endDate ) { fileDoc.endDate = typeof ( fileDoc.endDate ) !== 'string' ? dateToOurDate( fileDoc.endDate ) : fileDoc.endDate.replace( /-/g, '' ); }
			}
			if ( fileDoc.docType?.hasRenewalDate ) {
				if ( fileDoc.renewalDate ) { fileDoc.renewalDate = typeof ( fileDoc.renewalDate ) !== 'string' ? dateToOurDate( fileDoc.renewalDate ) : fileDoc.renewalDate.replace( /-/g, '' ); }
			}
			if ( fileDoc.docType?.hasDueDate ) {
				if ( fileDoc.dueDate ) { fileDoc.dueDate = typeof ( fileDoc.dueDate ) !== 'string' ? dateToOurDate( fileDoc.dueDate ) : fileDoc.dueDate.replace( /-/g, '' ); }
			}
			if ( fileDoc.docType?.hasIssueDate && fileDoc.docType?.hasExpiryDate ) {
				if ( fileDoc.issueDate ) { fileDoc.issueDate = typeof ( fileDoc.issueDate ) !== 'string' ? dateToOurDate( fileDoc.issueDate ) : fileDoc.issueDate.replace( /-/g, '' ); }
				if ( fileDoc.expiryDate ) { fileDoc.expiryDate = typeof ( fileDoc.expiryDate ) !== 'string' ? dateToOurDate( fileDoc.expiryDate ) : fileDoc.expiryDate.replace( /-/g, '' ); }
			}

			if ( fileDoc.dob ) { fileDoc.dob = typeof ( fileDoc.dob ) !== 'string' ? dateToOurDate( fileDoc.dob ) : fileDoc.dob.replace( /-/g, '' ); }

			fileDoc.invalidStartDate = undefined;
			fileDoc.invalidEndDate = undefined;
			fileDoc.invalidIssueDate = undefined;
			fileDoc.invalidExpiryDate = undefined;
			fileDoc.invalidDueDate = undefined;
			fileDoc.status = 'Validating...';
			let value = '';
			fileDoc.document = {
				id: uuid(),
				name: fileDoc.name,
				type: fileDoc.type,
				createdAt: currentTime,
				fullPath: '',
				url: '',
				contentType: fileDoc?.contentType || '',
				position: index,
				refId: this.data.refId,
				refType: this.data.refType,
				uploadDate: gettoday(),
				uploadedBy: this.ss.currentUser?.contact || null,
			};



			if ( fileDoc.type === '' ) {
				fileDoc.status = 'Document type not selected';
				this.isValid = false;
				break;
			}

			if ( fileDoc.showStartEndtDate ) {
				value = fileDoc.startDate;
				if ( !isDateValid( value ) ) {
					fileDoc.invalidStartDate = 'Invalid date';
					fileDoc.status = 'Invalid start date';
					this.isValid = false;
					break;
				}
				fileDoc.document.startDate = value;

				value = fileDoc.endDate;
				if ( !isDateValid( value ) ) {
					fileDoc.invalidEndDate = 'Invalid date';
					fileDoc.status = 'Invalid end date';
					this.isValid = false;
					break;
				}
				if ( Number( fileDoc.startDate ) >= Number( value ) ) {
					fileDoc.invalidEndDate = 'Invalid date';
					fileDoc.status = 'End Date must be greater than Start Date';
					this.isValid = false;
					break;
				}
				fileDoc.document.endDate = value;
				if ( fileDoc.showRenewalDate ) {
					value = fileDoc.renewalDate;

					if ( !isDateValid( value ) ) {
						fileDoc.invalidRenewalDate = 'Invalid date';
						fileDoc.status = 'Invalid renewal date';
						this.isValid = false;
						break;
					}
					if ( Number( fileDoc.startDate ) >= Number( value ) ) {
						fileDoc.invalidRenewalDate = 'Invalid date';
						fileDoc.status = 'Renewal Date must be greater than Start Date';
						this.isValid = false;
						break;
					}
					if ( Number( fileDoc.endDate ) >= Number( value ) ) {
						fileDoc.invalidRenewalDate = 'Invalid date';
						fileDoc.status = 'Renewal Date must be greater than End Date';
						this.isValid = false;
						break;
					}
					fileDoc.document.renewalDate = value;
				}
				fileDoc.document.notificationRange = fileDoc.notification_range;
			}

			if ( fileDoc.showIssueExpiryDate ) {
				value = fileDoc.issueDate;
				fileDoc.document.issueDate = value;
				value = fileDoc.expiryDate;
				if ( Number( fileDoc.document.issueDate ) >= Number( value ) ) {
					fileDoc.invalidExpiryDate = 'Invalid date';
					fileDoc.status = 'Expiry Date must be greater than Issue Date';
					this.isValid = false;
					break;
				}
				fileDoc.document.expiryDate = value;
				fileDoc.document.notificationRange = fileDoc.notification_range;

				if ( fileDoc.dob ) {
					value = fileDoc.dob;
					if ( !isDateValid( value ) ) {
						fileDoc.status = 'Invalid DOB';
						this.isValid = false;
						break;
					}
					if ( Number( fileDoc.document.issueDate ) < Number( value ) ) {
						fileDoc.status = 'DOB cannot be after issue date';
						this.isValid = false;
						break;
					}
					fileDoc.document.dob = value;
				}
				fileDoc.document.notificationRange = fileDoc.notification_range;
			}

			if ( fileDoc.showDueDate ) {
				value = fileDoc.dueDate;
				if ( !isDateValid( value ) ) {
					fileDoc.invalidDueDate = 'Invalid date';
					fileDoc.status = 'Invalid due date';
					this.isValid = false;
					break;
				}
				fileDoc.document.dueDate = value;
				fileDoc.document.notificationRange = fileDoc.notification_range;
			} else if ( fileDoc?.showIssueDate ) {
				value = fileDoc.issueDate;
				if ( !isDateValid( value ) ) {
					fileDoc.invalidIssueDate = 'Invalid Date';
					fileDoc.status = 'Invalid Issue Date';
					this.isValid = false;
					break;
				}
				fileDoc.document.issueDate = value;
			}

			// Letterhead can only accept certain file types
			if ( !letterheadAcceptedTypes.includes( fileDoc.contentType ) && fileDoc.type === 'lh' ) {
				fileDoc.status = 'Failed To Upload. Only JPG, JPEG & PNG files are allowed.';
				this.isValid = false;
				break;
			}

			fileDoc.status = 'Validated upload pending';
		}
		if ( this.fileList.some( doc => doc.status !== 'Validated upload pending' ) ) {
			this.toaster.error( 'please select types for all documents' );
		}
	}

	close () {
		this.fileList=[]
		this.display = false;
		this.hideEvent.emit();
	}

	getDocumentType ( id ) {
		for ( let i = 0; i < this.documentTypes.length; i++ ) {
			if ( this.documentTypes[ i ].id === id ) {
				return this.documentTypes[ i ];
			}
		}
	}
	private getDate = ( date: any ) => {
		date = [ date.substring( 0, 4 ), date.substring( 4, 6 ), date.substring( 6, 8 ) ];
		return date[ 0 ] + '-' + date[ 1 ] + '-' + date[ 2 ]
	}
}
