
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { distinctUntilChanged, retry } from 'rxjs/operators';
import { transformProjectToCommunity } from '../../../models/community.models';
import { IMap } from '../../../models/generic.models';
import { SystemService } from './system.service';
import { HttpClient } from '@angular/common/http';
import { arraytomap } from '../../../utils/mapandarray';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { getNonNullNonEmptyValue, getNonNullValue } from 'src/app/utils/getNonNullValue';
import { sortBy } from '../../../utils/sortby';
import { UserService } from './user.service';
import { Project } from '../../../models/project.models';
import { environment } from '../environments/environment';
import { SessionStorageClient, StorageKeys } from './core/utils/session-storage-client';

const BASE_API_URL = environment.apiURL;
const MAX_API_CALL_RETRIES = environment.maxAPICallRetries;

@Injectable( { providedIn: 'root' } )
export class CommunityService {

	public projects: BehaviorSubject<IMap<Project>> = new BehaviorSubject( {} );
	public projectArray: BehaviorSubject<Project[]> = new BehaviorSubject( [] );
	public project: BehaviorSubject<Project> = new BehaviorSubject( null );
	public projectID: BehaviorSubject<string> = new BehaviorSubject( '' );
	public createdProject: BehaviorSubject<boolean> = new BehaviorSubject( false );
	private subs: Subscription[] = [];
	private addendumFireBase = { Geography: '', oldId: '', migratedFrom: '', snagReportId: '' };
	constructor (
		private http: HttpClient,
		private ss: SystemService,
		private userService: UserService,
		private tt: CustomToastrService,
	) {
		if ( window[ 'Cypress' ] ) {
			window[ 'CommunityService' ] = this
		}
		ss.isActive.pipe( distinctUntilChanged() ).subscribe( user => {

			if ( !user ) {
				this.subs.forEach( sub => {
					if ( sub?.unsubscribe ) {
						sub.unsubscribe();
					}

				} );
				return;
			}
			this.communitiesGet();
			this.subs.push( this.projectID.pipe( distinctUntilChanged() ).subscribe( this.communityGet ) );
			this.subs.push( this.ss.url.pipe( distinctUntilChanged() ).subscribe( url => {
				const urlSegments = url.split( '/' );
				if ( urlSegments[ 1 ] === 'projects' || urlSegments[ 1 ] === 'communities' ) {
					// TODO if we want to rely on this solution it is better to use state management replaced of the session storage.
					if(urlSegments[ 2 ] != undefined){
						SessionStorageClient.setItem(StorageKeys.currentCommunityId, urlSegments[ 2 ]);
						SessionStorageClient.setItem(StorageKeys.deniedAccessUrl, url);
						this.projectID.next( urlSegments[ 2 ] );
					}
				}
				else SessionStorageClient.removeItem(StorageKeys.currentCommunityId);
			} ) );
		} );
	}

	public communityCreate = async ( communityPayload: Partial<Project> ) => {
		const oldCommunity = transformProjectToCommunity( communityPayload );
		let createdProjectId:string;
		try {
			createdProjectId=(await firstValueFrom( this.http.post<{result:string}>( `${ BASE_API_URL }/community`, oldCommunity ) ))?.result;
		} catch ( error ) {
			this.tt.error( 'Failed to create the Property Group on the server' );
			console.error( error );
			throw error;
		}
		await this.communitiesGet();
		this.createdProject.next( true );
		return createdProjectId;
	}

	public communityUpdate = async ( communityId: string, communityPayload: Partial<Project> ) => {
		if ( !communityId ) {
			this.tt.warning( 'Property Group update is requested without a Property Group ID' );
			throw new Error( 'Property Group update is requested without a Property Group ID' );
		}
		try {
			const payload = transformProjectToCommunity( communityPayload );
			if ( communityPayload.clientInfo?.id && !payload.client ) payload.client = communityPayload.clientInfo.id;
			const response: any = await firstValueFrom( this.http.put( `${ BASE_API_URL }/community`, payload ) );
			await this.communitiesGet();

		} catch ( error ) {
			this.tt.error( 'Failed to update the Property Group' );
			console.error( error );
			throw error;
		}

		return true;
	}
	public getCurrentUserCommunities = async () => {
		const communities = await Promise.all( ( await this.userService.getCurrentUserCommunityIds() ).map(
			async ( communityId ) => {
				return this.getCommunitybyId( communityId );
			}
		) );
		return communities;
	}
	public getCurrentUserCommunitiesByClient = async () => {
		try {
			const communities = await Promise.all( ( await this.userService.getCurrentUserClientCommunityIds() ).map(
				async ( communityId ) => {
					return this.getCommunitybyId( communityId );
				}
			) );
			return communities;
		} catch ( error ) {
			this.tt.error( `Couldn't fetch user Property Groups by client` );
			throw error;
		}
	}
	public communityDelete = async ( communityId: string ) => {
		if ( !communityId ) {
			this.tt.warning( 'Property Group delete is requested without a Property Group ID' );
			throw new Error( 'Property Group delete is requested without a Property Group ID' );
		}
		try {
			await firstValueFrom( this.http.delete<any>( `${ BASE_API_URL }/community/${ communityId }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to delete the Property Group' );
			console.error( error );
			throw error;
		}
		await this.communitiesGet();
		return true;
	}

	private communityGet = async ( projectId: string ) => {
		this.project.next( null );
		if ( projectId ) {
			const tmp = ( await this.getCommunities() )[ projectId ];
			if ( tmp ) {
				this.project.next( tmp );
			} else {
				this.project.next( await this.communityFetch( projectId ) );
			}
		}
	}

	public communitiesGet = async () => {
		let communities = ( await this.communitiesFetch() ).sort( sortBy( 'name', false, false ) );
		communities = communities.map( c => ( {
			...c,
			nameWithMollak: c.name?.toLowerCase() !== ( c.mollakName || '' ).toLowerCase() ? `${ c.name }${ c.mollakName ? ` (${ c.mollakName }) ` : '' }` : c.name,
		} ) );
		this.projectArray.next( communities );
		this.projects.next( arraytomap( communities ) );
		return true;
	}
	/**
		* fetches community from server, not from from cache or array, use sparingly
		*/
	private communityFetch = async ( communityId: string ) => {
		if ( communityId ) {
			try {
				return await firstValueFrom( this.http.get<Project>( `${ BASE_API_URL }/community/${ communityId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch the Property Group from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Property Group fetch is requested without a Property Group ID' );
			throw new Error( 'Property Group fetch is requested without a Property Group ID' );
		}
	}

	private communitiesFetch = async () => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/community`, ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Group from the server' );
			console.error( error );
			throw error;

		}
	}
	/**
		* gets active community ie the that's id is in the url, cached
		*/
	public getCommunity = async () => await getNonNullNonEmptyValue<Project>( this.project );
	public getCommunityId = async () => await getNonNullValue( this.projectID );
	public getCommunities = async () => await getNonNullNonEmptyValue<IMap<Project>>( this.projects );
	public getCommunityArray = async () => await getNonNullNonEmptyValue<Project[]>( this.projectArray );
	/**
		* gets community from the community array, use only if in /projects** or communities are already fetched, cached
		*/

	public getCommunitybyId = async ( id: string ) => ( ( await this.getCommunities() )[ id ] || ( await this.communityFetch( id ) ) || { id, name: 'N/A' } as Project );

	public notifyLandloards = async ( id: string ) => {
		this.http.post( `${ BASE_API_URL }/community/${ id }/notifyLandlords`, { projectID: id } ).subscribe( ( response: any ) => {
			alert( response.result );
		} );
	}

	// NO CACHE FUNCTIONS
	public getCommunitiesNoCache = async () => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/community`, ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Groups from the server' );
			console.error( error );
			throw error;
		}
	}

	public getCommunityByIdNoCache = async ( communityId ) => {
		try {
			return await firstValueFrom( this.http.get<Project>( `${ BASE_API_URL }/community/${ communityId }`, ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Group from the server' );
			console.error( error );
			throw error;
		}
	}
	public getAllCommunities = async () => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/communities`, ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Group from the server' );
			console.error( error );
			throw error;
		}
	}
	public getCommunitiesByRequestContact = async ( contactId: string ) => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/communityByRequestContact/${ contactId }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Group from the server' );
			console.error( error );
			throw error;
		}
	}
	public getCommunitiesOfLandlordOrTenant = async ( contactId: string ) => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/communitiesOfLandlordOrTenant/${ contactId }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Group from the server' );
			console.error( error );
			throw error;
		}
	}

	public clientCommunitiesFetch = async ( checkActive = false ) => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/clientCommunities`, { params: { checkActive } } ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Groups from the server' );
			console.error( error );
			throw error;

		}
	}

	public getAllProperties = async ( checkActive = false ) => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/propertygroups`, { params: { checkActive } } ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Group from the server' );
			console.error( error );
			throw error;
		}
	}
	public clientPropertiesFetch = async ( checkActive = false ) => {
		try {
			return await firstValueFrom( this.http.get<Project[]>( `${ BASE_API_URL }/clientProperties`, { params: { checkActive } } ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Groups from the server' );
			console.error( error );
			throw error;

		}
	}

	public saveCommunityEmailCapture = async ( communityId: string, emailCapture: string ) => {
		try {
			return await firstValueFrom( this.http.post<Project[]>( `${ BASE_API_URL }/saveEmailCapture`, { communityId, emailCapture } ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch Property Groups from the server' );
			console.error( error );
			throw error;

		}
	}
}

