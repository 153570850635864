
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { SharedModule } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { MessagingService } from '../messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { environment } from '../../environments/environment';

const firebase = environment.firebase;

@NgModule( {
	declarations: [
		MainComponent,
		NavBarComponent,
	],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		NgxMaskModule.forRoot(),
		BsDropdownModule.forRoot(),
		CollapseModule,
		TabsModule.forRoot(),
		MenubarModule,
		MenuModule,
		SharedModule,
		DropdownModule,
		OverlayPanelModule,
		ButtonModule,
		BadgeModule,
		DividerModule,
		AngularFireModule.initializeApp( firebase ),
		AngularFireMessagingModule,
		AutoCompleteModule
	],
	providers: [
		MessagingService,
	],
	exports: [ MainComponent ],
} )
export class CoreModule { }
