import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageClient, StorageKeys } from '../core/utils/session-storage-client';

@Injectable()
export class CommunityIdInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const propertyGroupId = SessionStorageClient.getItem(StorageKeys.currentCommunityId); // Read from local storage
    
    const clonedRequest = req.clone({
      headers: req.headers.set('X-Property-Group-ID', propertyGroupId ?? '')
    });

    return next.handle(clonedRequest);
  }
}
