export const gettoday = ( dateObject = new Date() ) => {
	return dateToOurDate( dateObject );
};

export const getNDaysLater = ( days: number ) => {
	const dateObject = new Date();
	dateObject.setDate( dateObject.getDate() + days );
	return dateToOurDate( dateObject );
};

export const get12MonthsLater = () => {
	const dateObject = new Date();
	dateObject.setFullYear( dateObject.getFullYear() + 1 );
	dateObject.setDate( dateObject.getDate() - 1 );
	return dateToOurDate( dateObject );
};

export const dateToOurDate = ( date: Date ) => {
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	return ( year * 10000 + month * 100 + day ).toString();
};
