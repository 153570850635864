import {endOfYear, startOfYear} from "date-fns";
import { environment } from '../../environments/environment';

const BASE_API_URL = environment.apiURL;

export const fallbackErrorMessage = 'Failed to fetch from the server.'
export const forbiddenErrorMessage = 'Contact your administrator to get access to '
export const defaultDateFormat = 'yyyy-MM-dd'
export const generalLedgerDefaultDateRange = [startOfYear(Date.now()), endOfYear(Date.now())]
export const API_V2_URL = `${BASE_API_URL}/v2`;
