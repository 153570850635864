
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { SystemService } from 'src/app/system.service';
import { environment } from '../../../../environments/environment';


const baseUrl = environment.yellowfinSetting?.url?.replace( '/api', '' )



@Component( {
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: [ './main.component.scss' ],
	animations: [
		trigger( 'routerTransition', [
			transition( '* <=> *', [
				query( ':enter, :leave', style( { position: 'absolute', width: '100%', top: '0', left: '0' } ), { optional: true } ),
				query( ':enter', style( { opacity: 0 } ), { optional: true } ),
				query( ':leave', animateChild(), { optional: true } ),
				group( [
					query( ':leave', [ animate( '.5s ease-out', style( { opacity: 0 } ) ) ], { optional: true } ),
					query( ':enter', [ animate( '.5s ease-out', style( { opacity: 1 } ) ) ], { optional: true } )
				] ),
				query( ':enter', animateChild(), { optional: true } )
			] ),
		] ) ]
} )
export class MainComponent implements OnInit {
	public isEmailVerified = false;
	@ViewChild( 'container', { static: true } ) htmlContainer: ElementRef

	constructor (
		public afAuth: AuthService,
		public ss: SystemService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
	) { }

	async ngOnInit () {
		if ( this.afAuth.user?.actorType !== 'contact' && this.afAuth.user?.actorType !== 'vendor' ) {
			this.afAuth.currentUserData.subscribe( ( user ) => {
				if ( user ) {
					if ( user.isVerified ) {
						this.isEmailVerified = true;
					} else {
						this.isEmailVerified = false;
					}
				}
			} );
		} else {
			this.htmlContainer.nativeElement.style.overflow = 'unset'
			if ( this.afAuth.user.isVerified ) {
				this.isEmailVerified = true;
			} else {
				this.isEmailVerified = false;
			}
		}
	}

	getAnimationState ( outlet: RouterOutlet ) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animateState;
	}

}
