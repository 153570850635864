import { Component, OnInit, Input } from '@angular/core';
import { endOfYear, format, startOfMonth, startOfYear } from 'date-fns';
import { AuditActions } from '../../../../../../models/ledgerGL.models';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { AuditTrailService } from 'src/app/audit-trail.service';
import { SystemService } from 'src/app/system.service';
import { UserService } from 'src/app/user.service';
import { ContactReferenceService } from 'src/app/contact-reference.service';

/**
 * This component is for showing Audit Log of the Ledgers
 */
@Component( {
	selector: 'app-audit-log',
	templateUrl: './audit-log.component.html',
	styleUrls: [ './audit-log.component.scss' ],
} )

export class AuditLogComponent implements OnInit {
	items$: Observable<MenuItem[]>;
	@Input() table = '';
	audits = [];
	@Input() filterFrom: string;
	_filterFrom: Date;
	filterTo: Date;
	startRow = 0;
	endRow = 1000;
	step = 1000;
	public loading$ = new BehaviorSubject( false );
	public parent$ = new BehaviorSubject( '' );
	totalRecords = 0;
	activeTab = '';
	activeTable = '';
	AuditActions = AuditActions;
	keys = Object.keys;
	currentPage = 0;
	userFunctions = [];
	canViewLog = false;
	user;
	@Input() showOnlyTable = false;
	@Input() rowIdAsArray = false;
	@Input() rowId;

	constructor (
		private auditService: AuditTrailService,
		public userService: UserService,
		public ss: SystemService,
		public contactReferenceService: ContactReferenceService,
	) { }

	async ngOnInit () {
		await this.initialize();
	}

	async initialize () {
		this._filterFrom= this.filterFrom ? startOfMonth( new Date(this.filterFrom)): startOfMonth( new Date() )
		if ( !this.showOnlyTable ) {
			this.user = await this.userService.getUser();
			const contact = this.ss.currentUser.contact;
			this.contactReferenceService.getContactReferenceByContact( contact )
				.then( res => {
					res.forEach( cr => {
						if ( cr.function === 'alv' )
							this.canViewLog = true;
					} )
				} );
			this.items$ = combineLatest( [ this.loading$, this.parent$ ] ).pipe( map( ( [ disabled, parent ] ) => {
				this.activeTab = parent;
				return this.getMenuItems( disabled, parent );
			} ) );

			this.filterTo = new Date();
			await this.changeTab();
		} else {
			this.loading$.next( true );
			await this.getLogs( this.table, 0, this.step );
			this.loading$.next( false );
		}
	}

	async changeTab ( table?, parent = '', from = 0, to = this.step ) {
		if ( table ) {
			this.loading$.next( true );
			this.parent$.next( parent );
			this.table = table;
			await this.getLogs( table, from, to );
			this.loading$.next( false );
			this.activeTab = parent;
			this.activeTable = table;
		}
	}

	async getLogs ( table, from, to ) {
		if ( !this.filterTo ) this.filterTo = new Date();
		if ( this.showOnlyTable ) {

			const filterFrom = this._filterFrom ? format( this._filterFrom, 'yyyyMMdd' ) : format( startOfYear( new Date() ), 'yyyyMMdd' );
			const filterTo = this.filterTo ? format( this.filterTo, 'yyyyMMdd' ) : format( endOfYear( new Date() ), 'yyyyMMdd' );
			this.audits = await this.auditService.getAuditTrails( table, from, to, filterFrom, filterTo, this.rowId, this.rowIdAsArray );
		} else {
			this.audits = await this.auditService.getAuditTrails( table, from, to, format( this._filterFrom, 'yyyyMMdd' ), format( this.filterTo, 'yyyyMMdd' ) );
		}
		this.audits = this.audits.map( row => ( { ...row, jsonData: row.row_data } ) );
		this.totalRecords = this.audits.length;
	}

	async loadLogs () {
		await this.changeTab( this.activeTable, this.activeTab, ( this.startRow ), ( this.endRow ) );
	}

	getMenuItems ( disabled, parent ): MenuItem[] {
		return [
			{
				disabled,
				label: 'Unit Financials',
				expanded: parent === 'scs',
				items: [
					{ disabled, label: 'Service Charge Journals', command: () => { this.changeTab( 'ledgerSC', 'scs' ); } },
					{ disabled, label: 'SC Items', command: () => { this.changeTab( 'journalItemSC', 'scs' ); } },
				],
			},
			{
				disabled,
				label: 'Utility Ledger',
				expanded: parent === 'ul',
				items: [
					{ disabled, label: 'UL Journals', command: () => { this.changeTab( 'ledgerUL', 'ul' ); } },
					{ disabled, label: 'UL Items', command: () => { this.changeTab( 'journalItemUL', 'ul' ); } },
				],
			},
			{
				disabled,
				label: 'Purchases',
				expanded: parent === 'purchases',
				items: [
					{ disabled, label: 'Purchases', command: () => { this.changeTab( 'ledgerPU', 'purchases' ); } },
					{ disabled, label: 'Invoices', command: () => { this.changeTab( 'ledgerPUInvoice', 'purchases' ); } },
					{ disabled, label: 'Invoices Items', command: () => { this.changeTab( 'ledgerPUInvoiceItem', 'purchases' ); } },
					{ disabled, label: 'Payments', command: () => { this.changeTab( 'ledgerPUPayment', 'purchases' ); } },
					{ disabled, label: 'Void Payments', command: () => { this.changeTab( 'voidPUPayment', 'purchases' ); } },
					{ disabled, label: 'Accrued Expenses', command: () => { this.changeTab( 'ledgerPUAccruedExpense', 'purchases' ); } },
					{ disabled, label: 'Reversals', command: () => { this.changeTab( 'ledgerPUReversal', 'purchases' ); } },
					{ disabled, label: 'Credit Notes', command: () => { this.changeTab( 'ledgerPUCN', 'purchases' ); } },
					{ disabled, label: 'Credit Note Items', command: () => { this.changeTab( 'ledgerPUCNItem', 'purchases' ); } },
				],
			},
			{
				disabled,
				label: 'Sales',
				expanded: parent === 'sales',
				items: [
					{ disabled, label: 'Sales', command: () => { this.changeTab( 'ledgerSA', 'sales' ); } },
					{ disabled, label: 'Invoices', command: () => { this.changeTab( 'ledgerSAIN', 'sales' ); } },
					{ disabled, label: 'Invoice Items', command: () => { this.changeTab( 'ledgerSAItemIN', 'sales' ); } },
					{ disabled, label: 'Receipts', command: () => { this.changeTab( 'ledgerSARC', 'sales' ); } },
					{ disabled, label: 'Receipt Items', command: () => { this.changeTab( 'ledgerSAItemRC', 'sales' ); } },
					{ disabled, label: 'QO Items', command: () => { this.changeTab( 'ledgerSAItemQO', 'sales' ); } },
					{ disabled, label: 'Reversals', command: () => { this.changeTab( 'ledgerSARV', 'sales' ); } },
					{ disabled, label: 'Credit Notes', command: () => { this.changeTab( 'ledgerSACN', 'sales' ); } },
				],
			},
			{
				disabled,
				label: 'General Ledger',
				expanded: parent === 'gl',
				items: [
					{ disabled, label: 'Manual Journals', command: () => { this.changeTab( 'ledgerML', 'gl' ); } },
					{ disabled, label: 'Void Journals', command: () => { this.changeTab( 'voidGL', 'gl' ); } },
					{ disabled, label: 'Unidentified Payments', command: () => { this.changeTab( 'ledgerUnidentified', 'gl' ); } },
					{ disabled, label: 'Leasing Accounting ', command: () => { this.changeTab( 'ledgerLA', 'gl' ); } },
				],
			},
		];
	}

	async next () {
		this.startRow = this.startRow + this.step;
		this.endRow = this.startRow + this.step;
		await this.loadLogs();
	}

	async prev () {
		this.startRow = this.startRow - this.step;
		this.endRow = this.endRow - this.step;
		await this.loadLogs();
	}

	async reset () {
		this.startRow = 0;
		this.endRow = this.step;
		await this.loadLogs();
	}

	isLastPage (): boolean {
		return !this.audits?.length && this.startRow !== 0;
	}

	isFirstPage (): boolean {
		return this.startRow === 0;
	}
}
