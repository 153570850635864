import { objectify } from '../utils/objectify';

export interface JournalGLFirebase {
	id: string;
	account: string;
	bank: string;
	cheque: string;
	cheque_date: string;
	contact: string;
	cost_center: string;
	description: string;
	ha_reference: object;
	invoice: string;
	journal_date: string;
	migrated: object;
	migrationFile: string;
	old_journal_date: string;
	old_posting_date: string;
	posted_by: string;
	posting_date: string;
	receipt_type: string;
	receipt: string;
	reference?: {
		refId?: string,
		refType?: string,
		refItem?: string
	};
	type: string;
	unit: string;
	isBT: boolean;
	isTF: boolean;
	cashReserve?: string;
	fund?: string;
	collectedBy?: string;
	debit: number;
	credit: number;
	isCleared?: boolean;
}

export type JournalGL = JournalGLTuple;

export interface JournalGLTuple {
	id?: string;
	account: string;
	bank?: string;
	bulkId?: string;
	cheque?: string;
	chequeDate?: string;
	contact?: string;
	landlord?: string;
	costCenter: string;
	description: string;
	haReference: object;
	invoice?: string;
	journalDate: string;
	migrated?: object;
	migrationFile?: string;
	oldJournalDate?: string;
	oldPostingDate?: string;
	periodFrom?: string;
	periodTo?: string;
	postedBy?: string;
	postingDate: string;
	receiptType: string;
	receipt?: string;
	refId: string;
	refType: string;
	refItem?: string;
	type: string;
	unit?: string;
	community: string;
	isBT?: boolean;
	isTF?: boolean;
	cashReserve?: string;
	fund?: string;
	collectedBy?: string;
	debit: number;
	credit: number;
	isCleared?: boolean | null;
	reconciliationId?: string | null;
	transactionId?: string;
	lease?: string,
	invoiceId?: string,
	isTransfer?: boolean;
	fortId?: string,
	voidingStatus?: string,
	isPaid?: boolean,
	paidDate?: string,
}
export const getDefaultJournalGL = () => {
	return objectify( defaultJournalGL );
};

export const defaultJournalGL: Required<JournalGL> = {
	id: '',
	account: '',
	bank: '',
	bulkId: '',
	cheque: '',
	chequeDate: '',
	contact: '',
	landlord: '',
	costCenter: '',
	description: '',
	haReference: {},
	invoice: '',
	journalDate: '',
	migrated: {},
	migrationFile: '',
	oldJournalDate: '',
	oldPostingDate: '',
	periodFrom: '',
	periodTo: '',
	postedBy: '',
	postingDate: '',
	receiptType: '',
	receipt: '',
	refId: '',
	refType: '',
	refItem: '',
	type: '',
	unit: '',
	community: '',
	isBT: false,
	isTF: false,
	cashReserve: '',
	fund: '',
	collectedBy: '',
	debit: 0,
	credit: 0,
	isCleared: false,
	reconciliationId: '',
	transactionId: '',
	lease: '',
	invoiceId: '',
	isTransfer: false,
	fortId: '',
	voidingStatus: '',
	isPaid: false,
	paidDate: '',
};

export const transformJournalGL = (
	fromLedgerGL: JournalGLFirebase
) => {
	return {
		id: fromLedgerGL.id,
		account: fromLedgerGL.account,
		bank: fromLedgerGL.bank,
		chequeDate: fromLedgerGL.cheque_date,
		cheque: fromLedgerGL.cheque,
		contact: fromLedgerGL.contact,
		costCenter: fromLedgerGL.cost_center,
		description: fromLedgerGL.description,
		haReference: fromLedgerGL.ha_reference,
		invoice: fromLedgerGL.invoice,
		journalDate: fromLedgerGL.journal_date,
		migrated: fromLedgerGL.migrated,
		migrationFile: fromLedgerGL.migrationFile,
		oldJournalDate: fromLedgerGL.old_journal_date,
		oldPostingDate: fromLedgerGL.old_posting_date,
		postedBy: fromLedgerGL.posted_by,
		postingDate: fromLedgerGL.posting_date,
		receiptType: fromLedgerGL.receipt_type,
		receipt: fromLedgerGL.receipt,
		refId: fromLedgerGL?.reference?.refId || '',
		refType: fromLedgerGL?.reference?.refType || '',
		refItem: fromLedgerGL?.reference?.refItem || '',
		type: fromLedgerGL.type,
		unit: fromLedgerGL.unit,
		community: '',
		isBT: fromLedgerGL.isBT,
		isTF: fromLedgerGL.isTF,
		cashReserve: fromLedgerGL.cashReserve,
		fund: fromLedgerGL.fund,
		collectedBy: fromLedgerGL.collectedBy,
		debit: fromLedgerGL.debit,
		credit: fromLedgerGL.credit,
		isCleared: fromLedgerGL.isCleared,
		reconciliationId: '',
	} as JournalGLTuple;
};

export const reverseTransformJournalGL = (
	fromLedgerGL: JournalGLTuple | Partial<JournalGLTuple>
) => {
	const transformedJournal: any = {
		id: fromLedgerGL.id,
		account: fromLedgerGL.account,
		cost_center: fromLedgerGL.costCenter || '',
		description: fromLedgerGL.description,
		ha_reference: fromLedgerGL.haReference || {},
		journal_date: fromLedgerGL.journalDate,
		posting_date: fromLedgerGL.postingDate,
		receipt_type: fromLedgerGL.receiptType || '',
		type: fromLedgerGL.type || '',
		isCleared: fromLedgerGL.isCleared,
		reference: { refType: fromLedgerGL.refType, refId: fromLedgerGL.refId },
	};

	if ( fromLedgerGL.bank ) transformedJournal.bank = fromLedgerGL.bank;
	if ( fromLedgerGL.chequeDate ) transformedJournal.cheque_date = fromLedgerGL.chequeDate;
	if ( fromLedgerGL.cheque ) transformedJournal.cheque = fromLedgerGL.cheque;
	if ( fromLedgerGL.contact ) transformedJournal.contact = fromLedgerGL.contact;
	if ( fromLedgerGL.invoice ) transformedJournal.invoice = fromLedgerGL.invoice;
	if ( fromLedgerGL.migrated ) transformedJournal.migrated = fromLedgerGL.migrated;
	if ( fromLedgerGL.migrationFile ) transformedJournal.migrationFile = fromLedgerGL.migrationFile;
	if ( fromLedgerGL.oldJournalDate ) transformedJournal.old_journal_date = fromLedgerGL.oldJournalDate;
	if ( fromLedgerGL.oldPostingDate ) transformedJournal.old_posting_date = fromLedgerGL.oldPostingDate;
	if ( fromLedgerGL.postedBy ) transformedJournal.posted_by = fromLedgerGL.postedBy;
	if ( fromLedgerGL.receipt ) transformedJournal.receipt = fromLedgerGL.receipt;
	if ( fromLedgerGL.refItem ) transformedJournal.reference.refItem = fromLedgerGL.refItem;
	if ( fromLedgerGL.unit ) transformedJournal.unit = fromLedgerGL.unit;
	if ( fromLedgerGL.isBT ) transformedJournal.isBT = fromLedgerGL.isBT;
	if ( fromLedgerGL.isTF ) transformedJournal.isTF = fromLedgerGL.isTF;

	if ( fromLedgerGL.cashReserve ) transformedJournal.cashReserve = fromLedgerGL.cashReserve;
	if ( fromLedgerGL.fund ) transformedJournal.fund = fromLedgerGL.fund;
	if ( fromLedgerGL.collectedBy ) transformedJournal.collectedBy = fromLedgerGL.collectedBy;
	if ( fromLedgerGL.debit ) transformedJournal.debit = fromLedgerGL.debit;
	if ( fromLedgerGL.credit ) transformedJournal.credit = fromLedgerGL.credit;

	return ( transformedJournal as unknown ) as JournalGLFirebase;
};

export const reverseTransformJournalGLVM = (
	fromLedgerGL: JournalGLVM
) => {
	return ( {
		id: fromLedgerGL.id,
		account: fromLedgerGL.account,
		bank: fromLedgerGL.bank,
		chequeDate: fromLedgerGL.chequeDate,
		cheque: fromLedgerGL.cheque,
		contact: fromLedgerGL.contact,
		cost_center: fromLedgerGL.costCenter,
		description: fromLedgerGL.description,
		ha_reference: fromLedgerGL.haReference,
		invoice: fromLedgerGL.invoice,
		journal_date: fromLedgerGL.journalDate,
		migrated: fromLedgerGL.migrated,
		migrationFile: fromLedgerGL.migrationFile,
		old_journal_date: fromLedgerGL.oldJournalDate,
		old_posting_date: fromLedgerGL.oldPostingDate,
		posted_by: fromLedgerGL.postedBy,
		posting_date: fromLedgerGL.postingDate,
		receipt_type: fromLedgerGL.receiptType,
		receipt: fromLedgerGL.receipt,
		reference: {
			refId: fromLedgerGL.refId,
			refType: fromLedgerGL.refType,
			refItem: fromLedgerGL.refItem,
		},
		type: fromLedgerGL.type,
		unit: fromLedgerGL.unit,
		balance: fromLedgerGL.balance,
		balance_general: fromLedgerGL.balanceGeneral,
		balance_reserve: fromLedgerGL.balanceReserve,
		total_balance: fromLedgerGL.balanceTotal,
		clicked: fromLedgerGL.clicked,
		contactName: fromLedgerGL.contactName,
		unitName: fromLedgerGL.unitName,
		ccName: fromLedgerGL.ccName,
		accountName: fromLedgerGL.accountName,
		isBT: fromLedgerGL.isBT,
		isTF: fromLedgerGL.isTF,
		cashReserve: fromLedgerGL.cashReserve,
		fund: fromLedgerGL.fund,
		collectedBy: fromLedgerGL.collectedBy,
		debit: fromLedgerGL.debit,
		credit: fromLedgerGL.credit,
		collectedByName: fromLedgerGL.collectedByName,
	} as unknown ) as JournalGLFirebase;
};

export interface JournalGLVM extends JournalGLTuple {
	previousSCBalance?: number;
	previousPEBalance?: number;
	balance?: number;
	balanceGeneral?: number;
	balanceReserve?: number;
	balanceTotal?: number;
	clicked?: boolean;
	landlordName?: string;
	contactName?: string;
	receiptToDisplay?: string;
	unitName?: string;
	ccName?: string;
	accountName?: string;
	openingDebit?: number;
	openingCredit?: number;
	closingDebit?: number;
	closingCredit?: number;
	currentDebit?: number;
	currentCredit?: number;
	collectedByName?: string;
	isClosing?: boolean;
	isPayment?: boolean;
}

export interface LedgerGLDetails {
	openingBalance: Balance[];
	closingBalance: Balance[];
	openingDebit: number;
	openingCredit: number;
	closingDebit: number;
	closingCredit: number;
	pendingDebit?: number;
	pendingCredit?: number;
	currentDebit?: number;
	currentCredit?: number;
	data: JournalGLVM[];
	pendingData: JournalGLVM[];
}

export interface Balance {
	accountName: string,
	contactName: string,
	description: string,
	debit: number,
	credit: number,
	isBalanceRow: boolean,
}

export interface JournalMLTuple {
	id?: string;
	postedOn: string;
}

export type JournalML = JournalMLTuple;

export const getDefaultJournalML = () => {
	return objectify( defaulJournalML );
};

const defaulJournalML: Required<JournalML> = {
	id: '',
	postedOn: '',
};

export interface ReconciliationJournal {
	id: string,
	refId: string,
	refType: string,
	mollakId: string | null,
	receiptNumber: string | null,
	postedOn: string,
	journalDate?: string,
	unitId: string,
	description: string,
	accounts: string,
	accountIds?: string,
	amount: number,
	accountFunction: string,
	accountsToDisplay?: string,
	cheque?: string,
	bank?: string,
	unit?: string,
	lease?: string,
	isBouncable?: boolean,
	isTransfer?: boolean,
	ledgerLADetails?: any,
	needsToAssignInvoice?: boolean,
	needsBankCharges?: boolean,
	bulkId?: string,
	chequeDate?: string
}

export interface YearEndProcess {
	account: string,
	description: string,
	debit: number,
	credit: number,
	balance: number,
	balanceEntryType: 'debit' | 'credit',
	fund: string,
	ids: string,
}

export interface JournalUnidentified {
	id?: string;
	community: string;
	description: string;
	postedOn: string;
	amount: number;
	paymentDate: string;
	isRefunded: boolean;
	refundDate: string;
	refundedBy?: string;
	identifiedBy: string;
	identifiedAt: string;
	identifiedAmount: number;
	refundedAmount: number;
	paymentAccount: string;
	status: 'posted' | 'fullyIdentified' | 'partiallyIdentified';
	isVoided?:boolean,
	voidingStatus?:JournalUnidentifiedVoidStatus,
}

export enum JournalUnidentifiedVoidStatus {
	Voided = 'voided',
	NotVoided = 'notVoided',
	Pending = 'pending'
}

export enum PaymentStatus {
	fullyIdentified = 'Fully Identified',
	partiallyIdentified = 'Partially Identified',
	posted = 'Posted',
}

export const getDefaultJournalUnidentified = () => {
	return objectify( defaulJournalUnidentified );
};

const defaulJournalUnidentified: Required<JournalUnidentified> = {
	id: '',
	community: '',
	description: '',
	postedOn: '',
	amount: 0,
	paymentDate: '',
	isRefunded: false,
	refundDate: '',
	refundedBy: '',
	identifiedBy: '',
	identifiedAt: '',
	identifiedAmount: 0,
	refundedAmount: 0,
	paymentAccount: '',
	status: 'posted',
	isVoided:false,
	voidingStatus:JournalUnidentifiedVoidStatus.NotVoided	,
};

export interface AuditTabItem {
	label: string,
	table: string,
}

export enum AuditActions {
	'I' = 'Insert',
	'D' = 'Delete',
	'U' = 'Update',
	'T' = 'Truncate',
}

export interface ReportPayloadBody {
	filterFrom: string,
	filterTo: string,
	community: string,
	type: string,
	budgetVersion: string,
	account: string,
	communities:  string[],
	vendor: string,
	showContractedAmount: boolean,
	showPropotionBudget: boolean,
}

export enum LedgerGLRefType {
	purchases = 'ap',
	sales = 'ar',
	general = 'gl',
	holding = 'ha',
	leasing = 'la',
	utility = 'ul',
	other = 'oa',
	petty = 'pc',
	service = 'sc',
	transfer = 'tf'
}
	
