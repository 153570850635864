
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ContactsComponent } from './contacts/components/contacts/contacts.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HttpClientModule } from '@angular/common/http';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouteGuardService } from 'src/app/route.guard.service';
import { httpInterceptorProviders } from './http-interceptors';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DocumentListerModule } from './pages/document-lister/document-lister.module';
import { NgxMaskModule } from 'ngx-mask';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { AuditLogComponent } from './ledgers/components/audit-log/audit-log.component';

const routes: Routes = [
	// UnAuth Routes
	{ path: 'forgot-password', loadChildren: () => import( './forgot-password/forgot-password.module' ).then( m => m.ForgotPasswordModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Forgot Password', animateState: 'Forget Password' } },
	{ path: 'reset-password', loadChildren: () => import( './forgot-password/components/reset-password/reset-password.module' ).then( m => m.ResetPasswordModule ), canActivate: [ RouteGuardService ] },
	{ path: 'signin', loadChildren: () => import( './sign-in/sing-in.module' ).then( m => m.SignInModule ), data: { breadcrumb: 'Sign In', animateState: 'Sign in' } },
	// Common Routes
	{ path: 'global-view', loadChildren: () => import( './pages/global-view/global-view.module' ).then( m => m.GlobalViewModule ), canActivate: [ RouteGuardService ], data: { checkApproved: true, breadcrumb: 'Global View', animateState: 'Global View' } },
	{ path: 'profile', loadChildren: () => import( './user-profile/user-profile.module' ).then( m => m.UserProfileModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'User Profile', animateState: 'User profile' } },
	// Master User Routes
	{ path: 'clients', loadChildren: () => import( './clients/clients.module' ).then( m => m.ClientsModule ), canActivate: [ RouteGuardService ] }, 
	{ path: 'globalAdministration', loadChildren: () => import( './pages/global-administration-homepage/global-administration-homepage.module' ).then( m => m.GlobalAdministrationHomepageModule ), canActivate: [ RouteGuardService ] },
	// Admin Routes
	{ path: 'administration', loadChildren: () => import( './pages/administration-home/administration-home.module' ).then( m => m.AdministrationHomeModule ), canActivate: [ RouteGuardService ] },
	{ path: 'all-tenants', loadChildren: () => import( './pages/tenants-all/tenants-all.module' ).then( m => m.TenantsAllModule ), canActivate: [ RouteGuardService ], data: { checkAdmin: true, breadcrumb: 'All Tenants', animateState: 'All Tenants' } },
	{ path: 'audit-log', component: AuditLogComponent, canActivate: [ RouteGuardService ], data: { breadcrumb: 'Audit Log', animateState: 'Audit Log' } },
	{ path: 'collection', loadChildren: () => import( './collections-v2/collections-v2.module' ).then( m => m.CollectionsV2Module ), data: { breadcrumb: 'CollectionsV2', animateState: 'Collections' } },
	{ path: 'collections', loadChildren: () => import( './collections/collections.module' ).then( m => m.CollectionsModule ), canActivate: [ RouteGuardService ], data: { checkApproved: true, breadcrumb: 'Collections', animateState: 'Collections' } },
	{ path: 'contacts', component: ContactsComponent, loadChildren: () => import( './contacts/contacts.module' ).then( m => m.ContactsModule ), canActivate: [ RouteGuardService ], data: { checkApproved: true, breadcrumb: 'Contacts', animateState: 'Contacts' } },
	{ path: 'dashboard-bi', loadChildren: () => import( './yellowfin-dashboard/yellowfin-dashboard.module' ).then( m => m.YellowfinDashboardModule ), canActivate: [ RouteGuardService ], data: { checkApproved: true, breadcrumb: 'Yellowfin Dashboard', animateState: 'Yellowfin Dashboard' } },
	{ path: 'docs-admin', loadChildren: () => import( './docs-admin/docs-admin.module' ).then( m => m.DocsAdminModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Docs', animateState: 'Docs' } },
	{ path: 'lost-found-admin', loadChildren: () => import( './pages/admin-lost-found-items-list/admin-lost-found-items-list.module' ).then( m => m.AdminLostFoundItemsListModule ), canActivate: [ RouteGuardService ], data: { animateState: 'lost found' } },
	{ path: 'procurement-settings', loadChildren: () => import( './pages/procurement/procurement.module' ).then( m => m.ProcurementModule ), data: { animateState: 'procurement' } },
	{ path: 'projects', loadChildren: () => import( './pages/projects/projects.module' ).then( m => m.ProjectsModule ), canActivate: [ RouteGuardService ], data: { checkApproved: true, breadcrumb: 'Projects', animateState: 'Projects' } },
	{ path: 'projects-mollak', loadChildren: () => import( './pages/project-list-mollak/project-list-mollak.module' ).then( m => m.ProjectListMollakModule ), canActivate: [ RouteGuardService ], data: { checkApproved: true, breadcrumb: 'Project List Mollak', animateState: 'Project List Mollak' } },
	{ path: 'region', loadChildren: () => import( './pages/region/region.module' ).then( m => m.RegionModule ), canActivate: [ RouteGuardService ], data: { checkClientAdmin: true, breadcrumb: 'Region Page', animateState: 'Region Page' } },
	{ path: 'request-admin', loadChildren: () => import( './request-admin/request-admin.module' ).then( m => m.RequestAdminModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Contacts', animateState: 'Contacts' } },
	{ path: 'users', loadChildren: () => import( './users/users.module' ).then( m => m.UsersModule ), canActivate: [ RouteGuardService ], data: { checkAdmin: true, breadcrumb: 'Users', animateState: 'Users' } },
	{ path: 'visitors', loadChildren: () => import( './pages/visitors/visitors.module' ).then( m => m.VisitorsModule ), data: { animateState: 'visitors' } },
	{ path: 'vendor-requests', loadChildren: () => import( './pages/vendor-requests/vendor-requests.module' ).then( m => m.VendorRequestsModule ), data: { animateState: 'vendor requests' } },
	{ path: 'vendor-documents', loadChildren: () => import( './routes/property-management/vendor-documents/vendor-documents.module' ).then( m => m.VendorDocumentsModule ) },
	// CX Routes
	{ path: 'community-wall', loadChildren: () => import( './community-wall/community-wall.module' ).then( m => m.CommunityWallModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Community Wall', animateState: 'Community Wall' } },
	{ path: 'community-documents', loadChildren: () => import( './landlord-documents/my-documents.module' ).then( m => m.MyDocumentsListModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'My Documents List', animateState: 'My documents' } },
	{ path: 'event-calendar', loadChildren: () => import( './event-calendar/event-calendar.module' ).then( m => m.EventCalendarModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Event Calendar', animateState: 'Event Calendar' } },
	{ path: 'helpline-faq', loadChildren: () => import( './helpline-faq/helpline-faq.module' ).then( m => m.HelplineFAQModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Helpline & FAQ\'s', animateState: 'Helpline & FAQ\'s' } },
	{ path: 'my-requests', loadChildren: () => import( './customer-requests/customer-requests.module' ).then( m => m.CustomerRequestsModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'My Requests', animateState: 'My Requests' } },
	{ path: 'my-units-balances', loadChildren: () => import( './my-balance/my-balance.module' ).then( m => m.MyBalanceModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'My Units', animateState: 'Units and balances' } },
	{ path: 'my-units', loadChildren: () => import( './my-units/my-units.module' ).then( m => m.MyUnitsModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'My Units', animateState: 'My Units' } },
	{ path: 'oc-tenders', loadChildren: () => import( './oc-tenders/oc-tenders.module' ).then( m => m.OCTendersModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Tenders', animateState: 'Tenders' } },
	{ path: 'profiles-update', loadChildren: () => import( './pages/profile-update-request/profile-update-request.module' ).then( m => m.ProfileUpdateRequestModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Contacts', animateState: 'Contacts' } },
	{ path: 'welcome-customer', loadChildren: () => import( './welcome/contact-home-page/contact-home-page.module' ).then( m => m.ContactHomePageModule ), canActivate: [ RouteGuardService ] },
	{ path: 'contact-us', loadChildren: () => import( './pages/contact-us/contact-us.module' ).then( m => m.ContactUsModule ), data: { animateState: 'contact us' } },
	// Vendor Routes
	{ path: 'vendor-register', loadChildren: () => import( './vendors/vendor-register/vendor-register.module' ).then( m => m.VendorRegisterModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'Vendor Register', animateState: 'Vendor Register' } },
	{ path: 'vendor-home', loadChildren: () => import( './vendor-portal/vendor-portal.module' ).then( m => m.VendorPortalModule ), canActivate: [ RouteGuardService ], data: { breadcrumb: 'My Requests', animateState: 'My Requests' } },
	// Fallback Routes
	{ path: '', redirectTo: 'signin', pathMatch: 'full' },
	{ path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
];

@NgModule( {
	declarations: [ AppComponent ],
	imports: [
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		HammerModule,
		LayoutModule,
		CoreModule,
		NgxMaskModule,
		FormsModule,
		RouterModule.forRoot( routes, {} ),
		TypeaheadModule.forRoot(),
		CollapseModule.forRoot(),
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		BsDatepickerModule.forRoot(),
		Ng2GoogleChartsModule,
		ToastrModule.forRoot( { timeOut: 9000, positionClass: 'toast-top-right', preventDuplicates: true, newestOnTop: false } ),
		ToastModule,
		DocumentListerModule,
		DialogModule,
		ButtonModule,
	],
	providers: [
		httpInterceptorProviders,
		MessageService,
		BsModalRef
	],
	bootstrap: [ AppComponent ],
} )

export class AppModule { }
