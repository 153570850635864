
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PositionedItem } from '../../../models/generic.models';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class ReceiptTypeService {
	private initialized = false;

	private API = `${ BASE_API_URL }/receiptType`;
	public receiptTypesArray: BehaviorSubject<PositionedItem[]> = new BehaviorSubject( [] );
	constructor (
		private http: HttpClient,
		private authService: AuthService
	) {
		this.initialize();
	}
	private initialize = async () => {
		if ( this.initialized ) return;
		this.initialized = true;
		this.authService.isActive.pipe( distinctUntilChanged() ).subscribe( async ( isActive ) => {
			if ( isActive ) {
				const result: PositionedItem[] = await this.getBy();
				this.receiptTypesArray ??= new BehaviorSubject( [] );
				this.receiptTypesArray.next( result );
				return result;
			}
		} );
	}
	getBy = async ( key?: string, value?: string ): Promise<PositionedItem[]> => {
		const url = `${ this.API }${ ( !!key && !!value ) ? '/' + key + '/' + value : '' }`;
		const result = await firstValueFrom( this.http.get<PositionedItem[]>( url ) );
		return result;

	}

	getAllReceiptTypes = (): Observable<PositionedItem[]> => {
		return this.http.get<PositionedItem[]>( `${ this.API }` );
	}

	post = async ( payload: PositionedItem ): Promise<PositionedItem> => {
		const result = await firstValueFrom( this.http.post<PositionedItem>( this.API, payload ) );
		this.sync( result );

		return result;
	}
	put = async ( id: string | number, payload: Partial<PositionedItem> ): Promise<PositionedItem> => {
		if ( payload.id && payload.id !== id ) throw new Error( 'Id mismatch...' );
		const url = `${ this.API }/${ id.toString().trim() }`;
		const result = await firstValueFrom( this.http.put<PositionedItem>( url, payload ) );
		this.sync( result );

		return result;
	}
	delete = async ( id: string | number ): Promise<PositionedItem> => {

		const url = `${ this.API }/${ id.toString().trim() }`;
		const result = await firstValueFrom( this.http.delete<PositionedItem>( url ) );
		let syncArray = this.receiptTypesArray.getValue();
		syncArray = result.id ? syncArray.filter( a => a.id !== result.id ) : syncArray;
		this.receiptTypesArray.next( syncArray );
		return result;

	}
	private sync = ( targetAccount: PositionedItem ) => {

		let syncArray = this.receiptTypesArray.getValue();
		const ix = syncArray.findIndex( account => account.id === targetAccount.id );
		if ( ix >= 0 ) {
			syncArray[ ix ] = targetAccount;
		} else {
			syncArray = syncArray.concat( targetAccount );
		}
		this.receiptTypesArray.next( syncArray );
	}


}
