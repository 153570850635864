
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class AuditTrailService {
	constructor (
		private http: HttpClient,
		private toasterSrv: CustomToastrService,

	) {
	}

	public async getAuditTrails ( tableName: string, startRow: number, endRow: number, filterFrom: string, filterTo: string, rowId?, rowIdAsArray = false ) {
		try {
			const url = `${ BASE_API_URL }/auditHistory`;
			return firstValueFrom( this.http.post<any[]>( url, { tableName, startRow, endRow, filterFrom, filterTo, rowId, rowIdAsArray } ) );
		} catch ( error ) {
			this.toasterSrv.error( 'An error occured while fetching audit info.' );
			throw error;
		}
	}
}
