
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountTuple } from '../../../models/account.pg.model';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class AccountService {
	private initialized = false;

	private ACCOUNT_API = `${ BASE_API_URL }/account`;
	public accountsArray: BehaviorSubject<AccountTuple[]> = new BehaviorSubject( [] );
	constructor ( private http: HttpClient, private authService: AuthService ) {
		this.initialize();
	}
	private initialize = async () => {
		if ( this.initialized ) return;
		this.initialized = true;

		this.authService.isActive.pipe( distinctUntilChanged() ).subscribe( async ( isActive ) => {
			if ( isActive ) {
				const result: AccountTuple[] = await this.getBy();
				this.accountsArray ??= new BehaviorSubject( [] );
				this.accountsArray.next( result );
			}
		} );
	}
	getBy = async ( key?: string, value?: string ): Promise<AccountTuple[]> => {
		const url = `${ this.ACCOUNT_API }${ ( !!key && !!value ) ? '/' + key + '/' + value : '' }`;
		const result = await firstValueFrom( this.http.get<AccountTuple[]>( url ) );
		return result;

	}
	getAccountsByFunctions = async (): Promise<AccountTuple[]> => {
		const url = `${ this.ACCOUNT_API }Functions`;
		const result = await firstValueFrom( this.http.get<AccountTuple[]>( url ) );
		return result;

	}

	getAllAccounts = (): Observable<AccountTuple[]> => {
		return this.http.get<AccountTuple[]>( `${ this.ACCOUNT_API }` );
	}

	post = async ( payload: AccountTuple ): Promise<AccountTuple> => {
		const result = await firstValueFrom( this.http.post<AccountTuple>( this.ACCOUNT_API, payload ) );
		this.sync( result );

		return result;
	}
	put = async ( id: string | number, payload: Partial<AccountTuple> ): Promise<AccountTuple> => {
		if ( payload.id && payload.id !== id ) throw new Error( 'Id mismatch...' );
		const url = `${ this.ACCOUNT_API }/${ id.toString().trim() }`;
		const result = await firstValueFrom( this.http.put<AccountTuple>( url, payload ) );
		this.sync( result );

		return result;
	}
	delete = async ( id: string | number ): Promise<AccountTuple> => {

		const url = `${ this.ACCOUNT_API }/${ id.toString().trim() }`;
		const result = await firstValueFrom( this.http.delete<AccountTuple>( url ) );
		let syncArray = this.accountsArray.getValue();
		syncArray = result.id ? syncArray.filter( a => a.id !== result.id ) : syncArray;
		this.accountsArray.next( syncArray );
		return result;

	}
	private sync = ( targetAccount: AccountTuple ) => {

		let syncArray = this.accountsArray.getValue();
		const ix = syncArray.findIndex( account => account.id === targetAccount.id );
		if ( ix >= 0 ) {
			syncArray[ ix ] = targetAccount;
		} else {
			syncArray = syncArray.concat( targetAccount );
		}
		this.accountsArray.next( syncArray );
	}


}
