import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd, RouterEvent, Event } from '@angular/router';
import { distinctUntilChanged, filter, map, mergeMapTo, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User, UserFirebase } from '../../../models/users.models';
import { IMap, PositionedItem } from '../../../models/generic.models';
import { maptoarray, arraytomap } from '../../../utils/mapandarray';
import { sortBy } from '../../../utils/sortby';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RouteLoadingIndicatorComponent } from './pages/route-loading-indicator/route-loading-indicator.component';
import { UserService } from './user.service';
import { DocumentTypesService } from './documentTypes.service';
import { AreaAccess } from '../../../models/area-access.model';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';
import { AccountService } from './account.service';
import { ContactTypeService } from './contact-type.service';
import { ContactFunctionService } from './contact-function.service';
import { CurrencyService } from './currency.service';
import { ReceiptTypeService } from './receipt-type.service';
import { EntitlementTypeService } from './entitlement-type.service';
import { UnitTypeService } from './unit-type.service';
import { ProjectCoolingSystemTypeService } from './project-cooling-system-type.service';
import { ServiceCategoryTypeService } from './service-category-type.service';
import { WorkOrderRequestersTypeService } from './work-order-requesters-type.service';
import { WorkOrderStatusesTypeService } from './work-order-statuses-type.service';
import { WorkOrderPrioritiesTypeService } from './work-order-priorities-type.service';
import { WorkOrderContractTypeService } from './work-order-contract-type.service';
import { WorkOrderLocationsTypeService } from './work-order-locations-type.service';
import { WorkOrderKeyaccessTypeService } from './work-order-keyaccess-type.service';
import { JWTPayload } from '../../../models/jwt.model';
import { AuthService } from './auth.service';
import { DocumentType } from '../../../models/document.pg.model';
import { ClientService } from './client.service';
import { CookieService } from 'ngx-cookie-service';
import { UnitSubTypeService } from './unit-sutype.service';
import {UnitSubTypeModel} from "models/unit.models";

@Injectable( {
	providedIn: 'root'
} )
export class SystemService {
	public url = new BehaviorSubject( '' );
	public types = new BehaviorSubject( [] as string[] );
	public receiptTypes = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public receiptTypeArray: Observable<PositionedItem[]>;
	public coaAccounts = new BehaviorSubject( <any> {} );
	public coaAccountArray = new BehaviorSubject( [] as any[] );
	public coaAccountArrayID: Observable<any[]>;
	public contactTypes = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public contactTypeArray: Observable<PositionedItem[]>;
	public contactFunctions = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public contactFunctionArray: Observable<PositionedItem[]>;
	public workOrderRequesters = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public workOrderRequestersArray: Observable<PositionedItem[]>;
	public workOrderStatuses = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public workOrderStatusesArray: Observable<PositionedItem[]>;
	public workOrderPriorities = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public workOrderPrioritiesArray: Observable<PositionedItem[]>;
	public workOrderContractTypes = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public workOrderContractTypesArray: Observable<PositionedItem[]>;
	public workOrderLocations = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public workOrderLocationsArray: Observable<PositionedItem[]>;
	public workOrderKeyAccesses = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public workOrderKeyAccessesArray: Observable<PositionedItem[]>;
	public serviceCategories = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public serviceCategoriesArray: Observable<PositionedItem[]>;
	public bankAccountTypes = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public bankAccountTypeArray: Observable<PositionedItem[]>;
	public currencies = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public currencyArray: Observable<PositionedItem[]>;
	public documentTypes: BehaviorSubject<IMap<PositionedItem>> = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public documentTypeArray: BehaviorSubject<DocumentType[]> = new BehaviorSubject( [] );
	public entitlementTypes = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public entitlementTypeArray: Observable<PositionedItem[]>;
	public projectsCoolingSystem = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public projectsCoolingSystemArray: Observable<PositionedItem[]>;


	public user: JWTPayload;
	public userId: string;
	public unitTypes = new BehaviorSubject( <IMap<PositionedItem>> {} );
	public unitTypeArray: Observable<PositionedItem[]>;
	public unitSubTypes = new BehaviorSubject( <IMap<UnitSubTypeModel>> {} );
	public unitSubTypeArray: Observable<UnitSubTypeModel[]>;
	public isCollapsed = false;
	private subs: Subscription[] = [];
	public navBarAppear = false;
	public isActive = new BehaviorSubject( false );
	public currentUrl = '/';
	public currentUser: User;
	public usersNotfication: boolean;
	public existingUserList: UserFirebase[];

	private isEmployee = false;
	private isResident = false;
	private isShowingLoadingIndicator = false;
	private loadingCount = 0;
	private loadingModalRef: BsModalRef;

	public hasLimitedAccess = false;
	public hasFullAccess = false;
	public areaAccess: AreaAccess = {
		projects: {}
	};
	public areaAccessProjects = new BehaviorSubject( false );
	public userListUpdate = new BehaviorSubject( false );
	public isApprovedFlag = false;

	constructor (
		private router: Router,
		private ms: BsModalService,
		private userService: UserService,
		private accountService: AccountService,
		private documentTypesService: DocumentTypesService,
		private contactTypeService: ContactTypeService,
		private contactFunctionService: ContactFunctionService,
		private currencyService: CurrencyService,
		private receiptTypeService: ReceiptTypeService,
		private entitlementTypeService: EntitlementTypeService,
		private unitTypeService: UnitTypeService,
		private unitSubTypeService: UnitSubTypeService,
		private projectCoolingSystemTypeService: ProjectCoolingSystemTypeService,
		private serviceCategoryTypeService: ServiceCategoryTypeService,
		private workOrderRequestersTypeService: WorkOrderRequestersTypeService,
		private workOrderStatusesTypeService: WorkOrderStatusesTypeService,
		private workOrderPrioritiesTypeService: WorkOrderPrioritiesTypeService,
		private workOrderContractTypeService: WorkOrderContractTypeService,
		private workOrderLocationsTypeService: WorkOrderLocationsTypeService,
		private workOrderKeyaccessTypeService: WorkOrderKeyaccessTypeService,
		public afAuth: AuthService,
		private clientService: ClientService,
		private cookieService: CookieService,
	) {
		userService.isActive.subscribe( this.isActive );
		userService.isActive.pipe( distinctUntilChanged() ).subscribe( active => {
			if ( !active ) return;
			const user = this.userService.firebaseUser.getValue();
			if ( user ) {
				this.userId = user.uid;
				this.user = user;
				this.userService.getUser().then( ( currentUser ) => {
					this.hasFullAccess = false;
					this.hasLimitedAccess = true;
					if ( currentUser ) {
						this.isApprovedFlag = currentUser.isApproved;
						if ( currentUser.isAdmin ) {
							this.hasFullAccess = true;
							this.hasLimitedAccess = false;
						}
					}
					if ( currentUser.isClientAdmin ) {
						this.areaAccess.projects = {};
						this.clientService.getClientCommunities( currentUser.client ).then( communities => {
							communities?.forEach( com => {
								this.areaAccess.projects[ com.id ] = {
									allow: true,
									profile: true,
									units: true,
									analytics: true,
									cost_centers: true,
									buildings: true,
									oa: true,
									budgets: true,
									accounting: true,
									announcements: true,
									documents: true,
									audit: true,
									servicecharges: true
								};
								this.areaAccessProjects.next( true );
								this.hasLimitedAccess = true;
								this.hasFullAccess = false;
							} );
						} );
					} else {
						const accessRightsArray = [ 'pc', 'cm', 'dc', 'co', 'prc', 'poc', 'pra', 'poa', 'ic', 'ip', 'sacr', 'saqa', 'saic', 'saii', 'sacnc', 'sacni', 'sarc', 'sari', 'salc',
							'ir', 'ufcpi', 'ufcpr', 'ufmi', 'ufmr', 'ufscps', 'uffsc', 'ufcra', 'ufpa', 'uftb', 'ufat', 'ufscs', 'glcmj', 'glvh', 'ccmtc', 'ccmr', 'ccmuf', 'ada', 'advd', 'reafsp', 'recf',
							'retb', 'repl', 'rebs', 'revl', 'rejr', 'regfrf', 'regle', 'reacr', 'reps', 'fmr', 'fsgs', 'fscaa', 'fscae', 'fscad', 'glfgl', 'addoc', 'addocg', 'cvanng', 'cvvg', 'rernro', 'ramr', 'acel', 'aadl'
						];
						this.userService.getCurrentUserContact().then( ( contactVal ) => {
							if ( contactVal ) {
								this.userService.getCurrentUserContactReferences().then( payload => {
									const crVal = payload;
									this.areaAccess.projects = {};
									if ( crVal.length > 0 ) {
										for ( const cr of crVal ) {
											if ( cr.function === 'pa' ) {
												this.areaAccess.projects[ cr.refId ] = {
													allow: true,
													profile: true,
													units: true,
													analytics: true,
													cost_centers: true,
													buildings: true,
													oa: true,
													budgets: true,
													accounting: true,
													announcements: true,
													documents: true,
													audit: true,
													servicecharges: true
												};
											} else if ( cr.function === 'pm' || accessRightsArray.includes( cr.function ) ) {
												this.areaAccess.projects[ cr.refId ] = {
													allow: true,
													profile: true,
													units: true,
													analytics: true,
													cost_centers: true,
													buildings: true,
													oa: true,
													budgets: true,
													accounting: false,
													announcements: true,
													documents: true,
													audit: true,
													servicecharges: true
												};
											}

											if ( accessRightsArray.includes( cr.function ) ) {
												this.areaAccess.projects[ cr.refId ].workorders = true;
											}
										}
										this.areaAccessProjects.next( true );
										if ( !this.currentUser.isAdmin ) {
											this.hasLimitedAccess = true;
											this.hasFullAccess = false;
										}
										console.log( 'this.areaAccess', this.areaAccess, this.hasLimitedAccess, this.hasFullAccess );
									}
								} );
							}
						} ).catch( console.error );
					}
				} ).catch( console.error );
			}
		} );

		this.isActive.subscribe( user => {
			let curEvent;
			this.router.events.pipe( filter( e => e instanceof RouterEvent ) ).subscribe( ( event: Event ) => {
				if ( event instanceof NavigationEnd ) {
					this.url.next( event.urlAfterRedirects );
					this.currentUrl = event.urlAfterRedirects;
					if ( this.loadingCount === 0 && this.isShowingLoadingIndicator ) {
						this.loadingModalRef.hide();
						this.isShowingLoadingIndicator = false;
					}
				}
				curEvent = event;
				if( 'url' in event )
					this.currentUrl = event.url;
				if ( event instanceof RouteConfigLoadStart ) this.loadingCount++;
				if ( event instanceof RouteConfigLoadEnd && this.loadingCount > 0 ) this.loadingCount = 0;
				if ( this.loadingCount > 0 && !this.isShowingLoadingIndicator ) {
					this.loadingModalRef = this.ms.show( RouteLoadingIndicatorComponent, { animated: false, backdrop: 'static', keyboard: false, class: 'bg-transparent modal-sm' } );
					this.isShowingLoadingIndicator = true;
					setTimeout( () => {
						this.isShowingLoadingIndicator = true;
						this.ms.hide( this.loadingModalRef.id );
					}, 1000 );
				}

			} );
			if ( !user ) {
				this.subs.forEach( subscription => {
					subscription.unsubscribe();
				} );
				this.navBarAppear = false;
				if (
					( this.currentUrl &&
						this.currentUrl?.length > 1 &&
						this.currentUrl?.split( '/' )[ 1 ] !== 'forgot-password' &&
						this.currentUrl?.split( '/' )[ 1 ] !== 'sign-up' &&
						this.currentUrl?.split( '?' )[ 0 ] !== '/authaction' )
				) {
					this.router.navigate( [ '/signin' ] );
				}
			} else {
				this.userService.isActive.pipe(
					distinctUntilChanged(),
					filter( a => !!a ),
					mergeMapTo( this.userService.user ),
					filter( activeUser => ( activeUser !== null ) ),
				)
					.subscribe( async ( currentUser ) => {
						let enter = true;
						if ( currentUser ) {
							this.currentUser = currentUser;

							if ( !( curEvent instanceof NavigationEnd ) ) {
								enter = false;
							}
							if ( !currentUser ) {
								if ( this.router.url !== '/forms' && this.router.url !== '/profile' && this.router.url !== 'welcome' ) {
									if ( this.afAuth.user.actorType === 'vendor' ) {
										this.router.navigate( [ '/vendor-home' ] );
									} else {
										this.router.navigate( [ '/welcome-customer' ] );
									}
								}
								console.log( 'Profile Not Complete or user not approved' );

							}
							if ( currentUser.profileType?.toLowerCase() === 'employee' ) {
								this.isEmployee = true;
							}
							if ( currentUser.profileType?.toLowerCase() === 'resident' ) {
								this.isResident = true;
							}
							if ( !currentUser.profileType ) {
								this.isEmployee = true;
							}
							if ( enter ) {
							if ( !this.isEmployee && !this.isResident && ( this.currentUrl?.length > 0 ) &&
								( this.currentUrl?.split( '/' )[ 1 ] === 'projects' ||
									this.currentUrl?.split( '/' )[ 1 ] === 'settings' ||
								this.currentUrl?.split( '/' )[ 1 ] === 'announcements' ||
									this.currentUrl?.split( '/' )[ 1 ] === 'users' ||
									this.currentUrl?.split( '/' )[ 1 ] === 'contacts' ||
									( this.currentUrl?.split( '/' )[ 1 ] === 'signin' && this.currentUrl?.split( '/' ).at( 2 ) !== 'register' )

								) ) {
								if ( this.afAuth.user.actorType === 'vendor' ) {
									this.router.navigate( [ '/vendor-home' ] );
								} else {
									this.router.navigate( [ '/welcome-customer' ] );
								}
							} else {
								this.isEmployee = true;
								}
							}

							this.bankAccountTypeArray = this.bankAccountTypes.pipe( map( ( p ) => maptoarray( p ) ) );

							this.subs.push( this.currencyService.getAllcurrencies().
								subscribe( ( p ) => this.currencies.next( arraytomap( p ) ) ) );
							this.currencyArray = this.currencies.pipe( map( ( p ) => maptoarray( p ) ) );

							this.subs.push( this.receiptTypeService.getAllReceiptTypes().
								subscribe( ( p ) => this.receiptTypes.next( arraytomap( p ) ) ) );
							this.receiptTypeArray = this.receiptTypes.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.accountService.getAllAccounts()
								.subscribe( ( p ) => {
									const aA = p.map( a => ( { ...a, fullName: a.id + ':' + a.description } ) ).sort( sortBy( 'id' ) );
									this.coaAccountArray.next( aA );
									this.coaAccounts.next( arraytomap( aA ) );
								} ) );
							this.subs.push( this.documentTypesService.getDocumentTypesObservable().subscribe(
								( p ) => {
									this.documentTypes.next( arraytomap( p ) );
									this.documentTypeArray.next( p );
								} ) );
							this.subs.push( this.contactTypeService.getAllContactTypes().
								subscribe( ( p ) => this.contactTypes.next( arraytomap( p ) ) ) );

							this.contactTypeArray = this.contactTypes.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.contactFunctionService.getAllContactFunctions().
								subscribe( ( p ) => this.contactFunctions.next( arraytomap( p ) ) ) );
							this.contactFunctionArray = this.contactFunctions.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.workOrderRequestersTypeService.getAllWorkOrderRequestersTypes().
								subscribe( ( p ) => this.workOrderRequesters.next( arraytomap( p ) ) ) );
							this.workOrderRequestersArray = this.workOrderRequesters.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.workOrderStatusesTypeService.getAllWorkOrderStatusesTypes().
								subscribe( ( p ) => this.workOrderStatuses.next( arraytomap( p ) ) ) );
							this.workOrderStatusesArray = this.workOrderStatuses.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.workOrderPrioritiesTypeService.getAllWorkOrderPrioritiesTypes().
								subscribe( ( p ) => this.workOrderPriorities.next( arraytomap( p ) ) ) );
							this.workOrderPrioritiesArray = this.workOrderPriorities.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.workOrderContractTypeService.getAllWorkOrderContractTypes().
								subscribe( ( p ) => this.workOrderContractTypes.next( arraytomap( p ) ) ) );
							this.workOrderContractTypesArray = this.workOrderContractTypes.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.workOrderLocationsTypeService.getAllWorkOrderLocationsTypes().
								subscribe( ( p ) => this.workOrderLocations.next( arraytomap( p ) ) ) );
							this.workOrderLocationsArray = this.workOrderLocations.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.workOrderKeyaccessTypeService.getAllWorkOrderKeyAccessesTypes().
								subscribe( ( p ) => this.workOrderKeyAccesses.next( arraytomap( p ) ) ) );
							this.workOrderKeyAccessesArray = this.workOrderKeyAccesses.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.serviceCategoryTypeService.getAllServiceCategoryTypes().
								subscribe( ( p ) => this.serviceCategories.next( arraytomap( p ) ) ) );
							this.serviceCategoriesArray = this.serviceCategories.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.entitlementTypeService.getAllEntitlementTypes().
								subscribe( ( p ) => this.entitlementTypes.next( arraytomap( p ) ) ) );
							this.entitlementTypeArray = this.entitlementTypes.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.unitTypeService.getAllunitTypes().
								subscribe( ( p ) => this.unitTypes.next( arraytomap( p ) ) ) );
							this.unitTypeArray = this.unitTypes.pipe( map( ( p ) => maptoarray( p ) ) );

							this.subs.push( this.unitSubTypeService.getAllunitSubTypes().
								subscribe( ( p ) => this.unitSubTypes.next( arraytomap( p ) ) ) );
							this.unitSubTypeArray = this.unitSubTypes.pipe( map( ( p ) => maptoarray( p ) ) );
							this.subs.push( this.projectCoolingSystemTypeService.getAllprojectCoolingSystemTypes().
								subscribe( ( p ) => this.projectsCoolingSystem.next( arraytomap( p ) ) ) );
							this.projectsCoolingSystemArray = this.projectsCoolingSystem.pipe( map( ( p ) => maptoarray( p ) ) );
							if ( this.isEmployee && ( this.currentUrl?.split( '/' )[ 1 ] === 'forgot-password' ||
								this.currentUrl?.split( '/' )[ 1 ] === 'sign-up' ||
								this.currentUrl?.split( '/' )[ 1 ] === 'signin' ||
								this.currentUrl?.split( '?' )[ 0 ] === '/authaction' ) ) {

								this.router.navigate( [ '/global-view' ] );
							}
							// TODO:  delete me
							this.navBarAppear = true;


						}
					} );
			}
		} );
	}

	public signout = async () => {
		this.subs.forEach( s => { s?.unsubscribe(); } );
		await this.afAuth.signOutUser();
		localStorage.clear();
		await this.router.navigate( [ '/signin' ] );
		this.cookieService.delete( 'JSESSIONID' );
		this.cookieService.delete( 'IPID' );
		window.location.reload();
	}

	public waitForUser = async () => {
		await getNonNullNonEmptyValue( this.userService.user );
	}

	public getAccountbyId = ( accountid: string ): Promise<any> => {
		return new Promise( ( resolve ) => {
			this.subs.push( this.coaAccounts.pipe( take( 1 ) ).subscribe( accounts => {
				let toResolve = { description: 'No account found with the ID' };
				if ( accounts && accounts[ accountid ] ) {
					toResolve = accounts[ accountid ];
				}
				resolve( toResolve );
			} ) );
		} );
	}

	public getUnitTypebyId = ( unitid: string ): Promise<any> => {

		return new Promise( ( resolve ) => {
			this.subs.push( this.unitTypes.pipe( take( 1 ) ).subscribe( types => {
				let toResolve = { description: 'No type found with the ID' };
				if ( types && types[ unitid ] ) {
					toResolve = types[ unitid ];
				}
				resolve( toResolve );
			} ) );
		} );
	}

}
