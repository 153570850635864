import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const jwtTokenConfig = environment.jwtTokenConfig;
const BASE_API_URL = environment.apiURL;
const snagReportSettings = environment.snagReportSettings;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor () {

	}
	intercept ( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
		try {
			if ( req.url.includes( BASE_API_URL ) ) {
				const userData = JSON.parse( localStorage.getItem( jwtTokenConfig.storagePathUI ) );
				const hasAuth = !!req.headers.get( 'Authorization' );
				if ( userData && !hasAuth ) {
					const authReq = req.clone( { setHeaders: { authorization: userData?.token } } );
					return next.handle( authReq );
				} else {
					return next.handle( req );
				}
			} else if ( req.url.includes( snagReportSettings.api.url ) ) {
				return next.handle( req );
			}
		} catch ( error ) {
			console.log( error );
			return next.handle( req );
		}
	}
}
