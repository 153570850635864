<!-- TODO ALI TO REVIEW -->
<p-menubar [model]="items" styleClass="d-flex justify-content-end">
	<ng-template pTemplate="start">
		<div class="p-menuitem p-cursor p-px-3">
			<img [src]="logoUrl" alt="System Logo" height="25" routerLink="/" />
			<p-dropdown dropDownIcon="pi pi-user nav-user" panelStyleClass="dropdown-override" styleClass="dropdown-override"
				[resetFilterOnHide]="true"
				emptyFilterMessage="No community found"
				data-cy="community"
				[options]="communityList"
				optionLabel="name"
				placeholder="Global View"
				[(ngModel)]="selectedProject"
				[filter]="true"
				[filterBy]="'name'"
				(onChange)="changeValue()"
				*ngIf="
					!epcexternalPartyContact &&
					!!currentUser &&
					((currentUser.isApproved && isKaizenEmployee) || currentUser.isAdmin)
				"
			>
				<ng-template let-item pTemplate="selectedItem">
					<i class="selected-Community">{{ item?.name }}</i>
					</ng-template>
					<ng-template let-object pTemplate="item">
					<i class="{{ object.icon }} icon"></i>
					<i class="selected-Community">{{ object?.name }}</i>
					</ng-template>
			</p-dropdown>
		</div>
	</ng-template>

	<div class="p-grid p-col-12 p-rightItems">
		<div
			*ngIf="
				!epcexternalPartyContact &&
				!!currentUser &&
				((currentUser.isApproved && isKaizenEmployee) || currentUser.isAdmin)
			"
			class="p-col-12 p-md-7 p-pb-0"
		>
			<p-autoComplete
				[group]="true"
				[suggestions]="filteredGroups"
				(completeMethod)="filterGrouped($event)"
				placeholder="Search..."
				[(ngModel)]="toSearch"
				(onSelect)="searchResult($event)"
				dropdown="true"
				dropdownMode="current"
				dropdownIcon="pi pi-search"
				styleClass="flex flex-row justify-content-center"
				class="searchIcon"
			>
				<ng-template let-group pTemplate="group">
					<div *ngIf="!!group.items.length" class="p-d-flex p-ai-center pl-3">
						<img src="{{ group.image }}" style="width: 20px" />
						&nbsp;&nbsp;
						<span><b>{{ group.label }}</b></span>
						</div>
						</ng-template>
						<ng-template let-item pTemplate="item">
					<div *ngIf="item.type === 'contact' && viewContacts" style="padding: 0.5rem 0 0.5rem 4rem">
						{{ item.label }}
						<span *ngIf="item.value.vip" class="vip-badge">Restricted Unit</span>
					</div>
					<div *ngIf="item.type === 'unit' && viewUnits" style="padding: 0.5rem 0 0.5rem 4rem">
						{{ item.label }}
					</div>
					</ng-template>
					</p-autoComplete>
					</div>

		<p-overlayPanel #op [showCloseIcon]="true" [style]="{ width: '400px', 'max-height': '85vh' }" [baseZIndex]="10000"
			[showCloseIcon]="false">
			<ng-template pTemplate>
				<div>
					<h5 *ngIf="recentNotifications?.length">Recent</h5>
					<p-divider *ngIf="recentNotifications?.length"></p-divider>
					<div *ngFor="let notification of recentNotifications">
						<a [href]="notification.url" class="a-style">
							<div [ngClass]="{
																highlight:
																	notification.status === 'new' ||
																	notification.status === 'seen'
															}">
								<div class="notificationIcon float-left p-pt-2 p-pl-1">
									<img style="height: 45px" [src]="notification.icon" />
								</div>
								<div class="p-pt-1 p-pl-1">
									<h6>{{ notificationTitle[notification.title] }}</h6>
									<div class="notificationBody">{{ notification.body }}</div>
									</div>
									<p-divider></p-divider>
									</div>
									</a>
									</div>
									</div>
									<div>
										<h5 *ngIf="oldNotifications?.length">Older Notifications</h5>
										<p-divider *ngIf="oldNotifications?.length"></p-divider>
										<div *ngFor="let notification of oldNotifications">
											<a [href]="notification.url" class="a-style">
							<div [ngClass]="{
																highlight:
																	notification.status === 'new' ||
																	notification.status === 'seen'
															}">
								<div class="notificationIcon float-left p-pt-2 p-pl-1">
									<img style="height: 45px" [src]="notification.icon" />
								</div>
								<div class="p-pt-1 p-pl-1">
									<h6 class="p-title">
										{{ notificationTitle[notification.title] }}
									</h6>
									<div class="notificationBody">{{ notification.body }}</div>
									</div>
									<p-divider></p-divider>
									</div>
						</a>
					</div>
					</div>
					<div *ngIf="!recentNotifications?.length && !oldNotifications?.length">
						No Notifications
					</div>
					</ng-template>
					</p-overlayPanel>

		<span class="p-pr-3" *ngIf="isContact || isVendor">
			<button data-cy="notifications" type="text" pButton pBadge [value]="notificationsCount" icon="pi pi-bell"
				(click)="op.toggle($event); getNotifications()"></button>
			</span>
		<div class="p-col-5">
			<p-dropdown #item dropDownIcon="pi pi-user nav-user" panelStyleClass="dropdown-override"
				styleClass="dropdown-override" [placeholder]="contact?.displayName ? contact?.displayName : userEmail"
				[options]="profileMenuItems"
				(onChange)="profileChangeValue($event)"
			>
				<ng-template let-object pTemplate="item">
					<i class="{{ object.icon }} icon"></i>
					<i class="selected-profile-item">{{ object.label }}</i>
					</ng-template>
					</p-dropdown>
					</div>
	</div>
</p-menubar>
