import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { RequestUrl } from "../../../models/request.models";
import { NotificationsService } from './notifications.service';


@Injectable()
export class MessagingService {

	currentMessage = new BehaviorSubject( null );
	public messageSub: Subscription;
	public notificationsCount: BehaviorSubject<number> = new BehaviorSubject<number>( 0 );
	constructor (
		private angularFireMessaging: AngularFireMessaging,
		private router: Router,
		private zone: NgZone,
		private notificationsService: NotificationsService, ) {
		this.syncCreateCache();
	}

	/**
		* request permission for notification from firebase cloud messaging
		*
		* @param userId userId
		*/
	requestPermission () {
		return this.angularFireMessaging.requestToken;
	}

	/**
		* hook method when new notification received in foreground
		*/
	async receiveMessage () {
		this.messageSub = this.angularFireMessaging.messages.subscribe(
			async ( payload: any ) => {
				if ( payload.data ) {
					this.deskNotification( payload.data );
					const count = await this.notificationsService.getUnseenNotificationsCount();
					this.notificationsCount.next( count );
				}
			} );
	}

	/**
		* Unsubscribe message on logout
		*/
	private unsubscribeReceiveMessage () {
		if ( this.messageSub ) {
			this.messageSub.unsubscribe();
		}
	}

	/**
		* Delete token from Cloud server
		* @param token
		*/
	deleteTokenRequest ( token: string ) {
		this.unsubscribeReceiveMessage();
		return this.angularFireMessaging.deleteToken( token )
	}

	deskNotification ( data: any ) {
		if ( data.custom ) return;

		if ( Notification.permission == 'granted' ) {
			if ( data ) {
				const options: NotificationOptions = {
					body: data.body,
					dir: "ltr",
					icon: data.icon,
					data: data
				};
				const notification = new Notification( data.title, options );
				notification.onclick = () => {
					if ( data.request_id ) {
						this.zone.run( () => {
							this.router.navigate( [ `${ RequestUrl[ data.requestType ] }/${ data.request_id }` ] );
						} );
					}
				};
				notification.onshow = function () { };
			}
		} else {
			Notification.requestPermission()
				.then( v => {
				} )
				.catch( error => console.error )
		}
	}
	private syncCreateCache = async () => {
		const count = await this.notificationsService.getUnseenNotificationsCount();
		this.notificationsCount.next( count );
	}
}
