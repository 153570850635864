import * as moment from 'moment';

export const dateFormat = 'YYYYMMDD';

export const isDateValid = ( payload: any ) => {
	if ( !payload ) return false;
	if ( payload.toString() ) payload = payload.toString();
	if ( payload.length < 8 ) return false;
	if ( !moment( payload, dateFormat ).isValid() ) return false;
	return true;
}
