import { Injectable } from '@angular/core';
import { uuid } from '../../../utils/uuid';

@Injectable({
  providedIn: 'root'
})
export class AppTracingService {
  private readonly trackingInfo: {
    sessionId: string;
    clientPlatform: string;
    clientVersion: string;
  };

  constructor() {
    this.trackingInfo = {
      sessionId: uuid(),
      clientPlatform: 'web',
      clientVersion: '1.0.0',
    };
  }

  getTrackingInfo() {
    return this.trackingInfo;
  }
}
