import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '../../../models/notifications.model';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { CustomMessageService } from './shared/services/customPriemngMessage.service';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class NotificationsService {
	constructor (
		private http: HttpClient,
		private messageService: CustomMessageService,
	) { }

	public getNotifications = async () => {
		try {
			const contactNotifications = await firstValueFrom( this.http.get<Notification[]>( `${ BASE_API_URL }/notifications` ) );
			return contactNotifications;
		} catch ( error ) {
			this.messageService.error( { sticky: true, message: 'Failed to fetch the notifications from the server' } );
			console.error( error );
			throw error;
		}
	}

	public getUnseenNotificationsCount = async () => {
		try {
			const unseenNotificationsCount = await firstValueFrom( this.http.get<any>( `${ BASE_API_URL }/unseenNotificationsCount` ) );
			return unseenNotificationsCount.count || 0;
		} catch ( error ) {
			this.messageService.error( { sticky: true, message: 'Failed to fetch the new notifications count from the server' } );
			console.error( error );
			throw error;
		}
	}
}
