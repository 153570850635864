
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { IMap } from '../../../models/generic.models';
import { HttpClient } from '@angular/common/http';
import { SystemService } from './system.service';
import { CommunityService } from './community.service';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { distinctUntilChanged, retry, tap, map } from 'rxjs/operators';
import { maptoarray, arraytomap } from '../../../utils/mapandarray';
import { sortBy } from '../../../utils/sortby';
import { BuildingPG } from '../../../models/buildings.pg.model';
import { environment } from '../environments/environment';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';

const BASE_API_URL = environment.apiURL;
const MAX_API_CALL_RETRIES = environment.maxAPICallRetries;

// TO-DO SQL injection
@Injectable( {
	providedIn: 'root'
} )
export class BuildingService {
	private BUILDING_API = `${ BASE_API_URL }/building`;
	private MULTI_BUILDING_API = `${ BASE_API_URL }/buildings`;
	public buildings: BehaviorSubject<IMap<BuildingPG>> = new BehaviorSubject( {} );
	public buildingArray: BehaviorSubject<BuildingPG[]> = new BehaviorSubject( [] );
	public building: BehaviorSubject<BuildingPG> = new BehaviorSubject( {} as BuildingPG );
	public buildingID: BehaviorSubject<string> = new BehaviorSubject( '' );
	private allbuildings: BehaviorSubject<IMap<BuildingPG>> = new BehaviorSubject( {} );
	private subs: Subscription[] = [];
	private mainSub: Subscription;

	constructor (
		private http: HttpClient,
		private ss: SystemService,
		private communityService: CommunityService,
		private tt: CustomToastrService,
	) {
		this.mainSub = ss.isActive.pipe( distinctUntilChanged() ).subscribe( user => {

			if ( !user ) {
				this.subs.forEach( sub => {
					if ( sub?.unsubscribe ) {
						sub.unsubscribe();
					}
				} );
				return;
			}


			this.subs.push( communityService.projectID.pipe( distinctUntilChanged() ).subscribe( this.communitybuildingsGet ) );
			this.subs.push( this.buildingID.pipe( distinctUntilChanged() ).subscribe( this.buildingGet ) );
			this.subs.push( this.ss.url.pipe( distinctUntilChanged() ).subscribe( url => {
				const urlSegments = url.split( '/' );
				if ( urlSegments[ 3 ] === 'buildings' ) {
					const uID = urlSegments[ 4 ] || '';
					this.buildingID.next( uID );
				}
			} ) );
		} );

	}

	public buildingCreate = async ( buildingPayload: Partial<BuildingPG> ) => {

		let newBuilding = null;
		try {
			newBuilding = await firstValueFrom( this.http.post<BuildingPG>( this.BUILDING_API, buildingPayload ) );
			this.communitybuildingsGet();
			return newBuilding;
		} catch ( error ) {
			this.tt.error( 'Failed to create the Property Subgroup on the server' );
			console.error( error );
			throw error;
		}
	}

	public buildingUpdate = async ( buildingId: string, buildingPayload: Partial<BuildingPG> ) => {

		if ( !buildingId ) {
			this.tt.warning( 'Property Group update is requested without a PropertySubgroup ID' );
			throw new Error( 'Property Group update is requested without a PropertySubgroup ID' );
		}

		try {
			await firstValueFrom( this.http.put( this.BUILDING_API, buildingPayload ) );
			this.communitybuildingsGet();
			return true;
		} catch ( error ) {
			this.tt.error( 'Failed to update the Property Subgroup' );
			console.error( error );
			throw error;
		}
	}
	public buildingDelete = async ( buildingId: string ) => {

		if ( !buildingId ) {
			this.tt.warning( 'Property Subgroup delete is requested without a Property Group ID' );
			throw new Error( 'Property Subgroup delete is requested without a Property Group ID' );
		}

		try {
			await firstValueFrom( this.http.delete( `${ this.BUILDING_API }/${ buildingId }` ) );
			this.communitybuildingsGet();
		} catch ( error ) {
			this.tt.error( 'Failed to delete the Property Subgroup' );
			console.error( error );
			throw error;
		}

		await this.communitybuildingsGet();
		return true;

	}

	public communitybuildingsGet = async ( communityId?: string ) => {
		this.buildingArray.next( [] );
		this.buildings.next( null );
		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		const buildings = await this.communitybuildingsFetch( communityId );
		this.buildingArray.next( buildings );
		this.buildings.next( arraytomap( buildings.sort( sortBy( 'name', false, false ) ) ) );
		return true;

	}

	private communitybuildingsFetch = async ( communityId: string ) => {
		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		if ( communityId ) {
			try {
				return await firstValueFrom( this.http.get<BuildingPG[]>( this.MULTI_BUILDING_API, { params: { community: communityId } || null } ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch Property Subgroups from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Property Subgroups fetch is requested without a Property Group ID' );
			throw new Error( 'Property Subgroups fetch is requested without a Property Group ID' );
		}

	}

	private buildingGet = async ( buildingId: string ) => {

		if ( !buildingId ) {
			this.building.next( null );
			return;
		}
		const buildings = this.getbuildings();
		const building = ( await buildings[ buildingId ] ) || ( await this.buildingFetch( buildingId ) );
		this.building.next( building );
		return true;

	}

	public buildingFetch = async ( buildingId: string ) => {

		if ( buildingId ) {
			try {
				return await firstValueFrom( this.http.get<BuildingPG[]>( this.MULTI_BUILDING_API, { params: { id: buildingId } || null } ).pipe( retry( MAX_API_CALL_RETRIES ) ).pipe( map( buildings => buildings[ 0 ] || <BuildingPG> {} ) ) );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch Property Subgroup from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Property Subgroup fetch is requested without a Property Subgroup ID' );
			throw new Error( 'Property Subgroup fetch is requested without a Property Subgroup ID' );
		}

	}

	public getAllbuildings = async (): Promise<IMap<BuildingPG>> => {

		if ( Object.values( this.allbuildings.getValue() ).length > 0 ) return this.allbuildings.getValue();
		try {
			return await firstValueFrom( this.http.get<BuildingPG[]>( this.MULTI_BUILDING_API ).pipe( retry( MAX_API_CALL_RETRIES ), map( res => arraytomap( res ) ), tap( res => ( this.allbuildings.next( res ) ) ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch all Property Subgroups from the server' );
			console.error( error );
			throw error;
		}
	}
	public getbuildingsArrayByCommunityId = async ( id: string ): Promise<BuildingPG[]> => {
		return maptoarray( await this.getAllbuildings() ).filter( building => building.project === id ).sort( sortBy( 'name', false, false ) );
	}
	public getbuildingsByCommunityId = async ( id: string ): Promise<IMap<BuildingPG>> => {
		return arraytomap( await this.getbuildingsArrayByCommunityId( id ) );
	}
	public getbuilding = async () => await getNonNullNonEmptyValue<BuildingPG>( this.building );
	public getbuildings = async () => await getNonNullNonEmptyValue<IMap<BuildingPG>>( this.buildings );
	public getbuildingsArray = async () => await getNonNullNonEmptyValue<BuildingPG[]>( this.buildingArray );
	public getBuildingById = async ( id: string ): Promise<BuildingPG> => ( await this.buildingFetch( id ) as BuildingPG );

	public getBuildingsByCommunityNoCache = async ( communityId: string ) => {
		if ( !communityId ) communityId = await this.communityService.getCommunityId();
		if ( communityId ) {
			try {
				return await firstValueFrom( this.http.get<BuildingPG[]>( this.MULTI_BUILDING_API, { params: { community: communityId } || null } ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch Property Subgroups from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Property Subgroups fetch is requested without a Property Group ID' );
			throw new Error( 'Property Subgroups fetch is requested without a Property Group ID' );
		}
	}

	public getBuildingNoCache = async ( buildingId: string ) => {
		if ( buildingId ) {
			try {
				return await firstValueFrom( this.http.get<BuildingPG>( this.MULTI_BUILDING_API, { params: { id: buildingId } || null } ).pipe( retry( MAX_API_CALL_RETRIES ) ).pipe( map( buildings => buildings[ 0 ] || <BuildingPG> {} ) ) );
			} catch ( error ) {
				this.tt.error( 'Failed to fetch Property Subgroup from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.tt.warning( 'Property Subgroup fetch is requested without a Property Subgroup ID' );
			throw new Error( 'Property Subgroup fetch is requested without a Property Subgroup ID' );
		}
	}
}
