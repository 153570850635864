
/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth-interceptor';
import { ResponseDataInterceptor } from './response-data-interceptor';
import { TracingInterceptor } from "./tracing-interceptor";
import { ErrorInterceptor } from './error.interceptor';
import { CommunityIdInterceptor } from './community-id.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: TracingInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ResponseDataInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: CommunityIdInterceptor, multi: true },
];
