
import { ClientService } from 'src/app/client.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserProfilePostRequest } from '../../../models/users.models';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, retry, map, tap } from 'rxjs/operators';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { getNonNullNonEmptyValue, getNonNullValue } from 'src/app/utils/getNonNullValue';
import { IMap } from '../../../models/generic.models';
import { arraytomap, maptoarray } from '../../../utils/mapandarray';
import { Contact, ContactTuple } from '../../../models/contacts.models';
import { ContactReference } from '../../../models/contact-reference.models';
import { reverseTransformUnit, Unit } from '../../../models/unit.pg.model';
import { AuthService } from './auth.service';
import { JWTPayload } from '../../../models/jwt.model';
import { ClientSettings, ClientVM } from '../../../models/client.model';
import { environment } from '../environments/environment';
import { CustomMessageService } from './shared/services/customPriemngMessage.service';

const BASE_API_URL = environment.apiURL;
const MAX_API_CALL_RETRIES = environment.maxAPICallRetries;

@Injectable( {
	providedIn: 'root'
} )
export class UserService {

	public user: BehaviorSubject<User> = new BehaviorSubject( null );
	public users: BehaviorSubject<IMap<User>> = new BehaviorSubject( {} );
	public userArray: BehaviorSubject<User[]> = new BehaviorSubject( [] );
	public currentUserContact: BehaviorSubject<Contact> = new BehaviorSubject( null );
	private fbIsActive = new BehaviorSubject( false );
	public isActive = new BehaviorSubject( false );
	public firebaseUser: BehaviorSubject<JWTPayload> = new BehaviorSubject( null );
	public userContactReferenceArray: BehaviorSubject<ContactReference[]> = new BehaviorSubject( null );
	private allUsersFetched = false;
	constructor (
		private aa: AuthService,
		private http: HttpClient,
		private tt: CustomToastrService,
		private clientService: ClientService,
		private messageService: CustomMessageService,
	) {
		if ( window[ 'Cypress' ] ) {
			window[ 'UserService' ] = this
		}
		this.aa.verifyUserToken();

		this.aa.currentUserData.
			subscribe(
				user => {
					this.firebaseUser.next( user );
					if ( this.fbIsActive.getValue() !== ( !!user ) ) this.fbIsActive.next( !!user );
				} );



		this.fbIsActive.pipe( distinctUntilChanged() ).subscribe( active => {
			if ( !active ) {
				this.fbIsActive.next( false );
				return;
			}
			this.currentUserFetch().then( () => this.currentUserContractFetch() ).
				then( () => this.currentContactReferencesFetch() );
		} );
	}
	public getUser = async () => await getNonNullNonEmptyValue<User>( this.user );
	public createUser = async ( email: string ) => {
		return await firstValueFrom( this.http.post<User>( `${ BASE_API_URL }/users`, { email } ) );
	}

	public createUserWithPassword = async ( email: string, password: string ) => {
		return await firstValueFrom( this.http.post<User>( `${ BASE_API_URL }/users`, { email, password } ) );
	}
	public createUserWithPasswordAndClient = async ( email: string, password: string, client: string ) => {
		return await firstValueFrom( this.http.post<User>( `${ BASE_API_URL }/approvedUser`, { email, password, client } ) );
	}
	
	private syncUpdateCache = ( id: string, updatedUser: User ) => {
		const users = this.users.getValue();
		if ( Object.keys( users ) ) {
			users[ id ] = updatedUser;
			this.users.next( users );
			this.userArray.next( maptoarray( users ) );
		}
		if ( this.user.getValue().id === id ) this.user.next( updatedUser );
	}
	public updateUser = async ( userId: string, userPayload: Partial<User> ) => {
		if ( !userId ) {
			this.tt.warning( 'user update is requested without a user ID' );
			throw new Error( 'user update is requested without a user ID' );
		}

		try {
			const updatedUser = await firstValueFrom( this.http.put<User>( `${ BASE_API_URL }/users/${ userId }`, userPayload ) );
			this.syncUpdateCache( userId, updatedUser );

		} catch ( error ) {
			this.tt.error( 'Failed to update the user' );
			console.error( error );
			throw error;
		}
	}
	public currentUserFetch = async () => {
		try {
			const user = await firstValueFrom( this.http.get<User>( `${ BASE_API_URL }/user` ).pipe( retry( 100 ) ) );
			this.user.next( user );
			this.isActive.next( true );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch your user info' );
			console.error( error );
			throw error;
		}
	}
	private currentContactReferencesFetch = async () => {
		try {
			const contactRefs = await firstValueFrom( this.http.get<ContactReference[]>( `${ BASE_API_URL }/user/contactReferences` ).pipe( retry( 100 ) ) );
			this.userContactReferenceArray.next( contactRefs );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch your user info' );
			console.error( error );
			throw error;
		}
	}
	public updateCurrentUserContact = async ( contact: Partial<Contact> ) => {

		try {
			const updatedContact = await firstValueFrom( this.http.put<Contact>( `${ BASE_API_URL }/user/contact`, contact ) );
			this.currentUserContact.next( updatedContact );
			if ( !this.user.getValue().contact ) this.user.next( { ...this.user.getValue(), contact: updatedContact.id } );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch your user info' );
			console.error( error );
			throw error;
		}
	}
	public updateUserAdmin = async ( id: string, isAdmin: boolean ) => {
		if ( !id ) {
			this.tt.warning( 'Update user request without id.' );
			throw new Error( 'Update user request without id.' );
		}
		try {
			const user = await firstValueFrom( this.http.put<User>( `${ BASE_API_URL }/users/${ id }/admin`, { isAdmin } ) );
			this.syncUpdateCache( user.id, user );
			return user;
		} catch ( error ) {
			this.tt.error( 'Failed to update user' );
			console.error( error );
			throw error;
		}
	}

	public updateUserClientAdmin = async ( id: string, isClientAdmin: boolean ) => {
		if ( !id ) {
			this.tt.warning( 'Update user request without id.' );
			throw new Error( 'Update user request without id.' );
		}
		try {
			const user = await firstValueFrom( this.http.put<User>( `${ BASE_API_URL }/users/${ id }/clientAdmin`, { isClientAdmin } ) );
			this.syncUpdateCache( user.id, user );
			return user;
		} catch ( error ) {
			this.tt.error( 'Failed to update user' );
			console.error( error );
			throw error;
		}
	}
	private userFetch = async ( id: string ) => {
		if ( !id ) {
			this.tt.warning( 'User get request without id' );
			throw new Error( 'User get request without id' );
		}
		try {
			const user = await firstValueFrom( this.http.get<User>( `${ BASE_API_URL }/users/${ id }` ) );
			const users = this.users.getValue() || {};
			users[ user.id ] = user;
			this.users.next( users );

			return user;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch user info' );
			console.error( error );
			throw error;
		}
	}
	public userDelete = async ( userId: string ) => {

		if ( !userId ) {
			this.tt.warning( 'User delete request without userId' );
			throw new Error( 'User delete request without userId' );
		}

		try {
			await firstValueFrom( this.http.delete( `${ BASE_API_URL }/users/${ userId }` ) );
			const users = this.users.getValue();
			if ( Object.keys( users ).length > 0 ) {
				delete users[ userId ];
				this.users.next( users );
				this.userArray.next( maptoarray( users ) );
			}
		} catch ( error ) {
			this.tt.error( 'Failed to delete the user' );
			console.error( error );
			throw error;

		}
		return true;

	}

	public userWithContactsDelete = async ( userId: string, contacts: string ) => {
		if ( !userId ) {
			this.tt.warning( 'User delete request without userId' );
			throw new Error( 'User delete request without userId' );
		}
		try {
			await firstValueFrom( this.http.delete( `${ BASE_API_URL }/usercontacts/${ userId }/${ contacts }` ) );
			const users = this.users.getValue();
			if ( Object.keys( users ).length > 0 ) {
				delete users[ userId ];
				this.users.next( users );
				this.userArray.next( maptoarray( users ) );
			}
		} catch ( error ) {
			this.tt.error( 'Failed to delete the user' );
			console.error( error );
			throw error;
		}
		return true;

	}

	public getUsers = async (): Promise<IMap<User>> => {
		await this.getUser();
		if ( this.allUsersFetched ) return this.users.getValue();
		try {
			return await firstValueFrom( this.http.get<User[]>( `${ BASE_API_URL }/users` ).pipe( retry( MAX_API_CALL_RETRIES ),
				tap( ( userArray ) => ( this.userArray.next( userArray ) ) ), map( res => arraytomap( res ) ),
				tap( res => ( this.users.next( res ) ) ), tap( () => this.allUsersFetched = true ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch users from the server' );
			console.error( error );
			throw error;
		}
	}

	public getUsersNoCache = async (): Promise<User[]> => {
		try {
			return await firstValueFrom( this.http.get<User[]>( `${ BASE_API_URL }/users` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch users from the server' );
			throw error;
		}
	}

	public getUserArray = async (): Promise<User[]> => {
		return maptoarray( await this.getUsers() );
	}
	public getNonApprovedUserList = async (): Promise<User[]> => {
		return maptoarray( ( await this.getUsers() ) ).filter( ( user: User ) => !user.isApproved );
	}
	public getApprovedUserList = async (): Promise<User[]> => {
		return maptoarray( ( await this.getUsers() ) ).filter( ( user: User ) => user.isApproved );
	}
	private currentUserContractFetch = async () => {
		const contactTuple = await firstValueFrom( this.http.get<ContactTuple>( `${ BASE_API_URL }/user/contact` ) );
		if ( !Object.keys( contactTuple ) ) return;
		const contact = {
			...contactTuple, displayName: ( [ contactTuple.name, contactTuple.middleName, contactTuple.surname ].map( str => ( str || '' ).trim() )
				.join( ' ' ).trim() ) || ( await this.getUser() ).email
		} as Contact;
		this.currentUserContact.next( contact );
	}

	private syncCurrentUserRegistrationCache = async ( registrationRequest: UserProfilePostRequest ) => {
		const user = await this.getUser();
		this.user.next( { ...user, registrationRequest } );

	}
	public sendUserRegistrationRequest = async ( registrationRequest: UserProfilePostRequest ) => {
		try {
			await firstValueFrom( this.http.post( `${ BASE_API_URL }/user/profile`, registrationRequest ) );
			await this.syncCurrentUserRegistrationCache( registrationRequest );
		} catch ( error ) {
			this.tt.error( 'Failed to post registration request' );
			console.error( error );
			throw error;
		}
	}
	/**
		* approve user and create contacts, etc
		*
		*/
	public approveCreateUser = async ( userId: string ) => {

		if ( !userId ) {
			this.tt.warning( 'user approval is requested without a user ID' );
			throw new Error( 'user approval is requested without a user ID' );
		}

		try {
			await firstValueFrom( this.http.post( `${ BASE_API_URL }/users/${ userId }/approve/create`, {} ) );
			this.syncUpdateCache( userId, await this.userFetch( userId ) );

		} catch ( error ) {
			this.tt.error( 'Failed to approve user.' );
			console.error( error );
			throw error;
		}
	}

	public approveLinkUser = async ( userId: string, contactId: string ) => {

		if ( !userId || !contactId ) {
			this.tt.warning( 'user approval is requested without ID' );
			throw new Error( 'user approval is requested without ID' );
		}

		try {
			await firstValueFrom( this.http.post( `${ BASE_API_URL }/users/${ userId }/approve/link/${ contactId }`, {} ) );
			this.syncUpdateCache( userId, await this.userFetch( userId ) );

		} catch ( error ) {
			this.tt.error( 'Failed to approve user.' );
			console.error( error );
			throw error;
		}
	}
	public getUserById = async ( id: string ): Promise<User> => {

		if ( this.users.value[ id ] ) return this.users.value[ id ];
		try {
			return await this.userFetch( id );
		} catch ( error ) {
			console.log( error );
			const nonExistingUser = {
				id,
				email: 'user not found.',
			} as User;
			const users = this.users.getValue() || {};
			users[ id ] = nonExistingUser;
			this.users.next( users );
		}
	}
	public createUserCompanyContact = async ( companyContact: Partial<Contact> ) => {
		const newCompanyContact = await firstValueFrom( this.http.post<Contact>( `${ BASE_API_URL }/user/companyContact`, companyContact ) );
		this.currentUserContact.next( { ...this.currentUserContact.value, companyContactId: newCompanyContact.id } );
		return newCompanyContact;
	}
	public getUserCompanyContact = async () => {
		const newCompanyContact = await firstValueFrom( this.http.get<Contact>( `${ BASE_API_URL }/user/companyContact` ) );
		return newCompanyContact;
	}
	public updateUserCompanyContact = async ( companyContact: Partial<Contact> ) => {
		const newCompanyContact = await firstValueFrom( this.http.put<Contact>( `${ BASE_API_URL }/user/companyContact`, companyContact ) );
		return newCompanyContact;
	}
	public getCurrentUserContact = async () => await getNonNullNonEmptyValue<Contact>( this.currentUserContact );
	public getCurrentUserContactReferences = async (): Promise<ContactReference[]> => await getNonNullValue( this.userContactReferenceArray );
	public getCurrentUserCommunityIds = async () => {
		const communityIds = await firstValueFrom( this.http.get<string[]>( `${ BASE_API_URL }/user/communities` ) );
		return communityIds;
	}
	public getCurrentUserClientCommunityIds = async () => {
		const communityIds = await firstValueFrom( this.http.get<string[]>( `${ BASE_API_URL }/user/client/communities` ) );
		return communityIds;
	}
	public getCurrentUserUnits = async () => {
		if ( this.aa.userType === 'user' ) {
		await getNonNullValue( this.isActive );
		}
		const units = await firstValueFrom( this.http.get<Unit[]>( `${ BASE_API_URL }/user/landlord/units` ) );
		return units.map( reverseTransformUnit );
	}

	public getUsersBy = async ( key?: string, value?: string[], notNulls?: string[] ): Promise<User[]> => {
		try {
			const currentUser = await this.getUser();
			if ( currentUser.isAdmin ) {
				return await this.getUsersNoCache();
			}

			if ( currentUser.isClientAdmin ) {
				key = 'client';
				value = [ currentUser.client ];
			}

			const url = `${ BASE_API_URL }/users/${ key || '' }/${ ( value || [] ).join( ',' ) }/${ ( notNulls || [] ).join( ',' ) }`;
			const result = await firstValueFrom( this.http.get<User[]>( url ) );
			return result;
		} catch ( error ) {
			this.tt.error( 'Failed to fetch users from the server' );
			console.error( error );
			throw error;
		}
	}

	public getCurrentUserClient = async ( clientOnly = true, forProc = false ): Promise<ClientVM> => {
		try {
			const currentUser = await this.getUser();
			return await firstValueFrom( this.http.get<ClientVM>( `${ BASE_API_URL }/client/${ currentUser.client }?clientOnly=${ clientOnly.toString() }&forProc=${ forProc.toString() }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the current client from server' );
		}
	}
	public getCurrentUserClientSettings = async ( ): Promise<ClientSettings> => {
		try {
			const currentUser = await this.getUser();
			return await firstValueFrom( this.http.get<ClientSettings>( `${ BASE_API_URL }/clientSettings/${ currentUser.client }` ) );
		} catch ( error ) {
			this.tt.error( 'Failed to fetch the current client from server' );
		}
	}
	public getUserByEmail = async ( email: string ) => {
		try {
			const user = await firstValueFrom( this.http.post<User>( `${ BASE_API_URL }/getUserByMail`, { email } ) );
			this.user.next( user );
			return user;
		} catch ( error ) {
			if ( error.error && error.error.message )
				this.tt.error( error.error.message );
			else
				this.tt.error( 'Failed to fetch your user info' );
			console.error( error );
			throw error;
		}
	}

	public grtUsersWithContacts = async ( status: string ) => {
		try {
			return await firstValueFrom( this.http.get<[]>( `${ BASE_API_URL }/usersWithContacts/${ status }` ) );
		} catch ( error ) {
			this.messageService.error({ sticky: true, message: error.error.data.error?.message } );
		}
	}
	public currentUser = async () => {
		try {
			const user = await firstValueFrom( this.http.get<User>( `${ BASE_API_URL }/user` ) );
			this.user.next( user );
			this.isActive.next( true );
			return user;
		} catch ( error ) {
			this.tt.error( 'please sign in' );
			console.error( error );
			throw error;
		}
	}
}
