
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientSettings, ClientVM } from '../../../models/client.model';
import { Community } from '../../../models/community.models';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

const BASE_API_URL = environment.apiURL;

import { PostClientRequestBody, PostClientResponse } from "../../../models/api/clients/clientPost.api.model";

@Injectable( { providedIn: 'root' } )
export class ClientService {

	constructor (
		private http: HttpClient,
		private toastr: CustomToastrService
	) { }

	public getClients = async (): Promise<any[]> => {
		try {
			return await firstValueFrom( this.http.get<ClientVM[]>( `${ BASE_API_URL }/clients` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}

	public getActiveClients = async ( clientsOnly = true ): Promise<ClientVM[]> => {
		try {
			return await firstValueFrom( this.http.get<ClientVM[]>( `${ BASE_API_URL }/clients/active?clientsOnly=${ clientsOnly.toString() }` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}

	public getClientById = async ( id: string, clientsOnly = true, forProc = false ): Promise<ClientVM> => {
		try {
			return await firstValueFrom( this.http.get<ClientVM>( `${ BASE_API_URL }/client/${ id }?clientOnly=${ clientsOnly.toString() }&forProc=${ forProc.toString() }` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}

	public createClient = async ( client: PostClientRequestBody ): Promise<PostClientResponse> => {
		try {
			return await firstValueFrom( this.http.post<PostClientResponse>( `${ BASE_API_URL }/client`, {
				...client, relayUrls: JSON.stringify( client.relayUrls ),
			} ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to create a client on the server' );
		}
	}

	public updateClient = async ( client?: any ): Promise<ClientVM> => {
		try {
			return await firstValueFrom( this.http.put<ClientVM>( `${ BASE_API_URL }/client`, {
				...client, relayUrls: JSON.stringify( client.relayUrls ),
			} ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to update client' );
		}
	}
	public updateClientSettings = async ( id, type: string, body?: any ): Promise<ClientVM> => {
		try {
			return await firstValueFrom( this.http.put<ClientVM>( `${ BASE_API_URL }/clientSettings/${ id }/${ type }`, body ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to update client' );
		}
	}
	public getClientSettingsById = async ( id: string ): Promise<ClientSettings> => {
		try {
			return await firstValueFrom( this.http.get<ClientSettings>( `${ BASE_API_URL }/clientSettings/${ id }` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to update client' );
		}
	}


	public deleteClient = async ( clientId: string ): Promise<void> => {
		try {
			return await firstValueFrom( this.http.delete<void>( `${ BASE_API_URL }/client/${ clientId }` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}

	public enableClient = async ( id: string ): Promise<void> => {
		try {
			return await firstValueFrom( this.http.post<void>( `${ BASE_API_URL }/client/${ id }/enable`, {} ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}

	public disableClient = async ( id: string ): Promise<void> => {
		try {
			return await firstValueFrom( this.http.post<void>( `${ BASE_API_URL }/client/${ id }/disable`, {} ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}

	public getClientByCommunity = async ( community: string ): Promise<ClientVM[]> => {
		try {
			return await firstValueFrom( this.http.get<ClientVM[]>( `${ BASE_API_URL }/clients`, { params: { community: community } } ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch client from server' );
		}
	}
	public getClientCommunities = async ( client: string ): Promise<Community[]> => {
		try {
			return await firstValueFrom( this.http.get<Community[]>( `${ BASE_API_URL }/clientCommunities/${ client }` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch Property Groups from server' );
		}
	}

	public getClientByUrl = async (): Promise<any> => {
		try {
			return await firstValueFrom( this.http.get<any>( `${ BASE_API_URL }/clientByUrl` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch client Logo' );
		}
	}

	public uploadClientImages = async ( fileList: any ): Promise<string> => {
		try {
			const formData = new FormData();
			fileList.forEach( fileDoc => {
				formData.append( fileDoc.id, fileDoc.image );
			} );
			return await firstValueFrom( this.http.post<any>( `${ BASE_API_URL }/uploadClientImages`, formData ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to upload client Logo' );
		}
	}

	public getActiveClientsForVendor = async ( status ): Promise<ClientVM[]> => {
		try {
			return await firstValueFrom( this.http.get<ClientVM[]>( `${ BASE_API_URL }/vendor-clients/${ status }` ) );
		} catch ( error ) {
			this.toastr.error( 'Failed to fetch clients from server' );
		}
	}
}
