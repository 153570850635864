<div class="p-grid p-col-12" *ngIf="canViewLog || showOnlyTable">
	<div class="p-col-12 p-md-3 p-lg-2" *ngIf="!showOnlyTable">
		<p-panelMenu [model]="items$ | async" autoWidth="true" [multiple]="false"></p-panelMenu>
	</div>
	<div class="p-grid p-col-12 p-pt-3" [ngClass]="{'p-md-9 p-lg-10 ': !showOnlyTable}">
		<div class="p-col-12 p-p-0 p-m-0 float-right" *ngIf="!showOnlyTable">
				<button pButton icon="pi pi-search" class="float-right p-button p-py-2" pTooltip="Refresh" tooltipPosition="bottom"
					(click)="loadLogs();"></button>
				<div class="p-field float-right p-m-0 p-px-2">
					<label for="to" class="filters p-px-1">Journal Date To:</label>
					<p-calendar [baseZIndex]="10050" placeholder="Journal Date" dateFormat="yy-mm-dd" [(ngModel)]="filterTo"
						name="j-date-name" [showIcon]="true" [showButtonBar]="true" required appendTo="body">
					</p-calendar>
				</div>
				<div class="p-field p-my-0 float-right p-px-1">
					<label for="from" class="filters p-px-1">Journal Date From:</label>
					<p-calendar [baseZIndex]="10050" placeholder="Journal Date" dateFormat="yy-mm-dd" [(ngModel)]="filterFrom"
						name="j-date-name" [showIcon]="true" [showButtonBar]="true" required appendTo="body" >
					</p-calendar>
				</div>
				<div class="float-left p-py-2" *ngIf="!(loading$ | async) && !audits?.length && !startRow && !table">
					Please Choose Ledger from the Menu to show the audit log for it
				</div>
			</div>
			<div class="p-col-12" [ngClass]="{'p-pt-6': !showOnlyTable}">
			<div *ngIf="loading$ | async">
				<p-skeleton *ngFor="let a of [].constructor(10)" class="list-group-item" width="100%" borderRadius="16px">
				</p-skeleton>
			</div>
			<p-table *ngIf="!(loading$ | async) && audits?.length>0" [scrollable]="true" [value]="audits"
				scrollHeight="flex" [rows]="step" [sortOrder]="-1"
				dataKey="event_id" styleClass="p-datatable-gridlines p-datatable-striped">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th class="p-py-0 text-center"></th>
						<th class="p-py-0 text-center">Action Date</th>
						<th class="p-py-0 text-center">Action</th>
						<th class="p-py-0 text-center">User</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-row let-i="rowIndex" let-expanded="expanded">
					<tr style="font-size: 0.86rem;">
						<td><button type="button" pButton pRipple [pRowToggler]="row"
								class="p-button-text p-button-rounded p-button-plain"
								[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
						</td>
						<td class="p-py-0 text-center">{{row.action_tstamp_clk | date:'yyyy-MM-dd HH:mm:ss'}}</td>
						<td class="p-py-0 text-center">{{AuditActions[row?.action]}}</td>
						<td class="p-py-0 text-center">{{row.email}} </td>
					</tr>
				</ng-template>
				<ng-template pTemplate="rowexpansion" let-row>
					<tr>
						<td colspan="4">
							<p-table *ngIf="row" [value]="row?.action==='U'?[row?.jsonData,row?.data]:[row?.jsonData]"
								[columns]="row?.action==='U'? keys(row?.cf) : keys(row?.jsonData)"
								dataKey="event_id">
								<ng-template pTemplate="header" let-columns>
					<tr style="font-size: calc(12rem/14);text-transform: capitalize;">
						<th *ngIf="row.action==='U'" style="width: 80px;"></th>
						<ng-container *ngFor="let col of columns">
							<th *ngIf="row.action!=='U' && col!=='id' && row.jsonData[col] && row.jsonData[col]!=='NULL'" class="p-p-1 data-cell"
								[ngStyle]="{'width' : (col === 'description') ?  '100px' : '80px'}">
								{{col}}</th>
							<th
								*ngIf="row.action==='U' && row.jsonData[col] !== row.data[col] && col!=='id' && row.jsonData[col] && row.jsonData[col]!=='NULL'"
								class="p-p-1 data-cell"
								[ngStyle]="{'width' : (col === 'description') ?  '100px' : '80px'}">
								{{col}}</th>
						</ng-container>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
					<tr>
						<td *ngIf="row.action==='U' && i===0" class="p-text-bold" style="width:80px;">Old Data</td>
						<td *ngIf="row.action==='U' && i===1" class="p-text-bold" style="width: 80px;">New Data</td>
						<ng-container *ngFor="let col of columns">
							<td *ngIf="row.action!=='U' && col!=='id' && row.jsonData[col] && row.jsonData[col]!=='NULL'" class="p-p-1 data-cell"
								[ngStyle]="{'width' : (col === 'description') ?  '100px' : '80px'}">
								{{rowData[col]}}
							</td>
							<td
								*ngIf="row.action==='U' && row.jsonData[col] !== row.data[col] && col!=='id' && row.jsonData[col] && row.jsonData[col]!=='NULL'"
								class="p-p-1 data-cell"
								[ngStyle]="{'width' : (col === 'description') ?  '100px' : '80px'}">
								{{rowData[col]}}
							</td>
						</ng-container>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="4">There are no logged actions for this ledger yet.</td>
					</tr>
				</ng-template>
			</p-table>
			</td>
			</tr>
			</ng-template>
			</p-table>
		</div>
		<div class="p-col-12 p-py-2" *ngIf="!audits?.length && startRow">
			You Reached the End of the results for this Ledger
		</div>
		<div class="p-mb-3 p-text-center bottom" *ngIf="(audits?.length > 0 || startRow !== 0) && !showOnlyTable">
			<button pButton icon="pi pi-chevron-left" class="p-button p-p-1" (click)="prev();"
				[disabled]="startRow===0 || isFirstPage()"></button>
			<button pButton icon="pi pi-refresh" class="p-button-outlined p-p-1 p-mx-1" (click)="reset();"></button>
			<button pButton icon="pi pi-chevron-right" class="p-button p-p-1" (click)="next();"
				[disabled]="isLastPage()"></button>
		</div>
	</div>
</div>
<div class="p-grid p-col-12 p-p-3 text-danger" *ngIf="!canViewLog && !showOnlyTable">
	You Don't have the access right to view this page, Please contact your client Admin for more Information
</div>
