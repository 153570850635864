import { IMap } from '../models/generic.models';

export const maptoarray = ( payload: any ) => {
	if ( Array.isArray( payload ) ) return payload;
	return Object.keys( payload || {} ).map( key => ( { ...payload[ key ], id: key } ) );
};

export const arraytomap = ( payload: any[] ) => {
	const toReturn: any = {};
	for ( const item of ( payload || [] ) ) {
		toReturn[ item.id ] = item;
	}
	return toReturn;
};
export const arrayToMapWithSpecificId = <T> ( payload: T[], idField: keyof T ): IMap<T> => {
	const toReturn: any = {};
	for ( const item of ( payload || [] ) ) {
		toReturn[ item[ idField ] ] = item;
	}
	return toReturn;
};
